import React from 'react'
import { Async } from 'react-async'
import fetchData, { actionGET } from '../../modules/fetch-data'
import LayoutDefault from '../../layouts/Default'
import TitleTopic from '../../components/TitleTopic'
import JobSearchBlock from './components/JobSearchBlock'
import testPartnerFilter from './modules/testPartnerFilter'
import styled from 'styled-components'

const StyledTitleTopic = styled(TitleTopic)``

const JobWidgetPage = ({ initialData, match, location, layout, ...props }) => {
  const title = props.title ? props.title : 'Stellenanzeigen'
  const jobWidgetUrl =
    process.env.RAZZLE_JOBWIDGET_API_HOST +
    '/public/search?class=50&cc=0&l=1&size=50&order=random' +
    testPartnerFilter()
  const meta = {
    title: 'Stellenanzeigen',
    author: 'stol.it',
    copyright: 'stol.it',
    url: 'https://www.stol.it/stellenanzeigen',
    description: 'Die neusten Job Angebote in Südtirol!',
    adsTargeting: {
      STOL7_seite: 's+ Job',
      STOL7_url: 'https://www.stol.it/stellenanzeigen',
    },
  }

  return (
    <Async
      promiseFn={fetchData}
      path={jobWidgetUrl}
      initialValue={initialData}
      accessType={'APIKEY'}
      access={process.env.RAZZLE_JOBWIDGET_API_KEY}
      deferFn={actionGET}
    >
      {({ data, error, isPending, isFulfilled, run }) => {
        return (
          <LayoutDefault meta={isFulfilled && meta} disable={!layout} {...props}>
            <StyledTitleTopic {...props} view={'jobs'} title={title} />
            <JobSearchBlock
              error={error}
              location={jobWidgetUrl}
              loading={isPending}
              data={isFulfilled && data}
              run={run}
              {...props}
            />
          </LayoutDefault>
        )
      }}
    </Async>
  )
}

JobWidgetPage.getInitialData = ({ req }) => {
  return fetchData({
    path: process.env.RAZZLE_JOBWIDGET_API_HOST + '/public/search?class=50&cc=0&l=1&size=50&order=random',
    accessType: 'APIKEY',
    access: process.env.RAZZLE_JOBWIDGET_API_KEY,
  })
}
JobWidgetPage.propTypes = {}
JobWidgetPage.defaultProps = {
  location: {
    pathname: undefined,
  },
  layout: true,
}
export default JobWidgetPage

export { StyledTitleTopic as JobWidgetPage0StyledTitleTopic }
