import consoleLogger from '../../modules/console-logger'
import includeJsFile from '../../modules/include-js-file'
import { setStoredItem, getStoredItem, clearStoredItem } from '../../modules/stored-item'
import defaultTimetables from './config/timetables'
import isServerSide from '../../modules/is-server-side'

let eventVars = {}
let timetables = defaultTimetables

const [consoleLog] = consoleLogger('Scheduler')

const keyPrefix = '__scheduler_'
const notYetFiredEventIdsKey = keyPrefix + 'not-yet-fired-entry-ids'

let isRunning = false
let needsRestart = false
let isInitialized = false

function createCallbackFunction() {
  window.callbackFunction = (eventKey, customVars = {}) => {
    if (!eventKey.startsWith(keyPrefix)) {
      eventKey = keyPrefix + eventKey
    }
    setStoredItem(eventKey, true)
    eventVars = {
      ...eventVars,
      ...customVars,
    }
    consoleLog(
      'Debugging',
      'Received event: ' +
        eventKey +
        ' | custom vars: ' +
        JSON.stringify(customVars, null, 4) +
        ' | all vars: ' +
        JSON.stringify(eventVars, null, 4),
    )
    handleScheduler()
  }
}

function kickoff({ forceClear = false, customTimetables } = {}) {
  if (customTimetables) {
    timetables = customTimetables
  }

  if (isInitialized && !forceClear) {
    consoleLog('Script Scheduler', '[Scheduler] - Already initialized, no need to reinitialize!')
    return
  }
  consoleLog('Script Scheduler', '[Scheduler] - Setting up Scheduler')

  if (forceClear) cleanOldEntries()
  resetNotYetFiredEvents()

  createCallbackFunction()

  consoleLog('Script Scheduler', '[Scheduler] - Successfully set up and starting. Get ready to party!')
  consoleLog('Script Scheduler', '-------------------------------------------------------------------\n')
  isInitialized = true

  handleScheduler()
}

function cleanOldEntries() {
  consoleLog('Script Scheduler', '[Scheduler] - Cleaning old stuff')
  for (const key of Object.keys(sessionStorage)) {
    if (key.startsWith('__scheduler_')) {
      clearStoredItem(key)
    }
  }
  resetNotYetFiredEvents()
  // window.callbackFunction = null
}

function resetNotYetFiredEvents() {
  const notYetFiredEventIds = []
  for (const [id, entry] of Object.entries(timetables)) {
    notYetFiredEventIds.push(entry.id)
  }
  setStoredItem(notYetFiredEventIdsKey, notYetFiredEventIds)
}

function handleScheduler() {
  if (!isRunning && isInitialized) {
    scheduleEvents()
  } else {
    consoleLog('Debugging', 'Waiting to be initialized or finished to restart Scheduler')
    needsRestart = true
  }
}

function scheduleEvents() {
  isRunning = true
  consoleLog('Script Scheduler', '[Scheduler] - Checking for next Events..')

  const notYetFiredEventIds = getStoredItem(notYetFiredEventIdsKey)

  notYetFiredEventIds.slice(0).forEach((notYetFiredEventId) => {
    consoleLog('Script Scheduler', '[Scheduler] - Checking Event ' + notYetFiredEventId)
    let notYetFiredEvent = timetables[notYetFiredEventId]

    if (checkIfEventNeedsToBeFired(notYetFiredEvent)) {
      notYetFiredEventIds.splice(notYetFiredEventIds.indexOf(notYetFiredEvent.id), 1)
      consoleLog('Script Scheduler', '[Scheduler] - Firing ' + notYetFiredEventId)
      doWork(notYetFiredEvent)
    } else {
      consoleLog('Script Scheduler', '[Scheduler] - Missing some Events in ')
      consoleLog('Script Scheduler', notYetFiredEvent)
    }
  })

  setStoredItem(notYetFiredEventIdsKey, notYetFiredEventIds)

  consoleLog('Script Scheduler', '[Scheduler] - Checking Done!')
  isRunning = false

  if (needsRestart) {
    needsRestart = false
    consoleLog('Script Scheduler', '[Scheduler] - Restarting!')
    scheduleEvents()
  }
}

function checkIfEventNeedsToBeFired(event) {
  let needsToBeFired = true

  event.neededEventIds.forEach((neededEventId) => {
    const neededEventFired = getStoredItem(keyPrefix + neededEventId)
    consoleLog(event.id + ': ' + neededEventId + ' | needsToBeFired?: ' + neededEventFired)
    if (neededEventFired === null || neededEventFired === undefined || neededEventFired === false) {
      needsToBeFired = false
    }
  })

  return needsToBeFired
}

function doWork(entry) {
  if (entry.internalCode != null) {
    consoleLog(
      'Script Scheduler',
      '[' + entry.id + ' | ' + entry.label + '] - Start internal code ' + entry.internalCode.function,
    )

    const promise = new Promise((resolve, reject) => {
      try {
        consoleLog('do work - event vars: ' + JSON.stringify(eventVars, null, 4))
        if (entry.internalCode.library) {
          entry.internalCode.library[entry.internalCode.function](eventVars)
        } else {
          entry.internalCode.function(eventVars)
        }

        resolve()
      } catch (error) {
        reject(error)
      }
    })
    cleanupEventPromise(promise, entry)
  } else if (entry.code != null) {
    consoleLog('Script Scheduler', '[' + entry.id + ' | ' + entry.label + '] - Start executing code ' + entry.code)

    const promise = new Promise((resolve, reject) => {
      try {
        const temporaryFunction = new Function(entry.code)
        temporaryFunction()

        resolve()
      } catch (error) {
        reject(error)
      }
    })
    cleanupEventPromise(promise, entry)
  } else if (entry.externalCode != null) {
    consoleLog(
      'Script Scheduler',
      '[' + entry.id + ' | ' + entry.label + '] - Start including URL ' + entry.externalCode.link,
    )

    const jsFile = entry.externalCode
    jsFile.onload = onExternalFileLoad
    jsFile.parameter = keyPrefix + entry.id

    includeJsFile(jsFile)
  }
}

function cleanupEventPromise(promise, entry) {
  promise.then(
    (resolveResponse) => {
      setStoredItem(keyPrefix + entry.id, true)
      consoleLog(
        'Script Scheduler',
        '[' + entry.id + ' | ' + entry.label + '] - Done!' + (resolveResponse ? resolveResponse : ''),
      )

      handleScheduler()
    },
    (errorResponse) => {
      consoleLog('Script Scheduler', '[' + entry.id + ' | ' + entry.label + '] - Error: ' + errorResponse)
      handleScheduler()
    },
  )
}

function onExternalFileLoad(key) {
  consoleLog('Script Scheduler', '[Scheduler] - ' + key + ' done!')

  setStoredItem(key, true)
}

function hasEventHappened(eventKey) {
  const eventWithPrefixTriggered = getStoredItem(keyPrefix + eventKey)
  if (eventWithPrefixTriggered != undefined) {
    return typeof eventWithPrefixTriggered === 'boolean' ? eventWithPrefixTriggered : eventWithPrefixTriggered != null
  } else {
    const eventWithoutPrefixTriggered = getStoredItem(eventKey)
    if (eventWithoutPrefixTriggered != undefined) {
      return typeof eventWithoutPrefixTriggered === 'boolean'
        ? eventWithoutPrefixTriggered
        : eventWithoutPrefixTriggered != null
    } else {
      return null
    }
  }
}

export { kickoff, hasEventHappened, cleanOldEntries, createCallbackFunction }

if (!isServerSide()) {
  window.athesia_react = { kickoff, hasEventHappened, cleanOldEntries, createCallbackFunction, ...window.athesia_react }
}

// TODO:
// K - Exception Management
// - Integrity Check for File
// K - resolve via event
// K - parallel security
// K - Wait for external file to be loaded with sessionStorage event
// K - Add possibility to flag errors in "firedEvents flags"
// K - onload for external file
// - Autocleanup of entries (with debug option)
