import domToReact from 'html-react-parser/lib/dom-to-react'
import React from 'react'
import ExternalFacebook from '../external-facebook'
import ExternalPinpoll from '../external-pinpoll'
import ExternalApa from '../external-apa'
import ExternalHappyContest from '../external-happy-contest'
import ExternalInstagram from '../external-instagram'
import ExternalTwitter from '../external-twitter'
import ExternalLiveticker from '../external-liveticker'
import ExternalGenially from '../external-genially'
import ExternalScript from '../external-script'

const embedExternal = ({ domNode }) => {
  const { attribs, children } = domNode
  const embedBoxChildren = children
  if (attribs.class === 'embed-box' && embedBoxChildren) {
    const iframe = embedBoxChildren.find((child) => child.name === 'iframe')
    const script = embedBoxChildren.find((child) => child.name === 'script')
    const div = embedBoxChildren.find((child) => child.name === 'div')
    const divScript = div && div.children.find((child) => child.name === 'script')
    const divIframe = div && div.children.find((child) => child.name === 'iframe')
    const facebook = divScript && divScript.attribs.src && divScript.attribs.src.includes('connect.facebook.net')
    const pinPoll = div && div.attribs.hasOwnProperty('data-pinpoll-id')
    const apa = iframe && iframe.attribs.src && iframe.attribs.src.includes('grafik.apa.at')
    const apaVideo = divIframe && divIframe.attribs.src && divIframe.attribs.src.includes('uvp-athesia.sf.apa.at/embed')
    const happyContest = script && script.attribs.src && script.attribs.src.includes('hc-apps.de')
    const instagram = script && script.attribs.src && script.attribs.src.includes('platform.instagram.com')
    const twitter = divScript && divScript.attribs.src && divScript.attribs.src.includes('platform.twitter.com')
    const liveTicker = script && script.attribs.src && script.attribs.src.includes('stol.it/inc/external.js')
    const genially =
      script &&
      div &&
      div.attribs.class &&
      div.attribs.class.includes('genially') &&
      script.children[0] &&
      script.children[0].data

    if (facebook) return ExternalFacebook(divScript.attribs, embedBoxChildren)
    if (pinPoll) return ExternalPinpoll(div.attribs, embedBoxChildren)
    if (apa) return ExternalApa(script.children[0].data, embedBoxChildren)
    if (apaVideo) return ExternalApa(null, embedBoxChildren)
    if (happyContest) return ExternalHappyContest(script.attribs, embedBoxChildren)
    if (instagram) return ExternalInstagram(script.attribs, embedBoxChildren)
    if (twitter) return ExternalTwitter(divScript.attribs, embedBoxChildren)
    if (liveTicker) return ExternalLiveticker(script.attribs, embedBoxChildren)
    if (genially) return ExternalGenially(genially, embedBoxChildren)

    if (script && script.children && script.children.length > 0) {
      let scripts = embedBoxChildren.filter((child) => child.name === 'script')
      scripts = scripts && scripts.map((item) => item.children && item.children[0] && item.children[0].data)
      return ExternalScript(scripts)
    }

    return domToReact(embedBoxChildren)
  }
}

export default embedExternal
