import consoleLogger from '../../../../modules/console-logger'
import { getStoredItem } from '../../../../modules/stored-item'

const amazonTam = () => {
  const [consoleLog] = consoleLogger('AdsLog')
  consoleLog('AEPA - AMAZON TAM', 'load the apstag.js library', 'Ads')

  !(function (a9, a, p, s, t, A, g) {
    if (a[a9]) return
    function q(c, r) {
      a[a9]._Q.push([c, r])
    }
    a[a9] = {
      init: function () {
        q('i', arguments)
      },
      fetchBids: function () {
        q('f', arguments)
      },
      setDisplayBids: function () {},
      targetingKeys: function () {
        return []
      },
      _Q: [],
    }
    A = p.createElement(s)
    A.async = !0
    A.src = t
    g = p.getElementsByTagName(s)[0]
    g.parentNode.insertBefore(A, g)
  })('apstag', window, document, 'script', '//c.amazon-adsystem.com/aax2/apstag.js')
  const apstag = window.apstag
  //initialize the apstag.js library on the page to allow bidding
  apstag.init({
    pubID: 'a4ac749f-c573-446f-8573-5724e3226405', //enter your pub ID here as shown above, it must within quotes
    adServer: 'googletag',
    simplerGPT: true,
  })

  if (getStoredItem('dlabAmazonTAMDebugTest', true) === 'true') {
    if (apstag && apstag.debug) {
      apstag.debug('enableConsole')
    }
  }
  /*
  apstag.fetchBids({
    slots: [{
      slotID: 'enter your slotID here', //example: 'div-gpt-ad-1475102693815-0'
      slotName: 'enter your GAM ad unit path here', //example: '12345/box-1'
      sizes: [[width,height], [width,height]] //example: [[300,250], [300,600]]
    },
      {
        slotID: 'enter your slotID here', //example: 'div-gpt-ad-1475185990716-0'
        slotName: 'enter your GAM ad unit path here', //example: '12345/leaderboard-1'
        sizes: [[width,height]] //example: [[728,90]]
      }],
    timeout: 2e3
  }, function(bids) {
// set apstag targeting on googletag, then trigger the first GAM request in googletag's
    disableInitialLoad integration
    googletag.cmd.push(function(){
      apstag.setDisplayBids();
      googletag.pubads().refresh();
    });
  });

   */
}

export default amazonTam
