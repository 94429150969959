import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import WidgetOnTourHeader from '../../components/HeaderOnTour/views/WidgetOnTourHeader'
import WidgetOnTourWithButtonItems from '../../widgets/WidgetOnTourItems/views/WidgetOnTourWithButtonItems'
import ContainerFull from '../../../../components/ContainerFull'
import Button from '../../../../components/Button'
import Icon from '../../../../components/Icon'
import translate from '../../../../modules/translate'
import Link from '../../../../components/Link'
import routes from '../../../routes'

const Wrapper = styled.section``

const StyledRightButton = styled(Button)`
  right: 20px;
  position: absolute;
  bottom: 15px;
`

const StyledLeftButton = styled(Button)`
  left: 20px;
  position: absolute;
  bottom: 15px;
`

const StyledLink = styled(Link)``

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #ffff;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  position: relative;
  min-height: 66px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: -20px;
    min-height: 64px;
  }
  @media (max-width: 500px) {
    min-height: 120px;
    justify-content: center;
    ${StyledRightButton} {
      margin: 0 auto;
      right: auto;
      left: auto;
      top: auto;
      bottom: 20px;
    }

    ${StyledLeftButton} {
      margin: 0 auto;
      left: auto;
      right: auto;
      bottom: auto;
      top: 20px;
    }

    ${StyledLink} {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
`

const ItemsAndButtonWrapper = styled(ContainerFull)`
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.27);
  z-index: 10;
  padding: 0;
  margin-right: ${(props) => props.theme.sizes.gapEdge};
  margin-left: ${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-right: ${(props) => props.theme.tablet.sizes.gapEdge};
    margin-left: ${(props) => props.theme.tablet.sizes.gapEdge};
    padding-left: 0;
    padding-right: 0;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-right: ${(props) => props.theme.mobile.sizes.gapEdge};
    margin-left: ${(props) => props.theme.mobile.sizes.gapEdge};
  }
  @media (min-width: 790px) {
    margin-top: -100px;
  }
  @media (max-width: 790px) {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
`
const WidgetOnTourWithButtons = ({ onTourItems, ...props }) => (
  <Wrapper {...props}>
    <WidgetOnTourHeader />
    <ItemsAndButtonWrapper as="section">
      <WidgetOnTourWithButtonItems onTourItems={onTourItems} numberItems={props.numberItems} />
      <ButtonWrapper>
        <StyledLink to="mailto:support@firstavenue.it">
          <StyledLeftButton view="grey">
            <Icon icon="email" /> {translate('ontour_booking_button')}
          </StyledLeftButton>
        </StyledLink>
        <StyledLink to={routes.getOptions('ontour').path}>
          <StyledRightButton>{translate('ontour_view_galleries_button')}</StyledRightButton>
        </StyledLink>
      </ButtonWrapper>
    </ItemsAndButtonWrapper>
  </Wrapper>
)

WidgetOnTourWithButtons.propTypes = {
  onTourItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object,
      location: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
}

export default WidgetOnTourWithButtons
