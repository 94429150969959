//this module defines all activated options on every jwplayer

import { createUuid } from '../uuid'
import hasItems from '../has-items'
import encodeTargets from '../encode-targets'
import options from '../../config/options'
import consoleLogger from '../console-logger'
import { concat } from 'lodash'

const optionsJwPlayer = ({
  jwId,
  targeting,
  videoTargetUri = '',
  excludedLabels,
  advertising,
  bidding,
  defaultOptions = options.Video.default,
}) => {
  const [consoleLog] = consoleLogger('OptionsVideo')
  let targetURI = hasItems(targeting) && encodeTargets(targeting)
  targetURI = targetURI ? targetURI + encodeURIComponent('&' + videoTargetUri) : encodeURIComponent(videoTargetUri)

  if (hasItems(excludedLabels)) {
    let excludeLabelsURI = 'excl_cat='
    excludedLabels.forEach((excludedLabel, index) => {
      if (index > 0) {
        excludeLabelsURI += ','
      }
      excludeLabelsURI += excludedLabel
    })
    consoleLog('excluded labels uri', excludeLabelsURI)
    targetURI += encodeURIComponent(excludeLabelsURI)
  }

  targetURI += targetURI && encodeURIComponent('&')

  consoleLog('targeting', targeting)
  consoleLog('targeting', encodeTargets(targeting))
  targetURI += hasItems(targeting) && encodeTargets(targeting)

  consoleLog('target uri', targetURI)

  let optionsVideo = defaultOptions.production

  const environment = process.env.RAZZLE_ENVIRONMENT
  consoleLog('environment', environment)
  if (environment) {
    optionsVideo = defaultOptions[environment]
  }

  consoleLog('options', optionsVideo)

  const optionsVendor = optionsVideo.vendor()

  consoleLog('options jw', optionsVendor)

  const optionsJw = {
    playerScript: optionsVendor.playerScript,
    customProps: optionsVendor.settings,
  }

  if (advertising) {
    optionsJw.customProps.advertising = optionsVendor.advertising(targetURI)

    if (bidding) {
      optionsJw.customProps.advertising.bids = optionsVendor.bidding
    }
  }
  if (jwId) {
    optionsJw.playerId = createUuid() + jwId
    optionsJw.playlist = `https://cdn.jwplayer.com/v2/media/${jwId}`
  }

  return optionsJw
}

optionsJwPlayer.defaultProps = {
  advertising: true,
}

export default optionsJwPlayer
