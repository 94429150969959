import React from 'react'
import { Async } from 'react-async'
import fetchData from '../../modules/fetch-data'
import LayoutDefault from '../../layouts/Default'
import ScreenPage from './screens/ScreenPage'
import PropTypes from 'prop-types'
import routes from '../routes'

const Page = ({ initialData, match, location, view, ...props }) => (
  <Async
    promiseFn={fetchData}
    location={match && routes.generatePathByRouteName(view, { slug: match.params.slug }, 'api')}
    watch={match}
    initialValue={initialData}
  >
    {({ data, error, isPending, isFulfilled }) => {
      let timeout = data && data.data && data.data.timeout

      return (
        <LayoutDefault meta={isFulfilled && data.meta} {...props}>
          <ScreenPage
            response={isFulfilled && !timeout && data}
            loading={isPending || timeout}
            error={error}
            ready={isFulfilled && !timeout}
            match={match}
          />
        </LayoutDefault>
      )
    }}
  </Async>
)

Page.getInitialData = ({ view, match, req }) => {
  return fetchData({ location: match && routes.generatePathByRouteName(view, { slug: match.params.slug }, 'api') })
}

Page.propTypes = {
  initialData: PropTypes.shape({
    meta: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    config: PropTypes.object,
  }),
  location: PropTypes.object,
  match: PropTypes.object,
}

Page.defaultProps = {
  location: {
    pathname: undefined,
  },
}

export default Page
