const viewports = {
  desktop: [1024, 0],
  tablet: [768, 0],
  mobile: [360, 0],
  all: [0, 0],
}

const sizes = {
  sponsor_s: [340, 100],
  sponsor_m: [468, 140],
  sponsor_l: [582, 175],
  sky: [300, 600],
  sky_s: [160, 600],
  largeLeaderBoard_s: [728, 90],
  button: [300, 100],
  button_s: [300, 50],
  buttonAds: [320, 100],
  buttonAds_s: [320, 50],
  rec: [300, 250],
}

const fubasConfig = {
  sizeMappings: {
    SPLASHPAGE: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [sizes.sponsor_s],
      },
      {
        viewPortSize: viewports.tablet,
        sizes: [sizes.sponsor_l],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.sponsor_m],
      },
    ],
    GAMEPAGE: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [sizes.sponsor_s],
      },
      {
        viewPortSize: viewports.tablet,
        sizes: [sizes.sponsor_l],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.sponsor_m],
      },
    ],
    SKYFLOOR: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.sky, sizes.sky_s],
      },
    ],
    LEAGUEPAGE: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [sizes.rec],
      },
      {
        viewPortSize: viewports.tablet,
        sizes: [sizes.rec],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.rec],
      },
    ],
    SUPERBANNER: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [sizes.button, sizes.buttonAds, sizes.buttonAds_s, sizes.button_s],
      },
    ],
    IS: [
      {
        viewPortSize: [960, 0],
        sizes: [],
      },
      {
        viewPortSize: [0, 0],
        sizes: [
          [300, 250],
          [320, 480],
        ],
      },
    ],
  },

  slots: [
    {
      id: 'fubas_s1',
      path: '/2273514/fubas_s1',
      sizeMappingName: 'SPLASHPAGE',
    },
    {
      id: 'fubas_s2',
      path: '/2273514/fubas_s2',
      sizeMappingName: 'GAMEPAGE',
    },
    {
      id: 'fubas_h1',
      path: '/2273514/fubas_h1',
      sizeMappingName: 'SKYFLOOR',
    },
    // {
    //   id: 'fubas_h2',
    //   path: '/2273514/fubas_h2',
    //   sizeMappingName: 'LEAGUEPAGE',
    // },
    {
      id: 'dlab-ad-IS-web',
      path: '/2273514/fubas_web_is',
      sizeMappingName: 'IS',
    },
    {
      id: 'fubas_s3',
      path: '/2273514/fubas_s3',
      sizeMappingName: 'SUPERBANNER',
    },
  ],

  general: {
    reloadInterval: true,
    collapseEmptyDivs: true,
    disableInitialLoad: true,
    enableSingleRequest: true,
    disableAddScrollbarWidth: true,
    prebid: {
      active: true,
    },
    adBlockingRecovery: {
      frequencyCap: {
        type: 'time',
        amount: 10,
      },
    },
    preloadedFiles: [
      {
        link: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
        async: true,
      },
    ],
    anchorAd: {
      path: '/2273514/fubas_h1',
      position: 'bottom',
      maxWidth: viewports.desktop,
    },
    interstitial: {
      // [ToDo]: Refactor that shit
      // showInViewports: ['mobile', 'mobile_s', 'tablet', 'tablet_s'],
      containerId: 'dlab-interstitial',
      slotId: 'dlab-ad-IS-web',
      // ga4eventName: 'web-interstitial',
      slotPath: '/2273514/fubas_web_is',
      displayTimeInSec: 15,
    },
    customRulesUrls: [
      {
        url: 'https://www.fubas.it/',
        exact: true,
        excludeAdUnit: ['dlab-ad-IS-web'],
      },
      {
        url: 'https://fubas-dev.azurewebsites.net/',
        exact: true,
        excludeAdUnit: ['dlab-ad-IS-web'],
      },
      {
        url: 'http://dev.fubas.it/',
        exact: true,
        excludeAdUnit: ['dlab-ad-IS-web'],
      },
      {
        url: '/',
        exact: false,
        slots: ['fubas_s3'],
        customExclusionLabels: ['Programmatic'],
      },
      /*       {
        url: '/spiel/',
        exact: false,
        customExclusionLabels: ['Programmatic'],
      },
      {
        url: '/partita/',
        exact: false,
        customExclusionLabels: ['Programmatic'],
      },
      {
        url: 'https://www.fubas.it/',
        exact: true,
        customExclusionLabels: ['Programmatic'],
      },
      {
        url: 'https://fubas-dev.azurewebsites.net/',
        exact: true,
        customExclusionLabels: ['Programmatic'],
      }, */
    ],
  },
}

export default fubasConfig
