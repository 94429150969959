import React from 'react'
import TitleTopic from '../../components/TitleTopic'
import Text from '../../components/Text'
import settingsSTOL from './settings'
import ContainerArticle from '../../components/ContainerArticle'
import OptionsAccordion from './components/OptionsAccordion'
import LayoutDefault from '../../layouts/Default'

const DlabSettings = ({ location, match, layout, ...props }) => {
  const meta = {
    adsTargeting: {
      STOL7_seite: 'DlabSettings',
    },
    robots: 'noindex, nofollow',
  }

  const version = require('../../../package.json').version

  return (
    <LayoutDefault disable={!layout} meta={meta} showAds={false} {...props}>
      <TitleTopic title={'Einstellungen'} />
      <ContainerArticle>
        <OptionsAccordion options={settingsSTOL()} />
        <br />
        <Text>
          <i>{'version: v.' + version}</i>
        </Text>
      </ContainerArticle>
      <br />
    </LayoutDefault>
  )
}

DlabSettings.defaultProps = {
  layout: true,
}

export default DlabSettings
