import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import Icon from '../../../../../../components/Icon'
import PropTypes from 'prop-types'
import Link from '../../../../../../components/Link'
import isStolApp from '../../../../../../modules/is-stolapp'
import isServerSide from '../../../../../../modules/is-server-side'

const StyledIcon = styled(Icon)`
  cursor: pointer;
  padding: 5px;
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
  ${(props) =>
    props.inverted &&
    css`
      color: #fff;
    `}
`
const SectionLeft = styled.div`
  flex: 1;
`
const SectionRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`
const Wrapper = styled.div`
  display: flex;
  clear: both;
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
  ${(props) =>
    props.position === 'bottom'
      ? css`
          border-bottom: solid 1px ${(props) => props.theme.color.textTertiary};
        `
      : css`
          border-top: solid 1px ${(props) => props.theme.color.textTertiary};
        `};
  @media print {
    display: none;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
`

const ModifyAndShareDetailArticle = ({
  position,
  enlargeFontCallBack,
  reduceFontCallBack,
  printCallBack,
  facebookCallBack,
  twitterCallBack,
  whatsAppCallBack,
  emailCallBack,
  inverted,
  ...props
}) => {
  if (!isStolApp()) {
    return (
      <Wrapper position={position} {...props}>
        <SectionLeft>
          <StyledIcon icon="enlargeFont" inverted={inverted} onClick={enlargeFontCallBack} />
          <StyledIcon icon="reduceFont" inverted={inverted} onClick={reduceFontCallBack} />
        </SectionLeft>
        <SectionRight>
          <StyledIcon icon="print" inverted={inverted} onClick={printCallBack} />
          {!isServerSide() && (
            <Fragment>
              <StyledLink to={'https://www.facebook.com/sharer/sharer.php?u=' + window.location}>
                <StyledIcon icon="facebook" inverted={inverted} onClick={facebookCallBack} />
              </StyledLink>
              <StyledLink to={'https://twitter.com/intent/tweet?url=' + window.location}>
                <StyledIcon icon="twitter" inverted={inverted} onClick={twitterCallBack} />
              </StyledLink>
              <StyledLink to={'https://api.whatsapp.com/send?text=' + window.location}>
                <StyledIcon icon="whats_app" inverted={inverted} onClick={whatsAppCallBack} />
              </StyledLink>
              <StyledLink to={'mailto:?subject=Stol.it - Artikel&body=' + window.location}>
                <StyledIcon icon="email" inverted={inverted} onClick={emailCallBack} />
              </StyledLink>
            </Fragment>
          )}
        </SectionRight>
      </Wrapper>
    )
  }
  return false
}

ModifyAndShareDetailArticle.propTypes = {
  position: PropTypes.oneOf(['top', 'bottom']),
}

ModifyAndShareDetailArticle.defaultProps = {
  position: 'top',
  inverted: false,
  enlargeFontCallBack: () => {},
  reduceFontCallBack: () => {},
  printCallBack: () => {
    if (!isServerSide()) {
      window.print()
    }
  },
  facebookCallBack: () => {},
  twitterCallBack: () => {},
  whatsAppCallBack: () => {},
  emailCallBack: () => {},
}

export default ModifyAndShareDetailArticle

export { StyledIcon as StyledIcon0ModifyAndShareDetailArticle }
