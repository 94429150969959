import screenModifyResponseHome from './Home/modules/screen-modify-response-home'
import screenModifyResponseDistrict from './District/modules/screen-modify-response-district'
import customApiPathSearch from './Search/modules/custom-api-path-Search'
import articleSPlusTargets from './Article/modules/article-splus-targets'

const modules = {
  screenModifyResponseHome,
  screenModifyResponseDistrict,
  customApiPathSearch,
  articleSPlusTargets,
}

export default modules
