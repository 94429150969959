import settingsDummy from '../../../topics/DlabSettings/settings'

// Contains the breakpoints of the page using this script. Used to make things more readable. In addition, if this
// object exists, the responsive refresh method hooks onto it. (see "responsiveRefresh()" for more infos)
const viewports = {
  desktop: [1381, 0],
  desktop_s: [1081, 0],
  tablet: [970, 0],
  tablet_s: [862, 0],
  mobile_l: [791, 0],
  mobile: [768, 0],
  mobile_m: [480, 0],
  mobile_s: [320, 0],
  all: [0, 0],
}

// Contains ad-unit sizes used in the size mapping array. These sizes are found in the programmatic documentation
// or in the GAM backend. Main usage is to retain readability with big and complex size mappings.
const sizes = {
  button: [300, 100],
  button_s: [300, 50],
  buttonAds: [320, 100],
  buttonAds_s: [320, 50],
  sponsoredBy: [170, 100],
  sponsoredBy_big: [285, 75],
  hotelWidget_s: [320, 615],
  hotelWidget_m: [480, 800],
  hotelWidget_l: [780, 515],
  hotelWidget_xl: [980, 560],
  hotelWidgetSolo_s: [320, 550],
  hotelWidgetSolo_m: [360, 500],
  hotelWidgetSolo_l: [780, 370],
  hotelWidgetSolo_xl: [970, 370],
  fullSize: [468, 60],
  largeLeaderBoard: [970, 90],
  largeLeaderBoard_s: [728, 90],
  rectangle: [300, 250],
  billboard: [970, 250],
  billboard_s: [728, 200],
  superBanner: [970, 150],
  superBanner_s: [728, 150],
  sky: [300, 600],
  sky_s: [160, 600],
  wallpaper: [1920, 1080],
}

// Contains often used combinations of ad-unit sizes. Makes the size mapping array a bit more readable but a lot more
// bulky and stiff. Mostly found in older adconfig files.
const sizeCombos = {
  buttons: [sizes.buttonAds, sizes.buttonAds_s, sizes.button, sizes.button_s],
  buttons_s: [sizes.buttonAds_s, sizes.button_s],
  largeLeaderboards: [sizes.superBanner, sizes.largeLeaderBoard, sizes.largeLeaderBoard_s],
}

// Start of the main configuration object.
const dummyConfig = {
  viewports: viewports,

  // The order of viewport sizes within the array defines their priority. Therefore the sorting of definitions inside
  // the single entries should be ordered DESC (biggest to smallest - with "all" be the last). It should work
  // nonetheless but sorting it reduces potential bugs/irregularities.
  // * viewPortSize: the value needs to be an Array with the first value being the width and the second being zero (0).
  //   For more information look up the GAM documentation or the viewports array contained in this file
  // * sizes: Two-dimensional array of sizes. Empty size array means the ad will not be displayed
  sizeMappings: {
    SKY: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile_s,
        sizes: sizeCombos.buttons_s,
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [sizes.largeLeaderBoard_s],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.sky, sizes.sky_s],
      },
    ],
    IS: [
      {
        viewPortSize: [960, 0],
        sizes: [],
      },
      {
        viewPortSize: [0, 0],
        sizes: [
          [300, 250],
          [320, 480],
        ],
      },
    ],
  },

  // The list of slots (i.e. the div with the corresponding ad unit) with all necessary information.
  // Note: even if the Interstitial is defined further down the config it still needs to be defined as slot here. That's
  // because it technically isn't an out-of-page-ad but a rather normal div in the HTML code (not to be confused by the
  // google-approved, out-of-page-defined between-the-page-interstitial found in their documentations.
  // * id: div id that should contain the ad
  // * path: the identification path found in the GAM Backend. Usually it is build like so:
  //   "/<AD_MANAGER_ID>/<PARENT_AD_UNIT_NAME>/<AD_UNIT_NAME>"
  // * sizeMappingName: the key that corresponds with the sizemapping defined in the object above.
  slots: [
    {
      id: 'dlab-ad-IS-web',
      path: '/2273514/stol7_web_is',
      sizeMappingName: 'IS',
      // Should the slot be capped
      frequencyCap: {
        // Can be capped in "time" or "session"
        type: 'time',
        // How long between show. ("time" => minutes)
        amount: 30,
      },
    },
    {
      id: 'dlab_ad_H2',
      path: '/2273514/stol7_hp_sky',
      sizeMappingName: 'SKY',
    },
  ],

  general: {
    // Array of options are shown when the hidden debug popup menu shows up
    hiddenOptions: settingsDummy(),
    // Unknown, maybe an old relic
    reloadInterval: true,
    // Corresponds to "googletag.pubads().collapseEmptyDivs()"
    collapseEmptyDivs: true,
    // Corresponds to "googletag.pubads().disableInitialLoad();"
    disableInitialLoad: true,
    // Corresponds to "googletag.pubads().enableSingleRequest();"
    enableSingleRequest: true,
    // Should the ScrollbarWidth be removed from the window width
    disableAddScrollbarWidth: true,
    // Which value should be added to the keyvalues of the ad when test mode is activated
    testValue: '1',
    // Should amazon TAM be activated
    amazontam: true,
    // Custom implementation of lazyload. If that object is defined in one way or another it will be activated
    lazyLoad: {
      // How far preloaded (in pixels) will be the ads on the page
      offset: 500,
    },
    // Should Prebid be activated
    prebid: {
      // Should Prebid be activated
      active: true,
      // Use prebid size mapping
      setSizeMapping: true,
      // Use prebid video
      video: {
        // Slot name of the video ad unit in question (for more infos look at "slots" above)
        adSlotName: '/2273514/stol7_video_pre',
        // Size of the video player
        playerSize: [640, 360],
      },
      liveramp: {
        // The Name of the Cookie - TO REFACTOR
        cookieName: 'stnews-user',
        // The Property in the Object of the Cookie - TO REFACTOR
        cookieObjectValue: 'email',
      },
    },
    // Adds everything needed for GAM Ad Blocker
    adBlockingRecovery: {
      // Should the message be capped
      frequencyCap: {
        // Can be capped in "time", "pageViews" or "session"
        type: 'time',
        // How long between show. ("time" => minutes, "pageViews" => clicks)
        amount: 30,
      },
    },

    // Custom Rules enable to define a list of directions that modify ad behaviour in specific URLs/Pages.
    // * url: URL string that needs to match with the wanted page(s)
    // * exact: Should the url match symbol by symbol [true] or should it just be contained in the current url [false]
    // * slots: List of slot ids that needs to match with the wanted ad ID ( rules will only affect those slots)
    // * inverted: if true, rules will affect all unmatching cases (only affects slot ids)
    // * noAds: if true, all ad requests will be prevented
    // * customExclusionLabels: Those labels will be added to the labels handed to the ad/gam-request of the page
    // * excludeAdUnit: Array of div IDs that wont be requested from ad/gam on the requested URLs
    customRulesUrls: [
      {
        url: '/p/kuendigen',
        exact: false,
        noAds: true,
      },
      {
        url: '/dolomiten',
        exact: false,
        slots: ['dlab_ad_H2'],
        customExclusionLabels: ['Programmatic'],
      },
      {
        url: '/rubrik/plus',
        exact: false,
        excludeAdUnit: ['dlab-ad-IS-web'],
      },
    ],

    // Possibiliy to define js-files that are loaded prior to the initAd()-routine
    // * link: url to the JS-file
    // * async: should the file be loaded asynchronously
    // * onload: function which gets executed after the script is loaded
    // * idLoadOnce: id for check to not include it multiple times
    preloadedFiles: [
      {
        idLoadOnce: 'gpt-script',
        link: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
        async: true,
        onload: () => {
          console.log('script loaded')
        },
      },
    ],

    // the script will add a custom version of an interstitial (NOT the native gam version of intertitial) if the
    // interstitial block exists.
    // Note: The slot needs to be defined not only here but also in the "slots"-block above. More Infos there.
    // * containerId: name of the container that the script will create for the intertitial. Can be used by other JS-
    //   script to style or manipulate it
    // * slotId: Name of the element in the slots-array above
    // * slotPath: the identification path found in the GAM Backend. For more Info look up the "slots"-Array above
    // * displayTimeInSec: if it exists the interstitial will close automatically in X seconds
    interstitial: {
      containerId: 'dlab-interstitial',
      slotId: 'dlab-ad-IS-web',
      slotPath: '/2273514/stol7_web_is',
      displayTimeInSec: 15,
      // Should the IS be capped
      frequencyCap: {
        // Can be capped in "time" or "session"
        type: 'time',
        // How long between show. ("time" => minutes)
        amount: 30,
      },
    },

    // Configures a part of the script that enables auto-reloads after X seconds. It will only reload the specified ad-
    // units and not the whole page.
    // Note: this logic needs to be configured in the gam-backend too - google will block the whole ad unit if it isn't
    // * interval: how long until the refresh kicks in
    // * campaignIds: ID of the campaign - can be found in the gam backend
    // * adUnitsIds: the identification path of the ad units in question.
    reloader: {
      interval: 30,
      campaignIds: [293423654, 164280974],
      adUnitsIds: ['/2273514/stol7_hp_sky', '/2273514/stol7_default_sky'],
    },

    // Adds a close button to the floor-ad that enables to close that adunit for that session. It is quite old and soon
    // obsolete when the out-of-page anchor ad comes so I don't bother going into more detail
    addCloseButton: {
      elements: [
        {
          ids: ['dlab_ad_H2', 'dlab_ad_S2'],
          width: [728],
        },
      ],
    },
  },
}

export default dummyConfig
