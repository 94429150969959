//a module to check if the React App/React Bundle is running in the App.

import isStolApp from '../is-stolapp'

const createValidRelativeUrl = (urlString) => {
  if (isStolApp()) {
    return urlString
  }
  if (!urlString) {
    return
  }
  try {
    return new URL(urlString).pathname
  } catch (e) {
    return urlString
  }
}

export default createValidRelativeUrl
