import React from 'react'
import styled from 'styled-components'
import Tabs from '../../../../../../components/Tabs'
import TabsItem from '../../../../../../components/TabsItem'
import { StyledListWidgetNakedLineArticle0WidgetMostRead } from '../../../WidgetMostRead'
import Icon from '../../../../../../components/Icon'
import { useSwipeable } from 'react-swipeable'
import pushEventToDataLayer from '../../../../../../modules/push-event-to-data-layer'

const TabPanel = styled.div`
  width: 100%;
`

const StyledTabs = styled(Tabs)`
  background-color: #ffffff;
  padding-bottom: 7px;
  margin-right: -${(props) => props.theme.sizes.gapEdge};
  margin-left: -34px;

  & .MuiTab-labelIcon {
    min-height: 56px;
  }

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-right: -${(props) => props.theme.tablet.sizes.gapEdge};
    margin-left: -${(props) => props.theme.tablet.sizes.gapEdge};

    & .MuiTab-labelIcon {
      min-height: 52px;
    }
  }

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-right: calc(-${(props) => props.theme.mobile.sizes.gapEdge} / 2);
    margin-left: calc(-${(props) => props.theme.mobile.sizes.gapEdge} / 2);
  }
`

const StyledSPlusIcon = styled(Icon)`
  font-size: 18px;
`

const TabsWidgetNakedLineArticle = ({ articles, numbering, textTag, onClick }) => {
  const tabs = ['plus', 'videos', 'standard']
  const [value, setValue] = React.useState('plus')

  const handleChange = (event, newValue) => {
    pushEventToDataLayer('MostReadTabSwitch', ['MostReadTab', newValue])
    setValue(newValue)
  }

  const swipe = (direction) => {
    let valueIdx = tabs.indexOf(value)
    let newValueIdx = (valueIdx + direction) % tabs.length

    // JS Modulo is defined: -1 % 3 is negative, instead of positive
    if (newValueIdx < 0) {
      newValueIdx = tabs.length - 1
    }

    return tabs[newValueIdx]
  }

  let handlers = useSwipeable({
    onSwipedRight: () => handleChange(null, swipe(+1)),
    onSwipedLeft: () => handleChange(null, swipe(-1)),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  return (
    <>
      <StyledTabs value={value} onChange={handleChange}>
        <TabsItem
          icon={<StyledSPlusIcon icon="splus" />}
          iconPosition="start"
          isWidget={true}
          label="Themen"
          value="plus"
        />
        <TabsItem
          icon={<Icon icon="article" />}
          iconPosition="start"
          isWidget={true}
          label="Artikel"
          value="standard"
        />
        <TabsItem icon={<Icon icon="video" />} iconPosition="start" isWidget={true} label="Videos" value="videos" />
      </StyledTabs>
      <TabPanel {...handlers}>
        <StyledListWidgetNakedLineArticle0WidgetMostRead
          articles={articles[value]}
          numbering={numbering}
          textTag={textTag}
          onClick={onClick}
        />
      </TabPanel>
    </>
  )
}

export default TabsWidgetNakedLineArticle
