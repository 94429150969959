import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import useScript from 'react-script-hook'
import isServerSide from '../../modules/is-server-side'
import errorLog from '../../modules/error-log'
import consoleLogger from '../../modules/console-logger'
import { useCookies } from 'react-cookie'
import isStolApp from '../../modules/is-stolapp'
import { useWidgetContext } from '../../bundles/components/WidgetContext'
import options from '../../config/options'

const Sourcepoint = () => {
  const isWidget = useWidgetContext()
  const [consoleLog] = consoleLogger('Sourcepoint')
  const [loading, error] = useScript({
    src: 'https://cdn.privacy-mgmt.com/wrapperMessagingWithoutDetection.js',
    checkForExisting: true,
  })
  const [cookies, setCookie, removeCookie] = useCookies(['cw'])
  let app = isStolApp() ? 'app' : cookies['cw']
  const config = {
    accountId: 1411,
    baseEndpoint: 'https://cdn.privacy-mgmt.com',
    isSPA: true,
    targetingParams: {
      cookiew: app,
    },
  }
  consoleLog('is widget', isWidget)
  if (isWidget && isStolApp()) {
    consoleLog('propertyHref', options.Bundles.backendHost)
    config.propertyHref = options.Bundles.backendHost
  }

  if (!isServerSide() && window && window.device_native_advertising_identifier) {
    config.authId = window.device_native_advertising_identifier
  }

  if (error) {
    errorLog({ level: 'fatal', info: error.message })
    return false
  }

  const hasExecuteMessaging = !isServerSide() && window._sp_ && typeof window._sp_.executeMessaging === 'function'
  useEffect(() => {
    consoleLog('1 started - window variable: ', window._sp_)
    consoleLog('1 started - script loading state: ', loading)
    if (!loading && hasExecuteMessaging) {
      consoleLog('2 messaging executed', '')
      consoleLog('2 execute options', config)
      window._sp_.executeMessaging()
    }
    return () => {
      if (!loading && hasExecuteMessaging) {
        consoleLog('3 messages destroyed', '')
        window._sp_.destroyMessages()
      }
    }
  }, [loading])

  if (!hasExecuteMessaging || isServerSide()) {
    return (
      <Helmet>
        <script>
          {`
 !function () { var e = function () { var e, t = "__tcfapiLocator", a = [], n = window; for (; n;) { try { if (n.frames[t]) { e = n; break } } catch (e) { } if (n === window.top) break; n = n.parent } e || (!function e() { var a = n.document, r = !!n.frames[t]; if (!r) if (a.body) { var i = a.createElement("iframe"); i.style.cssText = "display:none", i.name = t, a.body.appendChild(i) } else setTimeout(e, 5); return !r }(), n.__tcfapi = function () { for (var e, t = arguments.length, n = new Array(t), r = 0; r < t; r++)n[r] = arguments[r]; if (!n.length) return a; if ("setGdprApplies" === n[0]) n.length > 3 && 2 === parseInt(n[1], 10) && "boolean" == typeof n[3] && (e = n[3], "function" == typeof n[2] && n[2]("set", !0)); else if ("ping" === n[0]) { var i = { gdprApplies: e, cmpLoaded: !1, cmpStatus: "stub" }; "function" == typeof n[2] && n[2](i) } else a.push(n) }, n.addEventListener("message", (function (e) { var t = "string" == typeof e.data, a = {}; try { a = t ? JSON.parse(e.data) : e.data } catch (e) { } var n = a.__tcfapiCall; n && window.__tcfapi(n.command, n.version, (function (a, r) { var i = { __tcfapiReturn: { returnValue: a, success: r, callId: n.callId } }; t && (i = JSON.stringify(i)), e.source.postMessage(i, "*") }), n.parameter) }), !1)) }; "undefined" != typeof module ? module.exports = e : e() }();
 `}
        </script>
        <script>
          {`

            window._sp_ = {
              config: ${JSON.stringify(config)}
            }
          `}
        </script>
      </Helmet>
    )
  }

  return false
}

export default Sourcepoint
