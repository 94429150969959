import React, { Fragment } from 'react'
import styled, { withTheme } from 'styled-components'
import ErrorBoundary from '../../../../topics/Error/widgets/ErrorBoundary'
import translate from '../../../../modules/translate'
import hotels from '../BlockThreeHotelsCommercial/hotel_data'
import pushEventToDataLayer from '../../../../modules/push-event-to-data-layer'
import ReactGA from 'react-ga'

const Wrapper = styled.div`
  display: flex;
  margin-top: -20px;
  margin-bottom: 15px;
  font-family: ${(props) => props.theme.font.primary};

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: -30px;
    margin-bottom: 0;
    flex-direction: column;
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: -20px;
  }
`

const StyledHotelImage = styled.img`
  width: 100%;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: 30%;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 40%;
  }
`

const StyledHotelInfo = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.theme.fontSize.text};

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
    padding-left: 20px;
    height: auto;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
    padding-left: 10px;
  }
`

const StyledHotelText = styled.div`
  margin-top: 10px;

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: 0;
  }
`

const StyledHotelHeader = styled.div`
  font-weight: 700;
  margin-bottom: 3px;
  font-size: ${(props) => props.theme.fontSize.title3};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.title3};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.title3};
  }
`

const StyledHotelSubheader = styled.div``

const StyledHotelLinks = styled.div`
  margin-top: 10px;
  display: flex;
  flex-grow: 20;
  align-items: flex-end;
  height: 30px;
`

const StyledHotelName = styled.div`
  color: #f00074;
  width: 100%;
  text-align: end;
  padding-right: 10px;
  padding-bottom: 5px;
  font-size: ${(props) => props.theme.fontSize.base};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobileS.breakPoint + 'px'}) {
    padding-right: 0;
  }
`

const StyledPrice = styled.div`
  font-weight: 700;
  padding-bottom: 5px;
  color: white;
  display: none;

  font-size: ${(props) => props.theme.fontSize.base};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};
  }
`

const StyledBookButton = styled.div`
  padding: 5px 10px;
  font-size: ${(props) => props.theme.fontSize.base};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};
  }
  color: #f00074;
  background-color: white;
  align-self: flex-end;
  border-width: 0px;
  border-radius: 10px;
  margin-left: auto;

  &:hover {
    color: #f00074;
    background-color: white;
  }

  display: none;
`

const StyledHotelItem = styled.a`
  background-color: white;
  text-decoration: none;
  color: ${(props) => props.theme.color.textPrimary};
  padding: 20px;
  flex-grow: 1;
  flex: 1;
  //transition: 0.5s;
  border-right: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-direction: row;
    padding: 10px;
    border-right: none;
    border-bottom: 1px solid #e5e5e5;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-left: 0;
  }

  &:hover {
    background-color: #f00074;

    ${StyledHotelName} {
      display: none;
    }

    ${StyledBookButton}, ${StyledPrice} {
      display: block;
    }

    ${StyledHotelName}, ${StyledHotelText}, ${StyledHotelLinks} {
      color: white;
    }
  }
`

const BlockThreeHotelsPretty = ({ data, ...props }) => {
  const errorOptions = {
    component: undefined,
    link: '/',
    linkText: translate('error_try_homepage'),
    logError: 'BlockThreeHotelsPretty index',
  }

  return (
    <ErrorBoundary {...errorOptions}>
      <Wrapper {...props}>
        <Fragment>
          {hotels.map((hotel) => (
            <StyledHotelItem
              href={hotel.link}
              target="_blank"
              onClick={() => {
                if (props.commercial) {
                  pushEventToDataLayer('Hotel 2', ['HotelName', hotel.name])
                  ReactGA.event({ category: 'Hotel 2', action: hotel.name })
                } else {
                  pushEventToDataLayer('Hotel', ['HotelName', hotel.name])
                  ReactGA.event({ category: 'Hotel', action: hotel.name })
                }
              }}
            >
              <StyledHotelImage src={hotel.imgUrl} />
              <StyledHotelInfo>
                <StyledHotelText>
                  <StyledHotelHeader>{hotel.header}</StyledHotelHeader>
                  <StyledHotelSubheader>{hotel.subHeader}</StyledHotelSubheader>
                  {hotel.duration && <StyledHotelSubheader>{hotel.duration}</StyledHotelSubheader>}
                </StyledHotelText>
                <StyledHotelLinks>
                  <StyledHotelName>{hotel.name}</StyledHotelName>
                  <StyledPrice>
                    {hotel.moreExpensiveRooms && 'Ab '} € {hotel.price} p.P.
                  </StyledPrice>
                  <StyledBookButton>
                    <span>Jetzt Buchen</span>
                  </StyledBookButton>
                </StyledHotelLinks>
              </StyledHotelInfo>
            </StyledHotelItem>
          ))}
        </Fragment>
      </Wrapper>
    </ErrorBoundary>
  )
}

export default withTheme(BlockThreeHotelsPretty)
