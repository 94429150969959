import React from 'react'
import { Async } from 'react-async'
import fetchData from '../../modules/fetch-data'
import LayoutDefault from '../../layouts/Default'
import ScreenFaq from './screens/ScreenFaq'
import PropTypes from 'prop-types'

const Faq = ({ initialData, match, location, view, layout, api, ...props }) => (
  <Async promiseFn={fetchData} location={api}>
    {({ data, error, isPending, isFulfilled }) => {
      let timeout= data && data.data && data.data.timeout


      return(
      <LayoutDefault meta={isFulfilled && !timeout && data.meta} showAds={false} disable={!layout} {...props}>
        <ScreenFaq response={isFulfilled && !timeout && data} loading={isPending || timeout} error={error} ready={isFulfilled && !timeout} match={match} />
      </LayoutDefault>
    )}}
  </Async>
)

Faq.getInitialData = ({ req }) => {
  return fetchData({ location: req.url })
}

Faq.propTypes = {
  initialData: PropTypes.shape({
    meta: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    config: PropTypes.object,
  }),
  location: PropTypes.object,
  match: PropTypes.object,
}

Faq.defaultProps = {
  location: {
    pathname: undefined,
  },
  layout: true,
}

export default Faq
