import T from 'prop-types'

import rittnerLogo from './images/Logo_Rittner_Horn_CMYK.svg'
import snowLogo from './images/snowLogo.svg'
import { Description, Title } from '../styled'
import { Container, ImageContainer, ParagraphWrapper, Text, TextContainer, TextWrapper } from './styled'
import uniqueId from 'lodash/uniqueId'
import Wrapper from '../../components/Wrapper'

const Paragraph = ({ data }) => {
  return (
    <Wrapper>
      <ParagraphWrapper>
        <Title>{data.title}</Title>
        <Description>{data.subTitle}</Description>
        <TextWrapper>
          <Container>
            <TextContainer>
              {data.content.map((el) => (
                <Text key={uniqueId()}>{el}</Text>
              ))}
            </TextContainer>
            <ImageContainer>
              <a href="https://www.ritten.com/de/sonnenplateau/highlight/rittner-horn.html" target="_blank">
                <img src={rittnerLogo} alt={'Rittner'} />
              </a>
              <a href="https://www.ritten.com/de/sonnenplateau/rittnerhorn/skicenter.html" target="_blank">
                <img src={snowLogo} alt={'snow logo'} />
              </a>
            </ImageContainer>
          </Container>
        </TextWrapper>
      </ParagraphWrapper>
    </Wrapper>
  )
}

Paragraph.propTypes = {
  data: T.shape({
    title: T.string,
    subTitle: T.string,
    content: T.array,
  }),
}

export default Paragraph
