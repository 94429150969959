import React from 'react'
import styled from 'styled-components'
import TitleTopic, { WrapperTitle0TitleTopic } from '../../../../../../components/TitleTopic'
import styledContainerQuery from 'styled-container-query'
import translate from '../../../../../../modules/translate'
import MapSouthtyrol from '../../../../../../components/MapSouthtyrol'
import Icon from '../../../../../../components/Icon'
import Text from '../../../../../../components/Text'
import PropTypes from 'prop-types'
import Select from '../../../../../../components/Select'

const StyledMapSouthtyrol = styled(MapSouthtyrol)`
  width: 11.875em;
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: 0;
  }
`

const StyledIcon = styled(Icon)`
  margin-right: 0.5em;
  color: ${(props) => props.theme.color.textPrimary};
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
`

const StyledText = styled(Text)`
  color: ${(props) => props.theme.color.textPrimary};
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
`

const WrapperPlace = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-self: flex-start;
  margin-bottom: calc(${(props) => props.theme.sizes.gapVertical} / 2);
  margin-top: calc(${(props) => props.theme.sizes.gapVertical} / 6);
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: calc(${(props) => props.theme.tablet.sizes.gapVertical} / 2);
    margin-top: calc(${(props) => props.theme.tablet.sizes.gapVertical} / 6);
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: 0;
    margin-top: calc(${(props) => props.theme.mobile.sizes.gapVertical} / 6);
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    align-items: flex-start;
  }
`

const StyledSelect = styled(Select)`
  width: ${(props) => (props.defaultValue ? '100px' : '150px')};
  margin-right: ${(props) => props.theme.sizes.gapColHalf};
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-right: 0;
    margin-bottom: 10px;
    min-width: 85px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    .perimeterSelect__control {
      font-size: ${(props) => props.theme.mobile.fontSize.small};
    }
  }
`

const NavName = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: 0;
    margin-top: calc(${(props) => props.theme.mobile.sizes.gapVertical} / 6);
  }
`

const Wrapper = styledContainerQuery(TitleTopic)`
  display: flex;
  justify-content: space-around;
  z-index: auto;

  ${WrapperTitle0TitleTopic} {
    align-items: flex-start;
  }

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-top: 10px;
    padding-bottom: 20px;
  }

  &:container(max-width: 400px){
    flex-direction: column;
    justify-content: flex-start;
    height: 210px;
    margin-bottom: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    ${WrapperPlace}{
      padding-top:calc(${(props) => props.theme.sizes.gapVertical}/2);
      padding-bottom:calc(${(props) => props.theme.sizes.gapVertical}/2);
    }
  }
`

const perimeters = [5, 10, 15, 30, 50]
const selectOptions = perimeters.map((value) => {
  return { label: value + ' km', value: value }
})

const TitleNearMe = ({ place, latitude, longitude, radius, onChange, ...props }) => (
  <Wrapper
    title={translate('nearme_title')}
    subelement={
      <WrapperPlace>
        <StyledSelect
          value={selectOptions.find((perimeterData) => perimeterData.value === radius)}
          isSearchable={false}
          values={selectOptions}
          onChange={onChange}
          classNamePrefix="perimeterSelect"
        />
        {place && place !== false && (
          <NavName>
            <StyledIcon icon="my_location" />
            <StyledText>{place.includes(',') ? place.split(',')[0] : place}</StyledText>
          </NavName>
        )}
      </WrapperPlace>
    }
    {...props}
  >
    <StyledMapSouthtyrol
      dotPosition={latitude && longitude ? { latitude: latitude, longitude: longitude } : undefined}
    />
  </Wrapper>
)

TitleNearMe.propTypes = {
  place: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
}

export default TitleNearMe
