import React from 'react'
import translate from '../../../../modules/translate'
import ErrorBoundary from '../../../Error/widgets/ErrorBoundary'
import SkeletonNearMe from '../../components/ListNearMe/skeleton'
import { setStoredItem } from '../../../../modules/stored-item'
import TitleNearMe from '../../components/ListNearMe/components/TitleNearMe'
import isServerSide from '../../../../modules/is-server-side'
import loadable from '@loadable/component'

const ListNearMe = loadable(() =>
  import(/* webpackChunkName: "ListNearMe" , webpackPrefetch: true */ '../../components/ListNearMe'),
)

const errorOptions = {
  component: translate('nearme_title'),
  link: '/',
  linkText: translate('error_try_homepage'),
  logError: 'NearMe display screen',
}

const customOnChange = ({ label, value }) => {
  if (!isServerSide()) {
    setStoredItem('nearMeRadius', value)
    window.location.reload()
  }
}

const NearMeScreen = ({
  loading,
  error,
  response: { latitude, longitude, radius, data },
  ready,
  onChange,
  ...props
}) => {
  if (loading) ListNearMe.preload()

  return (
    <ErrorBoundary error={error} {...errorOptions} {...props}>
      <TitleNearMe
        place={data && data.place}
        latitude={latitude}
        longitude={longitude}
        radius={radius}
        onChange={onChange ? onChange : customOnChange}
      />
      {loading && <SkeletonNearMe item={3} {...props} />}
      {ready && <ListNearMe data={data} longitude={longitude} latitude={latitude} radius={radius} {...props} />}
    </ErrorBoundary>
  )
}

export default NearMeScreen
