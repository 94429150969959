import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import translate from '../../../../../../modules/translate'
import errorLog from '../../../../../../modules/error-log'
import MessageText from '../MessageText'
import { skeletonFade } from '../../../../../../modules/mixins'
import options from '../../../../../../config/options'
import Icon from '../../../../../../components/Icon'
import Async from 'react-async'
import { actionPOST } from '../../../../../../modules/fetch-data'
import userSession from '../../../../../Profile/modules/user-session'
import isServerSide from '../../../../../../modules/is-server-side'

const optionsUGC = options.UserGeneratedContent

const ConfirmationText = styled(MessageText).attrs(() => ({
  color: 'green',
}))``
const ErrorText = styled(MessageText).attrs(() => ({
  color: 'red',
}))``
const LoadingText = styled(MessageText)`
  ${skeletonFade}
`

const WarningIcon = styled(Icon)`
  margin-right: 0.3em;
  font-size: ${(props) => props.theme.fontSize.title2};
`

let errorMessage = ''

const PostStatus = ({ isReady, postData, onRequest, ...props }) => {
  const [showConfirmation, setShowConfirmation] = useState(true)
  let access = userSession.getAccessToken()

  if (isReady && !showConfirmation) setShowConfirmation(true)
  return (
    <Fragment>
      <Async deferFn={actionPOST} access={access} location="ugc" {...props}>
        {({ error, isFulfilled, isPending, run }) => {
          if (isReady) {
            onRequest()
            run({ data: postData })
          }
          if (isFulfilled) {
            if (!isServerSide()) {
              window.setTimeout(() => {
                if (showConfirmation) {
                  setShowConfirmation(false)
                }
              }, optionsUGC.timeSavedMessage)
            }
            return (
              <Fragment>
                {showConfirmation && <ConfirmationText>{translate('ugc_info_success')}</ConfirmationText>}
                <MessageText>
                  <WarningIcon icon="warning" />
                  <div>
                    {translate('ugc_attention_eventual_change')}&nbsp;
                    <a href="mailto:bezirke@stol.it">bezirke@stol.it</a>
                  </div>
                </MessageText>
              </Fragment>
            )
          }
          if (isPending) {
            if (!isServerSide()) {
              window.onbeforeunload = function (e) {
                return translate('are_you_sure_to_leave')
              }
            }
            return <LoadingText>{translate('ugc_info_loading')}</LoadingText>
          }
          if (error) {
            errorLog({ error: 'POST Report', info: `Could not send report with this data: ${postData}` })
            if (error.response && error.response.data && error.response.data.validation) {
              Object.keys(error.response.data.validation).forEach((key) => {
                Object.keys(error.response.data.validation[key]).forEach((innerKey) => {
                  errorMessage = error.response.data.validation[key][innerKey]
                })
              })
            }
            return (
              <Fragment>
                <ErrorText tag="p" color="red">
                  {translate('ugc_info_error')}
                </ErrorText>
                <ErrorText tag="p" color="red">
                  {errorMessage}
                </ErrorText>
              </Fragment>
            )
          } else {
            return <Fragment />
          }
        }}
      </Async>
      <MessageText>
        <WarningIcon icon="warning" />
        {translate('ugc_attention')}
      </MessageText>
    </Fragment>
  )
}

PostStatus.propTypes = {
  isReady: PropTypes.bool,
  data: PropTypes.object,
}

PostStatus.defaultProps = {
  isReady: false,
}

export default PostStatus
