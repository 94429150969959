import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ItemArticle from '../../components/ItemArticle'
import { Image0NakedItemArticle } from '../../components/ItemArticle/views/NakedItemArticle'
import AdUnit from '../../../../components/AdUnit'
import HeadlinePaper from '../../../Paper/widgets/WidgetHeadlinePaper/index'
import ContainerAd from '../../../../components/ContainerAd'
import ErrorBoundary from '../../../Error/widgets/ErrorBoundary'
import translate from '../../../../modules/translate'
import hasItems from '../../../../modules/has-items'

const StyledItemArticle = styled(ItemArticle)``

const BigLeader = styled.div`
  flex-basis: calc((100% - 30px) / 6 * 4 + 18px);
`
const SecondLeader = styled.div`
  flex-basis: calc((100% - 30px) / 6 * 2 + 6px);
  flex-wrap: wrap;

  ${StyledItemArticle}:first-child {
    margin-bottom: 6px;
  }

  ${StyledItemArticle} {
    overflow: hidden;
  }

  @media (min-width: ${(props) => props.theme.default.breakPoint + 'px'}) {
    ${Image0NakedItemArticle} {
      margin-top: -1.5px;
    }
  }
`
const ThirdLeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-basis: 100%;

  ${StyledItemArticle} {
    flex-basis: calc((100% - 30px) / 6 * 2 + 6px);
    margin-top: 6px;
  }
`
const StyledHeadlinePaper = styled(HeadlinePaper)`
  flex-basis: 100%;
  @media (min-width: 761px) {
    margin-left: -${(props) => props.theme.tablet.sizes.gapEdge};
    margin-right: -${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (min-width: ${(props) => props.theme.breakPoint + 'px'}) {
    margin-left: -${(props) => props.theme.sizes.gapEdge};
    margin-right: -${(props) => props.theme.sizes.gapEdge};
  }
`
const StyledContainerAd = styled(ContainerAd)`
  padding-left: 0;
  padding-right: 0;
  justify-content: space-between;
  flex-basis: calc(100% + 2 * ${(props) => props.theme.sizes.gapEdge});
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-basis: calc(100% + 2 * ${(props) => props.theme.tablet.sizes.gapEdge});
  }
  @media (max-width: 767px) {
    flex-basis: 100%;
    align-items: center;
    justify-content: space-evenly;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-basis: calc(100% + 2 * ${(props) => props.theme.mobile.sizes.gapEdge});
  }
`
const StyledContainerAdMobileDesktop = styled(StyledContainerAd)`
  > div {
    height: 90px;
  }
  display: flex;
  @media (max-width: 1080px) {
    > div {
      height: 0;
    }
    display: none;
  }
  @media (max-width: 767px) {
    > div {
      height: 130px;
      width: 320px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    display: flex;
  }
  @media (max-width: 600px) {
    > div {
      height: 120px;
    }
  }
`
const StyledContainerAdTablet = styled(StyledContainerAd)`
  > div {
    margin-top: 5px;
    height: 100px;
  }
  > div > div {
    margin-left: 0;
    margin-right: 0;
  }
  @media (min-width: 1081px), (max-width: 767px) {
    display: none;
    height: 0;
  }
`
const Wrapper = styled.div`
  ${(props) =>
    props.margin &&
    css`
      margin: ${(props) => props.margin};
    `}
`
const WrapperArticles = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: ${(props) => props.theme.sizes.gapEdge};
  padding-right: ${(props) => props.theme.sizes.gapEdge};
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.tablet.sizes.gapEdge};
    padding-right: ${(props) => props.theme.tablet.sizes.gapEdge};
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.mobile.sizes.gapEdge};
    padding-right: ${(props) => props.theme.mobile.sizes.gapEdge};
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.breakPoint + 'px'}) {
    ${BigLeader} {
      flex-basis: 100%;
      padding-bottom: 6px;
    }
    ${SecondLeader}, ${ThirdLeader} {
      display: flex;
      flex-basis: 100%;
      flex-direction: row;
      justify-content: space-between;

      ${StyledItemArticle} {
        margin-bottom: 0;
        overflow: unset;
        flex-basis: calc((100% - 30px) / 6 * 3 + 12px);
      }
      ${StyledContainerAd} {
        justify-content: space-evenly;
        flex-direction: column;
        flex-basis: calc((100% - 30px) / 6 * 3 + 12px);
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        margin: 0;
        padding: 0;

        > div > div {
          margin-top: 0;
        }
      }
    }
  }
  @media (max-width: 767px) {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    ${BigLeader}, ${SecondLeader}, ${ThirdLeader} {
      flex-basis: 100%;

      ${StyledItemArticle} {
        margin: 10px 10px 0 10px;
        flex-basis: 100%;
      }
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    ${BigLeader} {
      padding-bottom: 0px;
    }
  }
`

class WidgetLeader extends PureComponent {
  render() {
    const {
      adIds,
      data: { headlines, articles },
      data,
      margin,
      ...props
    } = this.props
    return (
      <Wrapper margin={margin}>
        <WrapperArticles>
          <BigLeader>
            <StyledItemArticle article={articles[0]} view="big_leader" noLazyLoading={true} noTo={true} />
          </BigLeader>
          <SecondLeader>
            <StyledItemArticle article={articles[1]} view="leader" noLazyLoading={true} noTo={true} />
            <StyledItemArticle article={articles[2]} view="leader" noLazyLoading={true} noTo={true} />
          </SecondLeader>
          <ThirdLeader>
            <StyledItemArticle article={articles[3]} view="leader" noLazyLoading={true} noTo={true} />
            <StyledItemArticle article={articles[4]} view="leader" noLazyLoading={true} noTo={true} />
            <StyledItemArticle article={articles[5]} view="leader" noLazyLoading={true} noTo={true} />
            <StyledContainerAdTablet adIds={adIds} mode={'tablet'} />
          </ThirdLeader>
          {hasItems(headlines) && (
            <ErrorBoundary component={translate('headline')}>
              <StyledHeadlinePaper data={headlines} />
            </ErrorBoundary>
          )}
          <StyledContainerAdMobileDesktop noGapEdge adIds={adIds} mode={'mobile_desktop'} />
        </WrapperArticles>
      </Wrapper>
    )
  }
}

WidgetLeader.propTypes = {
  adIds: PropTypes.arrayOf(AdUnit.propTypes.adId),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      headline: HeadlinePaper.propTypes,
      articles: ItemArticle.propTypes,
    }),
  ),
}

export default WidgetLeader
export {
  Wrapper as Wrapper0WidgetLeader,
  WrapperArticles as WrapperArticles0WidgetLeader,
  StyledItemArticle as StyledItemArticle0WidgetLeader,
  BigLeader as BigLeader0WidgetLeader,
  SecondLeader as SecondLeader0WidgetLeader,
  ThirdLeader as ThirdLeader0WidgetLeader,
  StyledContainerAd as StyledContainerAd0WidgetLeader,
}
