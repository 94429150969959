import T from 'prop-types'

import gearImg from './images/Gear.png'
import bulbImg from './images/bulb.svg'
import carImg from './images/car.svg'
import cardImg from './images/card.svg'
import sleighImg from './images/sleigh.svg'
import { Description, Title } from '../styled'
import {
  ListboxWrapper,
  ListContainer,
  ListContainerImg,
  ListItem,
  ListItemContainer,
  ListItemDescription,
  ListItemTitle,
} from './styled'
import Wrapper from '../../components/Wrapper'
import { htmlParser } from '../../../../modules/parse'

const Listbox = ({ data }) => {
  return (
    <Wrapper>
      <ListboxWrapper>
        <Title maxWidth={data.lng === 'DE' ? '682' : '950'}>{data.title}</Title>
        <Description>{htmlParser(data.content.description)}</Description>
        <ListContainer>
          <Title>{data.content.title}</Title>
          <ListContainerImg src={gearImg} alt={'gear'} />
          <ListItemContainer>
            <ListItem>
              <ListItemTitle>
                <img src={cardImg} alt={'card'} />
                <p>{data.content.list.first.title}</p>
              </ListItemTitle>
              <ListItemDescription>
                <p>
                  <span>-</span>
                </p>
                <p>{data.content.list.first.description}</p>
              </ListItemDescription>
            </ListItem>
            <ListItem>
              <ListItemTitle>
                <img src={carImg} alt={'car'} />
                <p>{data.content.list.second.title}</p>
              </ListItemTitle>
              <ListItemDescription>
                <p>
                  <span>-</span>
                </p>
                <p>{data.content.list.second.description}</p>
              </ListItemDescription>
            </ListItem>
            <ListItem>
              <ListItemTitle>
                <img src={sleighImg} alt={'sleigh'} />
                <p>{data.content.list.third.title}</p>
              </ListItemTitle>
              <ListItemDescription>
                <p>
                  <span>-</span>
                </p>
                <p>{data.content.list.third.description}</p>
              </ListItemDescription>
            </ListItem>
            <ListItem>
              <ListItemTitle>
                <img src={bulbImg} alt={'bulb'} />
                <p>{data.content.list.fourth.title}</p>
              </ListItemTitle>
              <ListItemDescription>
                {' '}
                <p>
                  <span>-</span>
                </p>
                <p>{data.content.list.fourth.description}</p>
              </ListItemDescription>
            </ListItem>
          </ListItemContainer>
        </ListContainer>
      </ListboxWrapper>
    </Wrapper>
  )
}

Listbox.propTypes = {
  data: T.shape({
    lng: T.string,
    title: T.string,
    content: T.shape({
      title: T.string,
      list: T.shape({
        first: T.shape({
          title: T.string,
          description: T.string,
        }),
        second: T.shape({
          title: T.string,
          description: T.string,
        }),
        third: T.shape({
          title: T.string,
          description: T.string,
        }),
        fourth: T.shape({
          title: T.string,
          description: T.string,
        }),
      }),
    }),
  }),
}

export default Listbox
