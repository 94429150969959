import React from 'react'
import PropTypes from 'prop-types'
import ScreenVideoOverview from './screens/ScreenVideoOverview'
import ScreenVideoDetail from './screens/ScreenVideoDetail'

const VideoComponents = {
  videos: ScreenVideoOverview,
  video: ScreenVideoDetail,
}

const ScreenVideo = ({ view, error, ...props }) => {
  const VideoComponent = VideoComponents[view]
  return <VideoComponent failed={error} {...props} />
}

ScreenVideo.propTypes = {
  view: PropTypes.string,
}

export default ScreenVideo
