import React from 'react'
import LayoutDefault from '../../layouts/Default/'
import FormUGC from './components/FormUGC'

const meta = {
  title: 'Leserreporter',
  adsTargeting: {
    STOL7_seite: 'Leserreporter',
  },
}

const UserGeneratedContent = ({ layout, ...props }) => (
  <LayoutDefault meta={meta} disable={!layout} showAds={false} {...props}>
    <FormUGC />
  </LayoutDefault>
)

UserGeneratedContent.defaultProps = {
  location: {
    pathname: undefined,
  },
  layout: true,
}

export default UserGeneratedContent
