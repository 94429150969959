import React from 'react'
import styled from 'styled-components'
import SkeletonMonitoringData from '../../components/LiveData/components/MonitoringData/skeleton'
import Text from '../../../../components/Text'
import Link from '../../../../components/Link'
import translate from '../../../../modules/translate'
import SkeletonTemperature from '../../components/LiveData/components/Temperature/skeleton'
import TextSkeleton, { Line0TextSkeleton } from '../../../../components/Text/views/TextSkeleton'
import routes from '../../../routes'

const Wrapper = styled.div`
  width: ${(props) => props.theme.sizes.col12};
  display: flex;
  background-color: ${(props) => props.theme.color.background};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-direction: column;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-direction: column;
  }
`

const FirstWrapper = styled.div`
  border-right: rgba(0, 0, 0, 0.2) 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    border-right: 0;
    border-bottom: rgba(0, 0, 0, 0.2) 1px solid;
    margin-bottom: 0;
    padding-bottom: calc(${(props) => props.theme.sizes.gapVertical} / 2);
    margin-top: calc(${(props) => props.theme.sizes.gapVertical} / 2);
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    border-right: 0;
    border-bottom: rgba(0, 0, 0, 0.2) 1px solid;
    margin-bottom: 0;
    padding-bottom: calc(${(props) => props.theme.sizes.gapVertical} / 2);
    margin-top: calc(${(props) => props.theme.sizes.gapVertical} / 2);
  }
`

const SecondWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`

const StyledMoreWrapper = styled.div`
  width: 100%;
  text-align: right;
`

const MoreText = styled(Text)`
  color: ${(props) => props.theme.color.stol};
  font-size: 15px;
  padding-right: ${(props) => props.theme.sizes.gapCol};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-right: ${(props) => props.theme.tablet.sizes.gapCol};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-right: ${(props) => props.theme.mobile.sizes.gapCol};
  }
`

const StyledTitleSkeleton = styled(TextSkeleton)`
  width: 100%;
  display: flex;
  justify-content: center;

  ${Line0TextSkeleton} {
    border-width: 25px;
  }
`

const SkeletonWidgetNearMeWeather = ({ currentCommuneSlug, response, error, loading, ...props }) => (
  <Wrapper {...props}>
    <FirstWrapper>
      <StyledTitleSkeleton lines={1} lastWidth="50%" />
      <SkeletonTemperature />
      <StyledMoreWrapper>
        <Link to={routes.getOptions('weather').path}>
          <MoreText>{translate('more')}</MoreText>
        </Link>
      </StyledMoreWrapper>
    </FirstWrapper>
    <SecondWrapper>
      <SkeletonMonitoringData />
    </SecondWrapper>
  </Wrapper>
)

export default SkeletonWidgetNearMeWeather
