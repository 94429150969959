import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Map as VendorMap, ZoomControl } from 'pigeon-maps'
import pointerMap from './components/MarkerMap/images/pointer.svg'
import MarkerMap from './components/MarkerMap'
import translate from '../../modules/translate'

const Wrapper = styled.div`

cursor: -webkit-grab;
cursor: -moz-grab;
cursor: grab;
height:300px;

#leafletID{
 color:inherit !important;
 text-decoration: none;
};

#leafletID:hover{
  text-decoration: underline;
}
`
const Attribution = () => (
  <span className="map-attribution" >
   <a id={"leafletID"} href="https://leafletjs.com"
                                                                    title="A JS library for interactive maps">Leaflet</a> | © <a
      href="https://openstreetmap.org/copyright" id={"leafletID"} >OpenStreetMap</a> contributors
  </span>
)
function Provider(x, y, z) {
  const s = String.fromCharCode(97 + ((x + y + z) % 3))
  return `https://${s}.tile.openstreetmap.de/${z}/${x}/${y}.png`
}
const Map = ({ lat, lng, width, height, children, ...props }) => (
  <Wrapper {...props}>
    <VendorMap
      center={[lat, lng]}
      provider={Provider}
      zoom={14}
      width={width}
      height={height}
      attributionPrefix={false}
      attribution={<Attribution />}
      twoFingerDrag={true}
      twoFingerDragWarning={translate('map_zoom_finger')}
    >
      <MarkerMap src={pointerMap} anchor={[lat, lng]} />
      <ZoomControl />
      {children}
    </VendorMap>

  </Wrapper>
)

Map.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.node,
}

export default Map
