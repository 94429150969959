import styled from 'styled-components'

export const OfferWrapper = styled.section`
  /*margin-bottom: 65px;
  @media (max-width: 600px) {
    margin-bottom: 40px;
  }*/
`

export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const CardContainer = styled.div`
  position: relative;
  align-self: center;
  max-width: 700px;
  width: 100%;
  padding: 0px 0px 0px 0px;
`

export const BottomListWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const BottomListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  width: 100%;
  p {
    margin-top: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    @media (max-width: 600px) {
      margin-top: 8px;
      font-size: 14px;
      line-height: 18px;
    }
  }
`
