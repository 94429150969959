import React from 'react'
import styled from 'styled-components'

import SkeletonSmallLandscapeItemArticle from '../../../Article/components/ItemArticle/views_default/SmallLandscapeItemArticle/skeleton'

import TitleTopic from '../../../../components/TitleTopic'

const ArticleWrapper = styled.div`
  width: 70%;
  margin-left: auto;
  margin-right: auto;
`
const WrapperSkeletonSmallLandscapeItemArticle = styled.div`
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
  &:first-child {
    margin-top: 0;
  }
`

const SkeletonBookmarks = ({ sortBy, searchTerm, ...props }) => {
  return (
    <div>
      <TitleTopic view="screen" title={'Lesezeichen'} />
      <br />
      <ArticleWrapper>
        <WrapperSkeletonSmallLandscapeItemArticle>
          <SkeletonSmallLandscapeItemArticle />
        </WrapperSkeletonSmallLandscapeItemArticle>
        <WrapperSkeletonSmallLandscapeItemArticle>
          <SkeletonSmallLandscapeItemArticle />
        </WrapperSkeletonSmallLandscapeItemArticle>
        <WrapperSkeletonSmallLandscapeItemArticle>
          <SkeletonSmallLandscapeItemArticle />
        </WrapperSkeletonSmallLandscapeItemArticle>
        <WrapperSkeletonSmallLandscapeItemArticle>
          <SkeletonSmallLandscapeItemArticle />
        </WrapperSkeletonSmallLandscapeItemArticle>
        <WrapperSkeletonSmallLandscapeItemArticle>
          <SkeletonSmallLandscapeItemArticle />
        </WrapperSkeletonSmallLandscapeItemArticle>
        <WrapperSkeletonSmallLandscapeItemArticle>
          <SkeletonSmallLandscapeItemArticle />
        </WrapperSkeletonSmallLandscapeItemArticle>
      </ArticleWrapper>
    </div>
  )
}

export default SkeletonBookmarks
