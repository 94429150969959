import styled from 'styled-components'

export const Title = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 62px;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '1100')}px;
  color: #353434;
  margin-bottom: 40px;
  @media (max-width: 600px) {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 16px;
  }
`

export const Description = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 160%;
  letter-spacing: -0.02em;
  color: #23242a;
  margin-bottom: 48px;
  > span {
    color: #db2f41;
    font-weight: 700;
  }
  @media (max-width: 600px) {
    font-size: 16px;
    margin-bottom: 32px;
  }
`

export const RedButton = styled.button`
  background: #db2f41;
  border-radius: 12px;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 60px;
  font-size: 24px;
  font-weight: 700;
  line-height: 133.4%;
  color: #ffffff;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '300')}px;
  border: none;
  :hover {
    background: #a12431;
  }
  @media (max-width: 600px) {
    padding: 14px 40px;
    font-size: 16px;
  }
`

export const FirstDescription = styled(Description)`
  margin-bottom: 0px;
`

export const SecondDescription = styled(Description)`
  margin-top: 0px;
  font-weight: 700;
  color: rgb(219, 47, 65);
  p {
    margin-top: 0px;
  }
`
