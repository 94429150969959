import React, { useEffect } from 'react'
import domToReact from 'html-react-parser/lib/dom-to-react'
import useScript from 'react-script-hook'

const ExternalPinpoll = (attribs, embedBoxChildren) => {
  const [loading, error] = useScript({
    src: 'https://tools.pinpoll.com/global.js',
    checkForExisting: true,
  })
  useEffect(() => {
    if (!loading && !error && window && window.Pinpoll && !!window.Pinpoll.embed) {
      window.Pinpoll.embed.start()
    }
  }, [loading, error])

  return <div>{domToReact(embedBoxChildren)}</div>
}

export default ExternalPinpoll
