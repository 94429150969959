import React from 'react'
import PropTypes from 'prop-types'
import translate from '../../../../modules/translate'
import ErrorBoundary from '../../../Error/widgets/ErrorBoundary'
import ListNewsFeed from '../../components/ListNewsFeed'

const errorOptions = {
  component: translate('newsfeed_error'),
  logError: 'ScreenNewsFeed screen display',
}

const ScreenNewsFeed = ({ response: { data }, response, error, loading, fetching, ...props }) => (
  <ErrorBoundary error={error} {...errorOptions} {...props}>
    {data && <ListNewsFeed title="Newsticker" articles={data.articles} />}
  </ErrorBoundary>
)

ScreenNewsFeed.propTypes = {
  response: PropTypes.shape({
    meta: PropTypes.object,
  }),
}

export default ScreenNewsFeed
