//Important Information: if you want to test this component you need to be logged in - change the authUrl host in the config.js in the root directory
import React, { Fragment, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import ContainerArticle from '../../../../components/ContainerArticle/index'
import Title from '../../../../components/Title/index'
import Text from '../../../../components/Text/index'
import Select from '../../../../components/Select/index'
import Button from '../../../../components/Button/index'
import Input from '../../../../components/Input/index'
import createDOMForm from 'rc-form/lib/createDOMForm'
import InputDropZone from '../../../../components/InputDropZone/index'
import IconError from '../../../Error/components/IconError/index'
import translate from '../../../../modules/translate/index'
import { withRouter } from 'react-router-dom'
import southTyrolDistricts from '../../../../config/data/southTyrolDistricts'
import MessageText, { colorSets } from './components/MessageText'
import options from '../../../../config/options'
import PostStatus from './components/PostStatus'
import NoAccessView from './components/NoAccessView'
import { useUserContext } from '../../../../components/ContextUser'
import consoleLogger from '../../../../modules/console-logger'
import TextSkeleton from '../../../../components/Text/views/TextSkeleton'

const optionsUGC = options.UserGeneratedContent

const StyledInputDropZone = styled(InputDropZone)`
  margin-left: -${(props) => props.theme.sizes.gapCol};
  width: calc(100% + (2 * ${(props) => props.theme.sizes.gapCol}) - 4px);
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-left: -${(props) => props.theme.tablet.sizes.gapCol};
    width: calc(100% + (2 * ${(props) => props.theme.tablet.sizes.gapCol}) - 4px);
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-left: -${(props) => props.theme.mobile.sizes.gapCol};
    width: calc(100% + (2 * ${(props) => props.theme.mobile.sizes.gapCol}) - 4px);
  }
`
const Wrapper = styled(ContainerArticle)`
  padding-bottom: 20px;
`
const WrapperHeader = styled(ContainerArticle)`
  padding-top: 20px;
`
const StyledNoAccessView = styled(NoAccessView)`
  padding-bottom: 20px;
`
const StyledSelect = styled(Select)`
  margin-top: 20px;
`
const StyledButton = styled(Button)`
  margin-top: 20px;
  display: table;
  margin-left: auto;
  margin-right: auto;
`
const StyledInputTitle = styled(Input)`
  margin: 20px 0;
  font-size: ${(props) => props.theme.fontSize.title1};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.title1};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.title1};
  }
  font-weight: ${(props) => props.theme.fontWeight.bold};
`
const StyledInput = styled(Input)`
  margin-top: 20px;
`
const StyledInputContent = styled(StyledInput)`
  height: 150px;
`
const TextPicture = styled(Text)`
  margin-top: 40px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
`

const ScreenUserGeneratedContent = ({ form: { getFieldProps, getFieldError, resetFields }, form, location }) => {
  const [consoleLog] = consoleLogger('FormUGC')
  const [data, setData] = useState(undefined)
  const [dataIsReady, setDataIsReady] = useState(false)
  const [validationError, setValidationError] = useState(false)

  const { profile } = useUserContext()

  const hasAccess = profile && profile.verified
  const isLoading = profile === undefined
  consoleLog('profile:', profile, 'UseUserData')

  const submit = () => {
    const validateOptions = {
      scroll: {
        alignWithLeft: false,
        alignWithTop: false,
        allowHorizontalScroll: false,
        onlyScrollIfNeeded: false,
      },
    }

    form.validateFieldsAndScroll(validateOptions, (error, value) => {
      if (error) setValidationError(error)
      if (!error) {
        let images = []
        Object.keys(value).map((key) => {
          if (key === 'imagesFiles') {
            value[key].map((currentImage) => {
              images.push({ image: currentImage.base64, subtitle: currentImage.subtitle })
              return null
            })
          }
          return null
        })
        delete value['imagesFiles']
        delete value['imagesSubtitles']
        let preparedValue = { ...value, ...{ images: images } }
        if (validationError) setValidationError(false)

        setData(preparedValue)
        setDataIsReady(true)
        resetFields()
      }
    })
  }

  const ErrorMessage = ({ field, ...props }) => {
    const errors = getFieldError(field)
    if (errors) return <IconError errorMessage={errors.join(',')} {...props} />
    return null
  }

  return (
    <Fragment>
      <WrapperHeader>
        <Title>{translate('ugc_title')}</Title>
      </WrapperHeader>

      {!hasAccess && !isLoading && <StyledNoAccessView access={!!profile} verified={profile && profile.verified} />}
      {isLoading && (
        <Wrapper>
          <TextSkeleton lines={5} fontSize="16px" lastWidth="45px" justify={false} />
        </Wrapper>
      )}
      {hasAccess && (
        <Fragment>
          <Wrapper>
            <Text>{translate('ugc_description')}</Text>
            <StyledSelect
              values={southTyrolDistricts.all}
              append={<ErrorMessage field="district" />}
              {...getFieldProps('district', {
                initialValue: '',
                rules: [{ required: true, message: translate('ugc_input_district_error') }],
              })}
            />
            <StyledInputTitle
              append={<ErrorMessage field="title" />}
              placeholder={translate('ugc_input_title')}
              border
              customValue={
                location &&
                location.state &&
                location.state.data &&
                location.state.data.title &&
                location.state.data.title
              }
              {...getFieldProps('title', {
                initialValue: '',
                rules: [{ required: true, message: translate('ugc_input_title_error') }],
              })}
            />
            <StyledInput
              append={<ErrorMessage field="description" />}
              placeholder={translate('ugc_input_description')}
              type="textarea"
              border
              customValue={
                location &&
                location.state &&
                location.state.data &&
                location.state.data.description &&
                location.state.data.description
              }
              {...getFieldProps('description', {
                initialValue: '',
                rules: [{ required: true, message: translate('ugc_input_description_error') }],
              })}
            />
            <TextPicture>
              {translate('ugc_input_dropzone_images')} <ErrorMessage field="imagesFiles" />
            </TextPicture>
            <StyledInputDropZone
              form={form}
              maxSize={optionsUGC.maxImageSize}
              reset={dataIsReady}
              {...getFieldProps('imagesFiles', {
                trigger: 'onDropChange',
                rules: [
                  {
                    required: true,
                    message: translate('ugc_input_dropzone_images_error'),
                  },
                ],
              })}
            />
            <StyledInputContent
              append={<ErrorMessage field="content" />}
              placeholder={translate('ugc_input_content')}
              type="textarea"
              border
              customValue={
                location &&
                location.state &&
                location.state.data &&
                location.state.data.content &&
                location.state.data.content
              }
              {...getFieldProps('content', {
                initialValue: '',
                rules: [{ required: true, message: translate('ugc_input_content_error') }],
              })}
            />
            <StyledButton onClick={submit}>{translate('ugc_button_send')}</StyledButton>
          </Wrapper>
          {validationError && (
            <MessageText color={colorSets.error}>
              <ul>
                {Object.keys(validationError).map((key) => (
                  <li key={key}>
                    {validationError[key].errors
                      ? validationError[key].errors[0].message
                      : validationError[key].map(() => validationError[key][0].errors[0].message)}
                  </li>
                ))}
              </ul>
            </MessageText>
          )}
          <PostStatus
            isReady={dataIsReady}
            postData={data}
            onRequest={() => {
              setDataIsReady(false)
              setData(undefined)
            }}
          />
        </Fragment>
      )}
    </Fragment>
  )
}

export default withTheme(withRouter(createDOMForm()(ScreenUserGeneratedContent)))
