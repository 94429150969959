import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import TitleTopic from '../../../../components/TitleTopic'
import TextSkeleton, { Line0TextSkeleton } from '../../../../components/Text/views/TextSkeleton'

const Wrapper = styled.div``

const WrapperInner = styled.div`
  margin-left: ${(props) => props.theme.sizes.gapEdge};
  margin-right: ${(props) => props.theme.sizes.gapEdge};
  margin-top: ${(props) => props.theme.sizes.gapVertical};
`

const StyledTextSkeleton = styled(TextSkeleton)`
  ${Line0TextSkeleton} {
    border-width: 10px;
  }
`

const SkeletonListTopic = ({ title, ...props }) => (
  <Wrapper {...props}>
    <TitleTopic view="screen" title={title} />
    <WrapperInner>
      <StyledTextSkeleton lines={15} justify={false} lastWidth="50%" />
    </WrapperInner>
  </Wrapper>
)

SkeletonListTopic.propTypes = {
  title: PropTypes.string,
}

export default SkeletonListTopic
