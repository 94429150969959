import React from 'react'
import styled, { withTheme } from 'styled-components'
import TitleTopic from '../../../../components/TitleTopic'
import PropTypes from 'prop-types'
import hasItems from '../../../../modules/has-items'
import { htmlParser } from '../../../../modules/parse'
import ContainerFull from '../../../../components/ContainerFull'

const Wrapper = styled(ContainerFull)``

const HtmlWrapper = styled(ContainerFull)`
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.text};
  color: ${(props) => props.theme.color.textPrimary};
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-left: ${(props) => props.theme.mobile.sizes.gapEdge};
    margin-right: ${(props) => props.theme.mobile.sizes.gapEdge};
  }
`

const DetailPage = ({ data: { title, html, backgroundColor }, disableTabs, ...props }) => {
  return (
    <Wrapper noGapEdge cinemaMode={backgroundColor && { backgroundPrimary: backgroundColor }} {...props}>
      {title && <TitleTopic view="screen" title={title} />}
      {hasItems(html) && <HtmlWrapper>{htmlParser(html)}</HtmlWrapper>}
    </Wrapper>
  )
}

DetailPage.propTypes = {
  data: PropTypes.shape({
    ...TitleTopic.propTypes,
    backgroundColor: PropTypes.string,
    html: PropTypes.string,
  }),
  disableTabs: PropTypes.bool,
}

export default withTheme(DetailPage)
