import styled from 'styled-components'

export const StyledFooter = styled.footer`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`

export const Wrapper = styled.div`
  max-width: 1164px;
  padding: 0px 14px 48px 14px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  p {
    margin: 0;
  }
  button {
    cursor: pointer;
    border: none;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  @media (max-width: 600px) {
    padding: 24px 13px;
  }
`

export const FooterBackground = styled.footer`
  background: linear-gradient(302.28deg, #d5e8f1 5.08%, #daf1fb 54.02%, rgba(254, 254, 254, 0.03) 100%);
  transform: matrix(1, 0, 0, -1, 0, 0);
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
`

export const FormTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 160%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #23242a;
`

export const FooterLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const FooterLogo = styled.img`
  width: 111px;
  margin-bottom: 18px;
  @media (max-width: 600px) {
    margin-bottom: 36px;
  }
`

export const LinkContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 600px) {
    display: none;
  }
`
export const LinkMobileContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;
  div {
    display: flex;
    flex-direction: column;
    width: 100%;
    a {
      margin-bottom: 16px;
    }
  }
  @media (min-width: 601px) {
    display: none;
  }
`

export const Link = styled.a`
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 133.4%;
  color: #8a8a8a;
  cursor: pointer;
  span {
    text-decoration: underline;
  }
`

export const VerticalDivider = styled.div`
  border: 1px solid #8a8a8a;
  height: 16px;
  margin: 0 8px;
  width: 0;
`

export const Form = styled.form`
  @media (max-width: 600px) {
    margin-bottom: 68px;
  }
`
