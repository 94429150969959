import React from 'react'
import PropTypes from 'prop-types'
import translate from '../../../../modules/translate'
import ErrorBoundary from '../../../Error/widgets/ErrorBoundary'
import MessageError from '../../../Error/screens/MessageError'
import SkeletonListTopic from '../../components/ListTopic/skeleton'
import loadable from '@loadable/component'
import isServerSide from '../../../../modules/is-server-side'

const ListTopic = loadable(() =>
  import(/* webpackChunkName: "ListTopic" , webpackPrefetch: true */ '../../components/ListTopic'),
)

const errorOptions = {
  component: translate('topic'),
  link: '/',
  linkText: translate('error_try_homepage'),
  logError: 'Topic screen display',
}

const ScreenTopic = ({ response: { data }, loading, error, ready, match, location, apiUrl, ...props }) => {
  const pushToErrorScreen = () => {
    props.history.push({ pathname: '/p/error', state: { location: isoLocation } })
  }

  let isoLocation = location ? location : !isServerSide() && window.location
  if (loading) ListTopic.preload()
  return (
    <ErrorBoundary
      error={error}
      onError={(error) => {
        pushToErrorScreen(error)
      }}
      {...errorOptions}
    >
      {error && <MessageError logInfo={error.message} {...errorOptions} />}
      {loading && (
        <SkeletonListTopic title={match && match.params.slug.charAt(0).toUpperCase() + match.params.slug.slice(1)} />
      )}
      {data && <ListTopic data={data} apiUrl={apiUrl} disableTabs />}
    </ErrorBoundary>
  )
}

ScreenTopic.propTypes = {
  response: PropTypes.shape({
    meta: PropTypes.object,
    data: PropTypes.object,
  }),
  loading: PropTypes.bool,
  error: PropTypes.object,
  ready: PropTypes.bool,
  match: PropTypes.object,
}

ScreenTopic.defaultProps = {
  response: {
    data: undefined,
  },
}

export default ScreenTopic
