import React from 'react'
import styled from 'styled-components'
import locationIcon from './images/location.svg'
import sourceIcon from './images/source.svg'
import Link from '../Link'
import ReactGA from 'react-ga'
import pushEventToDataLayer from '../../modules/push-event-to-data-layer'
import { actionPOST } from '../../modules/fetch-data'
import moment from 'moment'
import JobIcon from './images/JobIcon'
import isServerSide from '../../modules/is-server-side'
import ReactPlayer from 'react-player'
import logoLook4U from '../../layouts/Blocks/views_article/BlockJobs/LOOK4U.svg'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

const Wrapper = styled(Link)`
  width: 100%;
  display: flex;
  padding: ${(props) => (props.isWidget ? '15px 15px 15px 23px' : '24px 49px')};
  height: ${(props) => (props.isWidget ? '135px' : '153px')};
  outline: 0.5px solid rgba(205, 205, 205, 1);
  flex-direction: row;
  box-sizing: border-box;
  background-color: white;
  margin-bottom: ${(props) => (props.isWidget ? '0' : '1px')};
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;

  &:hover {
    background-color: ${(props) => (props.video ? '#ffd8d9' : '#f8f8f8;')};
    outline: ${(props) => props.video && '0.5px solid #d20026;'};
  }

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding: 15px 40px;
    margin-bottom: 1px;
    height: 135px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding: 12px 16px 5px 21px;
    height: 94.2px;
  }
`

const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 105px;
  width: 105px;
  flex: none;
  margin-right: 22px;
  box-shadow: 0 2px 6px #00000042;
  background-color: white;
  border-radius: 12px;
  opacity: 1;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    height: 95px;
    width: 95px;
    margin-right: 26px;
    margin-top: 4px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    height: 50px;
    width: 50px;
    margin-right: 15px;
    margin-bottom: 7px;
    margin-top: 9px;
  }
`

const StyledVideoLogoWrapper = styled(StyledLogoWrapper)`
  display: block;
  height: ${(props) => (props.isWidget ? '115px' : '130px')};
  top: ${(props) => (props.isWidget ? '-5px' : '')};

  width: ${(props) => (props.isWidget ? '105px' : '105px')};
  margin-right: ${(props) => (props.isWidget ? '22px' : '22px')};
  background-color: #e5e5e5;
  position: relative;
  bottom: 16px;
  color: black;
  :hover {
    background-color: #d41e25;
    color: white;
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    height: ${(props) => (props.isWidget ? '105px' : '115px')};
    width: 95px;
    bottom: ${(props) => (props.isWidget ? '16px' : '7px')};
    margin-right: 26px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    height: 70px;
    width: 50px;
    margin-right: 15px;
    margin-top: 5px;
    bottom: ${(props) => (props.isWidget ? '16px' : '7px')};
  }
`

const StyledLogoSlot = styled.div`
  color: #aeaeae;
  width: ${(props) => (props.source ? '100%' : '67px')};
  height: ${(props) => (props.source ? '100%' : '67px')};
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: ${(props) => (props.source ? '100%' : '35px')};
    height: ${(props) => (props.source ? '100%' : '35px')};
  }
`
const StyledVideoLogoSlot = styled(StyledLogoSlot)`
  position: relative;
  border-radius: 12px;
  background-color: white;
  width: 100%;
  height: ${(props) => (props.isWidget ? '92px' : '105px')};

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: 100%;
    height: ${(props) => (props.source ? '82px' : '35px')};
    height: ${(props) => (props.isWidget ? '82px' : '95px')};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 100%;
    height: 50px;
  }
  box-shadow: 0px 2px 6px #00000042;
`

const StyledLogo = styled.div`
  width: inherit;
  height: inherit;
  background: transparent url(${(props) => props.source}) center center no-repeat padding-box;
  background-size: ${(props) => (props.isLogo ? 'contain' : 'cover')};
  image-rendering: ${(props) => (props.isLogo ? '-webkit-optimize-contrast' : 'auto')};
  border-radius: 12px;
`

const StyledJobInfo = styled.div`
  width: calc(100% - 105px - 22px);
  font-family: ${(props) => props.theme.font.primary};
  display: flex;
  flex-direction: column;
  line-height: normal;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: calc(100% - 95px - 26px);
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: calc(100% - 50px - 15px);
  }
`

const StyledTopLine = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: 12px;
  }
`

const StyledEmployerName = styled.div`
  flex: 1;
  align-self: center;
  box-sizing: border-box;
  padding-top: 2px;
  height: 22.2px;
  color: ${(props) => props.theme.color.textSecondary};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    height: 17.4px;
  }
`

const StyledEmploymentType = styled.div`
  border: 1px solid
    ${(props) => (props.children === 'Vollzeit' || props.children === 'Teilzeit' ? '#dd0000' : 'transparent')};
  border-radius: 10px;
  background-color: ${(props) =>
    props.children === 'Teilzeit' ? '#FFEBEB' : props.children === 'Vollzeit' ? '#DD0004' : 'transparent'};
  margin-left: auto;
  padding: 1px 8px 0;
  color: ${(props) =>
    props.children === 'Teilzeit' ? '#E00000' : props.children === 'Vollzeit' ? 'white' : '#E00000'};
  text-align: end;
  box-sizing: border-box;
  height: 22.2px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    height: 17.4px;
  }
`

const StyledJobTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-top: 6px;
  color: #000000;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: 3px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: 15px;
    font-weight: 600;
  }
`

const StyledFooter = styled.div`
  width: -webkit-fill-available;
  max-width: -webkit-fill-available;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: auto;
  font-size: 12px;
`

const StyledLocationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: ${(props) => (props.isWidget ? '175px' : '370px')};
  min-width: ${(props) => (props.isWidget ? '175px' : '370px')};
  color: #464646;
  padding-top: 7px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: ${(props) => (props.isWidget ? '240px' : '175px')};
    min-width: ${(props) => (props.isWidget ? '240px' : '175px')};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-top: 5px;
    width: 205px;
    min-width: 205px;
  }
  @media (max-width: ${(props) => props.theme.mobileS.breakPoint + 'px'}) {
    width: 115px;
    min-width: 115px;
  }
`

const StyledLocationIcon = styled.div`
  margin-right: 6px;
  width: 10px;
  height: 13px;
  background-image: url(${() => locationIcon});
  background-size: cover;
`

const StyledLocationName = styled.div`
  flex: 1;
  margin-right: 10px;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const StyledSourceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: max-content;
  padding-top: 7px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-top: 5px;
  }
`

const StyledSourceIcon = styled.div`
  margin-right: 6px;
  width: 15px;
  height: 15px;
  background-image: url(${() => sourceIcon});
  background-size: cover;
`

const StyledSourceName = styled.div`
  flex: 1;
  color: #989898;
`

const StyledDate = styled.div`
  margin-left: auto;
  color: #464646;
  padding: 1px 8px 0;
  padding-top: 7px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: none;
  }
`

const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: black;
  width: 640px;
  height: 360px;
  @media (max-width: 800px) {
    top: unset;
    left: unset;
    transform: unset;
    width: 100%;
    height: 100%;
  }
`
const StyledVideoField = styled.div`
  color: inherit;
  text-align: center;
  margin-top: ${(props) => (props.isWidget ? '2px' : '4px')};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => (props.isWidget ? '2px' : '0px')};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: 13px;
    margin-top: ${(props) => (props.isWidget ? '2px' : '2px')};
  }
`

const CrossDiv = styled.div`
  position: absolute;
  top: -26px;
  right: -27px;
  opacity: 0.8;
  color: #afafaf;
  font-size: 30px;
  width: 18px;

  margin-left: auto;
  :after {
    background-color: #fff;

    content: '';
    display: block;
    height: 2px;
    margin: 7px 0;

    transform: translateY(-4px) rotate(135deg);
    :hover {
      background-color: #d41e25;
    }
  }

  :before {
    background-color: #fff;

    content: '';
    display: block;
    height: 2px;
    margin: 7px 0;

    transform: translateY(5px) rotate(-135deg);
    :hover {
      background-color: #d41e25;
    }
  }

  cursor: pointer;
  @media (max-width: 800px) {
    top: 70px;
    right: 19px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    top: 67px;
    right: 10px;
  }
`

const VideoJobIcon = styled(JobIcon)`
  max-height: 92px;
  width: inherit;
  @media (max-width: 1080px) {
    max-height: 82px;
    width: inherit;
  }
`

const JobElement = ({ data, isWidget, ...props }) => {
  const [open, setOpen] = React.useState(false)

  const onStartVideo = () => {
    pushEventToDataLayer(
      'JobwidgetVideoStart',
      ['Partner', data['partner']],
      ['JobwidgetType', isWidget ? 'Block' : 'Seite'],
      ['JobwidgetBranchen', data['sectors'] && data['sectors'].join('; ')],
      ['gtm.elementUrl', data['link']],
    )
  }

  function handleOpen(e) {
    e.preventDefault()
    pushEventToDataLayer(
      'JobwidgetVideoClick',
      ['Partner', data['partner']],
      ['JobwidgetType', isWidget ? 'Block' : 'Seite'],
      ['JobwidgetBranchen', data['sectors'] && data['sectors'].join('; ')],
      ['gtm.elementUrl', data['link']],
    )
    return setOpen(true)
  }

  function handleClose(e) {
    setOpen(false)
  }

  let picture = data['image'] || data['logo']
  let isLogo = !!data['logo']
  const isPartnerLook4U = data['partner'] === 'Look4U'
  if (isPartnerLook4U && !picture) {
    picture = logoLook4U
    isLogo = true
  }
  return (
    <Wrapper
      to={data['link']}
      target={'_blank'}
      isWidget={isWidget}
      video={data.video}
      {...props}
      onClick={() => {
        ReactGA.event({
          category: isWidget ? 'Jobwidget Block' : 'Jobwidget Seite',
          action: data['partner'],
          label: data['sectors'] && data['sectors'].join('; '),
        })
        pushEventToDataLayer(
          'Jobwidget',
          ['Partner', data['partner']],
          ['JobwidgetType', isWidget ? 'Block' : 'Seite'],
          ['JobwidgetBranchen', data['sectors'] && data['sectors'].join('; ')],
          ['gtm.elementUrl', data['link']],
        )

        if (!isWidget && data.id) {
          const postOptions = {
            path: process.env.RAZZLE_JOBWIDGET_API_HOST + '/public/log',
            accessType: 'APIKEY',
            access: process.env.RAZZLE_JOBWIDGET_API_KEY,
            data: [
              {
                aid: 4100,
                pid: 16,
                rt: 'job_ads',
                rid: [data.id],
              },
            ],
          }

          if (!isServerSide() && window.navigator.userAgent) {
            postOptions.userAgent = window.navigator.userAgent
          }

          actionPOST([], postOptions)
        }
      }}
    >
      {data.video ? (
        <StyledVideoLogoWrapper isWidget={isWidget} onClick={handleOpen}>
          <StyledVideoLogoSlot isWidget={isWidget} source={picture}>
            {!picture && <VideoJobIcon />}
            {picture && <StyledLogo source={picture} isLogo={isLogo} />}
          </StyledVideoLogoSlot>
          <StyledVideoField isWidget={isWidget}>Video</StyledVideoField>
        </StyledVideoLogoWrapper>
      ) : (
        <StyledLogoWrapper>
          <StyledLogoSlot source={picture}>
            {!picture && <JobIcon />}
            {picture && <StyledLogo source={picture} isLogo={isLogo} />}
          </StyledLogoSlot>
        </StyledLogoWrapper>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClick={handleClose}
      >
        <StyledBox>
          <ReactPlayer
            config={{
              youtube: {
                playerVars: { showinfo: 1 },
              },
            }}
            width="100%"
            height="100%"
            muted={true}
            playing={true}
            url={data.video}
            onStart={onStartVideo}
          />
          <CrossDiv onClick={handleClose}></CrossDiv>
        </StyledBox>
      </Modal>
      <StyledJobInfo>
        <StyledTopLine>
          {data['employer_name'] && <StyledEmployerName>{data['employer_name']}</StyledEmployerName>}
          {data['type'] && <StyledEmploymentType>{data['type']}</StyledEmploymentType>}
        </StyledTopLine>
        <StyledJobTitle noTopLine={!(data['employer_name'] || data['type'])}>{data['title']}</StyledJobTitle>
        <StyledFooter>
          <StyledLocationWrapper isWidget={isWidget}>
            {data['location'] && <StyledLocationIcon />}
            {data['location'] && <StyledLocationName>{data['location']}</StyledLocationName>}
          </StyledLocationWrapper>
          {data['partner'] && (
            <StyledSourceWrapper>
              <StyledSourceIcon />
              <StyledSourceName>{data['partner']}</StyledSourceName>
            </StyledSourceWrapper>
          )}
          {!isWidget && data['date'] && <StyledDate>{moment(data['date']).format('DD.MM.YYYY')}</StyledDate>}
        </StyledFooter>
      </StyledJobInfo>
    </Wrapper>
  )
}

export default JobElement

export {
  Wrapper as Wrapper0JobElement,
  StyledLogoWrapper as StyledLogoWrapper0JobElement,
  StyledVideoLogoWrapper as StyledVideoLogoWrapper0JobElement, //neu
  StyledVideoLogoSlot as StyledVideoLogoSlot0JobElement, //neu
  StyledJobInfo as StyledJobInfo0JobElement,
  StyledTopLine as StyledTopLine0JobElement,
  StyledEmploymentType as StyledEmploymentType0JobElement,
  StyledFooter as StyledFooter0JobElement,
  StyledLocationWrapper as StyledLocationWrapper0JobElement,
  StyledLocationIcon as StyledLocationIcon0JobElement,
  StyledSourceWrapper as StyledSourceWrapper0JobElement,
  StyledSourceIcon as StyledSourceIcon0JobElement,
  StyledDate as StyledDate0JobElement,
  StyledLogo as StyledLogo0JobElement,
  StyledLogoSlot as StyledLogoSlot0JobElement,
  StyledEmployerName as StyledEmployerName0JobElement,
  StyledJobTitle as StyledJobTitle0JobElement,
  StyledLocationName as StyledLocationName0JobElement,
  StyledVideoField as StyledVideoField0JobElement, //neu
  StyledBox as StyledBox0JobElement, //neu
  CrossDiv as CrossDiv0JobElement, //neu
}
