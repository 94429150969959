import HTMLReactParser from 'html-react-parser'
import parserOptions, { SMARTAD } from '../options-html-parser'
import imageConfigs from '../../config/images'
import PropTypes from 'prop-types'

/**
 * parses html and returns react nodes
 * @param htmlContent
 * @param parserProps
 * @returns {ReactElement | ReactElement[] | string}
 */
const htmlParser = (htmlContent, parserProps) => {
  return HTMLReactParser(htmlContent, parserOptions(parserProps))
}

/**
 * can be used to format bytes into human readable formats - for example 100KB
 * @param bytes
 * @param decimals
 * @returns {string}
 */
const formatBytes = (bytes, decimals) => {
  if (0 === bytes) return '0 Bytes'
  const c = 1024,
    d = decimals || 2,
    e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    f = Math.floor(Math.log(bytes) / Math.log(c))
  return parseFloat((bytes / Math.pow(c, f)).toFixed(d)) + ' ' + e[f]
}

const toKebabCase = (text) =>
  text &&
  text
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('-')

/**
 * a Class to format images links which typically are provided like so:
 * https://s3-images.stol.it/_images/{type}/{dimensions}/img/2019/11/in-ganz-suedtirol-begann-der-montag-mit-minustemperaturen-2.jpg
 */
class ImageFormat {
  constructor(url) {
    this.url = url
  }

  format(format) {
    const imageConfig = imageConfigs[format]
    const imageLink = this.url.replace('{type}', imageConfig.type)
    const imageWidth = imageConfig.width ? imageConfig.width : ''
    const imageHeight = imageConfig.height ? imageConfig.height : ''
    return imageLink.replace('{dimensions}', imageWidth + 'x' + imageHeight)
  }
}

/**
 * can convert a remote hosted image to a base64 string.
 * @param url
 * @param callback
 */
const imageToBase64 = (url, callback) => {
  if (!url.includes('data:image')) {
    const httpRequest = new XMLHttpRequest()
    httpRequest.onload = () => {
      const fileReader = new FileReader()
      fileReader.onloadend = () => {
        callback(fileReader.result)
      }
      fileReader.readAsDataURL(httpRequest.response)
    }
    httpRequest.open('GET', url)
    httpRequest.responseType = 'blob'
    httpRequest.send()
  } else {
    callback(url)
  }
}

htmlParser.propTypes = {
  htmlContent: PropTypes.string,
  ...parserOptions.propTypes,
}

formatBytes.propTypes = {
  bytes: PropTypes.number,
  decimals: PropTypes.number,
}

ImageFormat.propTypes = {
  url: PropTypes.string,
  format: PropTypes.string,
}

export { htmlParser, SMARTAD, formatBytes, toKebabCase, ImageFormat, imageToBase64 }
