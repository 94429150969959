import { Redirect, Route, Switch } from 'react-router-dom'
import React from 'react'
import PropTypes from 'prop-types'
import createUrl from '../../modules/create-url'
import ScreenLoader from '../ScreenLoader'
import RouteScreen from '../../class/RouteScreen'
import ScreenLayout from '../ScreenLayout'
import checkUrlForRedirect from './modules/check-url-for-redirect'
import isServerSide from '../../modules/is-server-side'

const Router = ({ customRoutes, layouts, blocks, modules, components, location, ...props }) => (
  <Switch location={location}>
    {customRoutes.map((routeName, index) => {
      const route = customRoutes.getRoute(routeName)
      const routeOptions = customRoutes.getOptions(routeName)

      if (!routeOptions.path) {
        return false
      }
      if (routeOptions.redirect) {
        const { isExternal, internalUrl } = checkUrlForRedirect(routeOptions.redirect)
        const redirectTo = internalUrl || routeOptions.redirect
        const pathnameBrowser = !isServerSide() && window.location && window.location.pathname
        if (isExternal && pathnameBrowser === routeOptions.path) {
          window.location.href = routeOptions.redirect
          return
        }
        return <Redirect from={routeOptions.path} to={redirectTo} />
      }

      let RouteElement = components[routeOptions.topic]

      if (typeof routeOptions.screen === 'string') {
        RouteElement = (props) => (
          <ScreenLoader
            Screen={components[routeOptions.screen]}
            modules={modules}
            blocks={blocks}
            components={components}
            {...props}
          />
        )
      }

      if (routeOptions.screen instanceof RouteScreen) {
        const routeScreen = routeOptions && routeOptions.screen
        const routeLayout = routeScreen && routeScreen.layout
        const routeLayoutConfig = layouts[routeLayout]
        //console.log('blocks in Router: ', blocks)
        RouteElement = (props) => (
          <ScreenLayout
            layout={routeLayoutConfig}
            blocks={blocks}
            modules={modules}
            components={components}
            {...props}
          />
        )
      }

      return (
        <Route
          key={index}
          path={routeOptions.path}
          exact={routeOptions.exact}
          render={({ location, match, ...routeProps }) =>
            React.createElement(RouteElement, {
              ...routeProps,
              location: location,
              match: match,
              view: routeName,
              api: routeOptions.api ? routeOptions.api : location && !routeOptions.apiUrl && location.pathname,
              apiUrl: createUrl(routeOptions.apiUrl),
              route: route,
              ...props,
            })
          }
        />
      )
    })}
  </Switch>
)

Router.propTypes = {
  initialData: PropTypes.object,
  customRoutes: PropTypes.object,
}

export default Router
