import styled from 'styled-components'

export const ListboxWrapper = styled.section`
  margin-top: 48px;
  @media (max-width: 600px) {
    margin-top: 40px;
  }
`

export const ListContainer = styled.div`
  position: relative;
  background: #ffffff;
  padding: 48px 16px 28px 72px;
  margin-bottom: 62px;
  @media (max-width: 600px) {
    padding: 32px 24px;
    margin-bottom: 48px;
  }
`

export const ListItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ListContainerImg = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  @media (max-width: 600px) {
    width: 50%;
  }
`
export const ListItem = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 20px;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
`
export const ListItemTitle = styled.div`
  display: flex;
  align-items: center;
  > p {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 110%;
    letter-spacing: -0.03em;
    color: #23242a;
    margin-left: 30px;
  }
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: start;
    > p {
      font-size: 24px;
      margin-left: 0;
      margin-top: 20px;
      margin-bottom: 0px;
    }
  }
`

export const ListItemDescription = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  @media (max-width: 600px) {
    margin-left: 0px;
  }
  > p {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 110%;
    letter-spacing: -0.03em;
    @media (max-width: 600px) {
      font-size: 20px;
      margin-top: 8px;
    }
  }
  span {
    font-weight: 700;
    margin-right: 30px;
    text-align: center;
    @media (max-width: 600px) {
      display: none;
    }
  }
`
