import React from 'react'
import PropTypes from 'prop-types'
import translate from '../../../../modules/translate'
import ErrorBoundary from '../../../Error/widgets/ErrorBoundary'
import MessageError from '../../../Error/screens/MessageError'
import SkeletonDetailPage from '../../components/DetailPage/skeleton'
import DetailPage from '../../components/DetailPage'
import isServerSide from '../../../../modules/is-server-side'

const errorOptions = {
  component: translate('topic'),
  link: '/',
  linkText: translate('error_try_homepage'),
  logError: 'Topic screen display',
}

const ScreenTopic = ({ response: { data }, loading, error, ready, match, location, ...props }) => {
  const pushToErrorScreen = () => {
    props.history.push({ pathname: '/p/error', state: { location: isoLocation } })
  }

  let isoLocation = location ? location : !isServerSide() && window.location

  return (
    <ErrorBoundary
      error={error}
      onError={(error) => {
        pushToErrorScreen(error)
      }}
      {...errorOptions}
    >
      {error && <MessageError logInfo={error.message} {...errorOptions} />}
      {loading && (
        <SkeletonDetailPage
          title={
            match &&
            match.params &&
            match.params.slug &&
            match.params.slug.charAt(0).toUpperCase() + match.params.slug.slice(1)
          }
        />
      )}
      {data && <DetailPage data={data} />}
    </ErrorBoundary>
  )
}

ScreenTopic.propTypes = {
  response: PropTypes.shape({
    meta: PropTypes.object,
    data: PropTypes.object,
  }),
  loading: PropTypes.bool,
  error: PropTypes.object,
  ready: PropTypes.bool,
  match: PropTypes.object,
}

ScreenTopic.defaultProps = {
  response: {
    data: undefined,
  },
}

export default ScreenTopic
