const unionBy = require('lodash/unionBy')
const defaultUrl = process.env.RAZZLE_ENVIRONMENT === 'production' ? 'stol.it' : 're.stol.it'
const defaultHost =
  process.env.RAZZLE_ENVIRONMENT === 'production' ? `https://www.${defaultUrl}` : `https://${defaultUrl}`
const backendHost = process.env.RAZZLE_BACKEND_HOST ? process.env.RAZZLE_BACKEND_HOST : defaultHost

const options = {
  Article: {
    item: {
      hiddenElements: ['date'],
    },
  },
  NewNewsMessage: {
    checkInterval: 30000,
  },
  Video: {
    get default() {
      return {
        production: {
          descriptionUrl: `${backendHost}/videos`,
          ads: {
            pre: 'stol7_video_pre',
            post: 'stol7_video_post',
          },
          vendor: (self = options.Video.default.production) => {
            const descriptionUrl = encodeURIComponent(self.descriptionUrl)
            const tag = (adUnit, targetURI) =>
              `//pubads.g.doubleclick.net/gampad/live/ads?iu=/${options.Ads.networkId}/${adUnit}&description_url=${descriptionUrl}&tfcd=0&npa=0&sz=640x360&cust_params=${targetURI}&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=`
            return {
              playerScript: 'https://cdn.jwplayer.com/libraries/VjLBFSOg.js',
              settings: {
                ga: {
                  label: 'title',
                },
                related: {
                  file: 'https://cdn.jwplayer.com/v2/playlists/DOyrebfq',
                },
                displaytitle: false,
                displaydescription: false,
              },
              advertising: (targetURI = '') => ({
                client: 'googima',
                adscheduleid: 'dlab123',
                schedule: [
                  {
                    offset: 'pre',
                    tag: tag(self.ads.pre, targetURI),
                  },
                  {
                    offset: 'post',
                    tag: tag(self.ads.post, targetURI),
                  },
                ],
                outstream: false,
                preloadAds: true,
                rules: {
                  startOnSeek: 'pre',
                  timeBetweenAds: 0,
                },
                vpaidcontrols: false,
                vpaidmode: 'insecure',
              }),
              bidding: {},
            }
          },
        },
        get staging() {
          return {
            ...this.production,
            vendor: (self = options.Video.default.staging) => {
              const defaultVendor = this.production.vendor(self)
              return {
                ...defaultVendor,
                settings: {
                  ...defaultVendor.settings,
                  related: {
                    file: 'https://cdn.jwplayer.com/v2/playlists/lzXNzqxY',
                  },
                },
              }
            },
          }
        },
      }
    },
  },
  Layout: {
    districtsNav: {
      nationwideIdentifier: 'landesweit',
    },
  },
  User: {
    authUrl: `${backendHost}/oauth/`,
    requiredUserData: [
      'firstName',
      'lastName',
      'user_gender',
      'user_place_of_residence',
      'user_address',
      'user_zip',
      'user_phone',
    ],
  },
  UserGeneratedContent: {
    maxImageSize: 10490000,
    timeSavedMessage: 10000,
  },
  Search: {
    defaultSortBy: 'datum',
  },
  Ads: {
    networkId: '2273514',
    sPlusExcludedAdsLabels: ['Programmatic', 'Standard'],
  },
  AutomaticLogin: {
    updateBackendUserAfterDays: 1,
  },
  Plus: {
    tag: 'Plus',
    vendor: {
      scriptFile: 'https://cdn.tinypass.com/api/tinypass.min.js',
      CxenseSiteId: '1152137728223711051',
      locale: 'de_DE',
      serverSideCookieAPI: `${backendHost}/pcookie`,
      live: {
        Endpoint: 'https://buy.tinypass.com/api/v3',
      },
      sandbox: {
        Endpoint: 'https://sandbox.tinypass.com/api/v3',
      },
    },
    tabLinks: [
      {
        label: 's+',
        value: '/rubrik/plus',
      },
      {
        label: 'Gesundheit',
        value: '/tag/Ernährung',
      },
      {
        label: 'Finanzen',
        value: '/tag/Mein%20Geld',
      },
      {
        label: 'Interviews',
        value: '/tag/Lebensgeschichten',
      },
      {
        label: 'Tipps',
        value: '/tag/Ratgeber',
      },
      {
        label: 'Comedy',
        value: '/tag/Wöchenschau',
      },
      {
        label: 'Menschen',
        value: '/tag/Menschen',
      },
      {
        label: 'Kriminalfälle',
        value: '/tag/Mordfälle%20in%20Südtirol',
      },
    ],
  },
  Meta: {
    app_store_id: 427788166,
    play_store_id: 'com.keepinmind.stol_mobile',
  },
  Marketing: {
    email: 'support@firstavenue.it',
  },
  Geo: {
    host: 'https://nominatim.openstreetmap.org/reverse?format=json',
  },
  Menu: {
    updateTime: 60 * 60 * 1000,
  },
  Api: {
    serverSide: '.bc.stol.it/api/rest/',
    host: `${backendHost}/api/rest/`,
    timeout: 10000,
  },
  Bundles: {
    ressourcePath: `${defaultHost}/js/spn-widgets/`,
    stnRessourcePath: `${defaultHost}/js/stn-widgets/`,
    ressourcePathAPPs: `${defaultHost}/js/app/`,
    defaultHost: `${defaultHost}`,
    backendHost: `${backendHost}`,
  },
  CloudflareCachedFiles: {
    live: {
      stolApp: [
        'https://www.stol.it/js/app/manifest.js',
        'https://www.stol.it/js/app/vendor.js',
        'https://www.stol.it/js/app/athesia_react.article.js',
      ],
      configurator: [
        'https://www.stol.it/js/configurator/manifest.js',
        'https://www.stol.it/js/configurator/vendor.js',
        'https://www.stol.it/js/app/athesia_react.home.js',
      ],
      spn: [
        'https://www.stol.it/js/spn-widgets/athesia_react.js?v=114',
        'https://www.stol.it/js/spn-widgets/manifest.js?v=114',
        'https://www.stol.it/js/spn-widgets/vendor.js?v=114',
      ],
      stn: [
        'https://www.stol.it/js/stn-widgets/athesia_react.js',
        'https://www.stol.it/js/stn-widgets/manifest.js',
        'https://www.stol.it/js/stn-widgets/vendor.js',
      ],
      ads: ['https://www.stol.it/js/ads/athesia_react.js'],
      components: [
        'https://www.stol.it/js/components/dlabmapdistrict/index.js',
        'https://www.stol.it/js/components/dlabtabs/index.js',
      ],
      fubas: ['https://www.stol.it/js/fubas/athesia_react.js'],
    },
    staging: {
      stolApp: [
        'https://re.stol.it/js/app/manifest.js',
        'https://re.stol.it/js/app/vendor.js',
        'https://re.stol.it/js/app/athesia_react.article.js',
      ],
      configurator: [
        'https://re.stol.it/js/configurator/manifest.js',
        'https://re.stol.it/js/configurator/vendor.js',
        'https://re.stol.it/js/app/athesia_react.home.js',
      ],
      spn: [
        'https://re.stol.it/js/spn-widgets/athesia_react.js?v=114',
        'https://re.stol.it/js/spn-widgets/manifest.js?v=114',
        'https://re.stol.it/js/spn-widgets/vendor.js?v=114',
      ],
      stn: [
        'https://re.stol.it/js/stn-widgets/athesia_react.js',
        'https://re.stol.it/js/stn-widgets/manifest.js',
        'https://re.stol.it/js/stn-widgets/vendor.js',
      ],
      ads: ['https://re.stol.it/js/ads/athesia_react.js'],
      components: [
        'https://re.stol.it/js/components/dlabmapdistrict/index.js',
        'https://re.stol.it/js/components/dlabtabs/index.js',
      ],
      fubas: ['https://re.stol.it/js/fubas/athesia_react.js'],
    },
  },
  Jobs: {
    partner: [
      {
        name: 'dolomitenmarkt.it',
        id: 14986,
      },
      {
        name: 'joobz.it',
        id: 15011,
      },
      {
        name: 'karriere-suedtirol.com',
        id: 15012,
      },
      {
        name: 'suedtirolerjobs.it',
        id: 15013,
      },
      {
        name: 'look4u',
        id: 15015,
      },
    ],
  },
  Images: {
    host: `https://s3-images.${defaultUrl}`,
  },
}

module.exports = options
