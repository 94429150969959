const viewports = {
  desktop: [840, 0],
  all: [0, 0],
}

const sizes = {
  button_s: [300, 50],
  button: [300, 100],
  buttonAds_s: [320, 50],
  buttonAds: [320, 100],
  largeLeaderBoard_s: [728, 90],
  largeLeaderBoard: [970, 90],
  superBanner: [970, 150],
  strip: [990, 30],
  superStrip: [990, 60],
  wideSuperbanner: [990, 120],
  billboard: [970, 250],
  sky: [300, 600],
  rectangle: [300, 250],
  skin: [1, 1],
  inread: [1, 1],
  interstitial: [1, 1],
  superbannerSkin: [1400, 600],
  oneByOne: [1, 1],
  smallInterstitial: [320, 480],
  // smallInterstitialInv: [480, 320],
  // mediumInterstitial: [740, 360],
  // mediumInterstitial2: [420, 740],
  mediumInterstitial3: [600, 600],
  bigInterstitial: [1000, 620],
  bigInterstitial2: [1000, 575],
}

const ladigeConfig = {
  sizeMappings: {
    IS: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.oneByOne, sizes.smallInterstitial],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.oneByOne, sizes.mediumInterstitial3, sizes.bigInterstitial, sizes.bigInterstitial2],
      },
    ],
    LEADERBOARD: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.button, sizes.button_s, sizes.buttonAds, sizes.buttonAds_s],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.largeLeaderBoard_s],
      },
    ],
    SUPERBANNER: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.button, sizes.button_s, sizes.buttonAds, sizes.buttonAds_s],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [
          sizes.largeLeaderBoard,
          sizes.superBanner,
          sizes.billboard,
          sizes.strip,
          sizes.superStrip,
          sizes.wideSuperbanner,
        ],
      },
    ],
    SUPERBANNER_SKIN: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.button, sizes.button_s, sizes.buttonAds, sizes.buttonAds_s],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [
          sizes.largeLeaderBoard,
          sizes.superBanner,
          sizes.billboard,
          sizes.strip,
          sizes.superStrip,
          sizes.wideSuperbanner,
          sizes.superbannerSkin,
        ],
      },
    ],
    HALFPAGE: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.sky, sizes.rectangle],
      },
    ],
    REC: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.rectangle],
      },
    ],
    BILLBOARD: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.button, sizes.button_s, sizes.buttonAds, sizes.buttonAds_s],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.billboard],
      },
    ],
    BUTTON: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.button],
      },
    ],
    SKIN: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.skin],
      },
    ],
    INREAD: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.inread],
      },
    ],
  },

  slots: [
    {
      id: 'dlab-ad-IS',
      path: '/2273514/adige_web_is',
      sizeMappingName: 'IS',
    },
    {
      id: 'H1',
      path: '/2273514/adige_hp_lb',
      sizeMappingName: 'LEADERBOARD',
    },
    {
      id: 'H2',
      path: '/2273514/adige_hp_sb_1',
      sizeMappingName: 'SUPERBANNER',
    },
    {
      id: 'H3',
      path: '/2273514/adige_hp_hp_1',
      sizeMappingName: 'HALFPAGE',
    },
    {
      id: 'H4',
      path: '/2273514/adige_hp_rec_1',
      sizeMappingName: 'REC',
    },
    {
      id: 'H5',
      path: '/2273514/adige_hp_bb_1',
      sizeMappingName: 'BILLBOARD',
    },
    {
      id: 'H6',
      path: '/2273514/adige_hp_sponsor',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'H7',
      path: '/2273514/adige_hp_but_1',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'H8',
      path: '/2273514/adige_hp_but_2',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'H9',
      path: '/2273514/adige_hp_rec_2',
      sizeMappingName: 'REC',
    },
    {
      id: 'H10',
      path: '/2273514/adige_hp_rec_3',
      sizeMappingName: 'REC',
    },
    {
      id: 'H11',
      path: '/2273514/adige_hp_rec_4',
      sizeMappingName: 'REC',
    },
    {
      id: 'H12',
      path: '/2273514/adige_hp_but_3',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'H13',
      path: '/2273514/adige_hp_but_4',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'H14',
      path: '/2273514/adige_hp_rec_5',
      sizeMappingName: 'REC',
    },
    {
      id: 'H15',
      path: '/2273514/adige_hp_hp_2',
      sizeMappingName: 'HALFPAGE',
    },
    {
      id: 'H16',
      path: '/2273514/adige_hp_bb_2',
      sizeMappingName: 'BILLBOARD',
    },
    {
      id: 'H18',
      path: '/2273514/adige_hp_skin',
      sizeMappingName: 'SKIN',
    },

    {
      id: 'S1',
      path: '/2273514/adige_default_lb',
      sizeMappingName: 'LEADERBOARD',
    },
    {
      id: 'S2',
      path: '/2273514/adige_default_sb_1',
      sizeMappingName: 'SUPERBANNER_SKIN',
    },
    {
      id: 'S3',
      path: '/2273514/adige_default_rec_1',
      sizeMappingName: 'REC',
    },
    {
      id: 'S4',
      path: '/2273514/adige_default_rec_2',
      sizeMappingName: 'REC',
    },
    {
      id: 'S5',
      path: '/2273514/adige_default_inread',
      sizeMappingName: 'INREAD',
    },
    {
      id: 'S6',
      path: '/2273514/adige_default_hp_1',
      sizeMappingName: 'HALFPAGE',
    },
    {
      id: 'S7',
      path: '/2273514/adige_default_rec_3',
      sizeMappingName: 'REC',
    },
    {
      id: 'S8',
      path: '/2273514/adige_default_rec_4',
      sizeMappingName: 'REC',
    },
    {
      id: 'S9',
      path: '/2273514/adige_default_but_1',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'S10',
      path: '/2273514/adige_default_but_2',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'S11',
      path: '/2273514/adige_default_but_3',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'S12',
      path: '/2273514/adige_default_but_4',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'S13',
      path: '/2273514/adige_default_rec_local_1',
      sizeMappingName: 'REC',
    },
    {
      id: 'S14',
      path: '/2273514/adige_default_rec_local_2',
      sizeMappingName: 'REC',
    },
    {
      id: 'S15',
      path: '/2273514/adige_default_rec_local_3',
      sizeMappingName: 'REC',
    },
    {
      id: 'S16',
      path: '/2273514/adige_default_rec_local_4',
      sizeMappingName: 'REC',
    },
    {
      id: 'S17',
      path: '/2273514/adige_default_rec_local_5',
      sizeMappingName: 'REC',
    },
    {
      id: 'S18',
      path: '/2273514/adige_default_bb_1',
      sizeMappingName: 'BILLBOARD',
    },
    {
      id: 'S19',
      path: '/2273514/adige_default_bb_2',
      sizeMappingName: 'BILLBOARD',
    },
    {
      id: 'S20',
      path: '/2273514/adige_default_skin',
      sizeMappingName: 'SKIN',
    },

    {
      id: 'HP-Button-Mobile',
      path: '/2273514/adige_hp_but_mobile',
      sizeMappingName: 'BUTTON',
    },
  ],

  general: {
    reloadInterval: false,
    collapseEmptyDivs: true,
    disableInitialLoad: true,
    enableSingleRequest: true,
    targetsKeyPrefix: 'ADIGE',
    amazontam: true,
    lazyLoad: {
      offset: 500,
    },
    prebid: {
      active: true,
    },
    interstitial: {
      containerId: 'dlab-interstitial',
      slotId: 'dlab-ad-IS',
      slotPath: '/2273514/adige_web_is',
      displayTimeInSec: 15,
    },
    preloadedFiles: [
      {
        link: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
        async: true,
      },
    ],
  },
}

export default ladigeConfig
