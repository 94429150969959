import React from 'react'
import styled from 'styled-components'
import NakedItemArticle, { Description0NakedItemArticle } from '../../views/NakedItemArticle'
import {
  Link0TitleItemArticle,
  Title0TitleItemArticle,
} from '../../components/DescriptionItemArticle/components/TitleItemArticle'
import {
  LinkDepartment0TopLine,
  LinkHeadLine0TopLine,
  StyledDelimiter0TopLine,
  Wrapper0TopLine,
} from '../../../../../../components/TopLine'
import { Text0DescriptionItemArticle, TopLine0DescriptionItemArticle } from '../../components/DescriptionItemArticle'
import Link from '../../../../../../components/Link'

const Wrapper = styled(NakedItemArticle)`
  position: relative;
  display: block;
  flex-direction: column;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  font-size: ${(props) => props.theme.fontSize.base};

  ${Description0NakedItemArticle} {
    position: absolute;
    display: flex;
    align-items: flex-start;
    align-self: center;
    bottom: 0;
    width: 100%;
    margin-top: 0;
    flex-direction: column;
    ${TopLine0DescriptionItemArticle} {
      margin-bottom: 0;
      font-size: 17px;
      line-height: 19px;
      padding: 0.25em 0.625rem;
    }
    ${Text0DescriptionItemArticle} {
      display: none;
    }
    ${LinkDepartment0TopLine} {
      display: none;
    }
    ${StyledDelimiter0TopLine} {
      display: none;
    }
  }

  ${Title0TitleItemArticle} {
    color: ${(props) => props.theme.color.textInverted};
    font-size: 18px;
    background-color: ${(props) => props.theme.color.textSecondary};
    padding: 0.25rem 0.6875rem 0.0625rem;
    max-height: 3.75rem;
    min-height: 1.75rem;
    margin-top: 0;
    display: flex;
    align-items: center;
    border-bottom: solid 0.167em;
    border-color: transparent;
    transition: all 0.5s ease;
    font-weight: 500;
    line-height: 20px;
  }

  ${Link0TitleItemArticle} {
    margin: 0;
    width: 100%;
  }

  ${Wrapper0TopLine} {
    font-weight: ${(props) => props.theme.fontWeight.regular};
    font-size: 1.0625rem;
    line-height: 1.5;
    background-color: rgba(183, 28, 28, 0.8);
    padding: 0.0625rem 0.625rem;
  }

  ${LinkHeadLine0TopLine} {
    color: ${(props) => props.theme.color.textInverted};
  }

  &:hover {
    ${Title0TitleItemArticle} {
      border-color: ${(props) => props.theme.color.stol};
    }
  }

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};

    ${Title0TitleItemArticle} {
      font-size: 1.125em;
      line-height: 1.3125rem;
      padding: 0.1875rem 0.6875rem 0.0625rem;
    }

    ${Wrapper0TopLine} {
      font-size: 0.9375rem;
      padding: 0.0625rem 0.625rem 0;
    }
  }
`

const LeaderItemArticle = ({ ...props }) => {
  const customLink = styled(Link).attrs({ to: !props.noTo && props.to ? props.to : undefined })``
  return <Wrapper descriptionParent={customLink} moreLink={false} {...props} />
}

LeaderItemArticle.propTypes = {
  ...NakedItemArticle.propTypes,
}

LeaderItemArticle.defaultProps = {
  truncateTitle: {
    numberOfLines: 3,
  },
}

export default LeaderItemArticle
