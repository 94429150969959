import settingsPiano from '../../config/settings/piano'
import settingsJobs from '../../config/settings/jobs'
import settingsCmp from '../../config/settings/cmp'
import settingsAds from '../../config/settings/ads'
import settingsBookmarks from '../../config/settings/bookmarks'
import settingsLayout from '../../config/settings/layout'
import settingsDefault from '../../config/settings/default'
import settingsVideos from '../../config/settings/videos'
import settingsApp from '../../config/settings/app'

const defaultSettings = settingsDefault()
defaultSettings.Allgemein.settings.unshift({
  label: 'API Anfragen Entwicklerumgebung',
  valueKey: 'dlabQueryDevTools',
  type: 'switch',
})
const settingsSTOL = () => ({
  ...defaultSettings,
  ...settingsAds(),
  ...settingsCmp(),
  ...settingsPiano(),
  ...settingsJobs(),
  ...settingsVideos(),
  ...settingsBookmarks(),
  ...settingsLayout(),
  ...settingsApp(),
})

export default settingsSTOL
