import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TitleTopic from '../../../../components/TitleTopic'
import hasItems from '../../../../modules/has-items'
import CategoryArticlesPanel from '../../../../components/CategoryArticlesPanel'
import ListArticle from './components/ListArticle'
import LoadMore from '../../../../components/LoadMore'
import moment from 'moment'
import differenceBy from 'lodash/differenceBy'

const Wrapper = styled.div`
  margin-bottom: ${(props) => props.theme.sizes.gapVerticalHalf};
`

const StyledCategoryArticlesPanel = styled(CategoryArticlesPanel)`
  margin-bottom: 15px !important;
`

const ListNewsFeed = ({ title, articles, ...props }) => {
  const allArticles = articles
  const [helperArticles, setHelperArticles] = useState(allArticles)
  const [lastDate, setLastDate] = useState(moment().subtract({ days: 1 }))

  const newArticles = differenceBy(allArticles, helperArticles, 'id')
  if (newArticles.length > 0) {
    const markedNewArticles = newArticles.map((article) => ({
      ...article,
      new: true,
    }))
    const concatArticles = markedNewArticles.concat(helperArticles)
    setHelperArticles(concatArticles)
  }

  return (
    <Wrapper {...props}>
      <TitleTopic view="screen" title={title} />
      {hasItems(helperArticles) && (
        <>
          <StyledCategoryArticlesPanel articles={helperArticles}>
            {(categoryArticles) => <ListArticle articles={categoryArticles} />}
          </StyledCategoryArticlesPanel>
          <LoadMore
            articles={helperArticles}
            afterLoadMore={(newArticles) => {
              setLastDate(lastDate.subtract({ days: 1 }))
              setHelperArticles(newArticles)
            }}
            customRoute={`newsticker/${lastDate.format('YYYY-MM-DD')}`}
          />
        </>
      )}
    </Wrapper>
  )
}

ListNewsFeed.propTypes = {
  title: PropTypes.string,
}

ListNewsFeed.defaultProps = {
  data: null,
}

export default ListNewsFeed
