import consoleLogger from '../../../../modules/console-logger'
import handlerWindowDlab from '../handler-window-dlab'

const destroyAdSettings = () => {
  const [consoleLog] = consoleLogger('Ads')
  const { dlabOption, clearDlabOption } = handlerWindowDlab('ads', { sub: 'units' })
  window.googletag = window.googletag || { cmd: [] }
  googletag.cmd.push(function () {
    window.googletag.pubads().clearTargeting()
    window.googletag.pubads().clearCategoryExclusions()
    window.googletag.destroySlots(window.googletag.pubads().getSlots())
    if (dlabOption) {
      clearDlabOption()
    }
    consoleLog('dlab ads deleted', window.dlab.ads)
    consoleLog('google ads DESTROYED', window.googletag.pubads().getSlots())
  })
}

export default destroyAdSettings
