import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Southtyrol from '../../images/MapSouthtyrol'
import { isLocationInSouthTyrol, southTyrolLat, southTyrolLong } from '../../modules/location'

const Wrapper = styled.div`
  position: relative;
`
const StyledSouthtyrol = styled(Southtyrol)`
  filter: drop-shadow(0 1.5px 3px rgba(0, 0, 0, 0.1));
`
const Dot = styled(({ position, ...props }) => <i {...props} />)`
  display: block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.color.stol};
  position: absolute;
  bottom: ${(props) => props.position.bottom}%;
  left: ${(props) => props.position.left}%;
  transform: translate(-4px, -4px);
`

const coordinatesToPosition = ({ latitude, longitude }, callBackIfPositionNotInSouthTyrol) => {
  if (isLocationInSouthTyrol(latitude, longitude)) {
    return {
      bottom: (latitude - southTyrolLat[1] - 0.02) / ((southTyrolLat[0] - southTyrolLat[1]) / 100),
      left: (longitude - southTyrolLong[0] - 0.02) / ((southTyrolLong[1] - southTyrolLong[0]) / 100),
    }
  } else {
    callBackIfPositionNotInSouthTyrol()
    return undefined
  }
}

const MapSouthtyrol = ({ dotPosition, callBackIfPositionNotInSouthTyrol, ...props }) => {
  const position = dotPosition ? coordinatesToPosition(dotPosition, callBackIfPositionNotInSouthTyrol) : false
  return (
    <Wrapper {...props}>
      <StyledSouthtyrol />
      {position && <Dot position={position} />}
    </Wrapper>
  )
}

MapSouthtyrol.propTypes = {
  dotPosition: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
}

MapSouthtyrol.defaultProps = {
  callBackIfPositionNotInSouthTyrol: () => {},
}

export default MapSouthtyrol
