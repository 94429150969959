import T from 'prop-types'
import isServerSide from '../../../../modules/is-server-side'
import footerLogo from './images/footerLogo.svg'
import {
  FooterBackground,
  FooterLinkContainer,
  FooterLogo,
  Form,
  FormTitle,
  Link,
  LinkContainer,
  LinkMobileContainer,
  StyledFooter,
  VerticalDivider,
  Wrapper,
} from './styled'
import ContactForm from './components/ContactForm'

const Footer = ({ data }) => {
  function openPm() {
    if (!isServerSide()) {
      window._sp_.loadPrivacyManagerModal(174573)
    }
  }
  return (
    <StyledFooter>
      <FooterBackground />
      <Wrapper>
        <Form>
          <ContactForm lng={data.lng} />
        </Form>
        <FooterLinkContainer>
          <FooterLogo src={footerLogo} alt={'logo'} />
          <LinkContainer>
            <Link href={'/p/impressum'}>Imprint </Link>
            <VerticalDivider />
            <Link href={'/p/agb'}>Terms and Conditions </Link>
            <VerticalDivider />
            <Link href={'https://www.iubenda.com/privacy-policy/88316793'}>Privacy policy </Link>
            <VerticalDivider />
            <Link href={'https://www.iubenda.com/privacy-policy/88316793/cookie-policy'}>Cookie policy </Link>
            <VerticalDivider />
            <Link onClick={() => openPm()}>Privacy settings </Link>
            <VerticalDivider />
            <Link href={'/p/faq'}>FAQ </Link>
            <VerticalDivider />
            <Link href={'/p/push-nachrichten'}>Push messages </Link>
          </LinkContainer>
          <LinkMobileContainer>
            <div>
              <Link href={'/p/agb'}>Terms and Conditions </Link>
              <Link href={'https://www.iubenda.com/privacy-policy/88316793'}>Privacy policy </Link>
              <Link onClick={() => openPm()}>Privacy settings </Link>
              <Link href={'/p/push-nachrichten'}>Push messages </Link>
            </div>
            <div>
              <Link href={'/p/impressum'}>Imprint </Link>
              <Link href={'https://www.iubenda.com/privacy-policy/88316793/cookie-policy'} target={'_blank'}>
                Cookie policy{' '}
              </Link>
              <Link href={'/p/faq'}>FAQ </Link>
            </div>
          </LinkMobileContainer>
          <Link href={'https://www.firstavenue.it/'} target={'_blank'} rel={'noopener, noreferrer'}>
            © 2023 <span>First Avenue GmbH</span>
          </Link>
        </FooterLinkContainer>
      </Wrapper>
    </StyledFooter>
  )
}

Footer.propTypes = {
  data: T.object,
}

export default Footer
