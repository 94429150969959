import React, { Component, Fragment } from 'react'
import styled, { css, withTheme } from 'styled-components'
import Dropzone from 'react-dropzone'
import Button from '../Button'
import ImageWidescreen from '../ImageWidescreen'
import { formatBytes } from '../../modules/parse'
import Input from '../Input'
import PropTypes from 'prop-types'
import truncateFileName from '../../modules/truncate-file-name'
import IconError from '../../topics/Error/components/IconError'
import translate from '../../modules/translate'
import CircularProgress from '../CircularProgress'
import Modal from '@mui/material/Modal'
import Slider from '@mui/material/Slider'
import { withStyles } from '@mui/styles'
import AvatarEditor from 'react-avatar-editor'
import Icon from '../Icon'
import Title from '../Title'
import Text from '../Text'

const Wrapper = styled.div`
  width: 100%;
`
const DropInfo = styled.div`
  font-size: ${(props) => props.theme.fontSize.title2};
  max-width: 97%;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.title2};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.title2};
  }
  font-weight: ${(props) => props.theme.fontWeight.bold};
`
const DropZoneWrapper = styled.div`
  display: flex;
  align-items: center;
  border-width: 0.19em;
  border-color: ${(props) => props.theme.color.textTertiary};
  border-style: dashed;
  font-family: ${(props) => props.theme.font.primary};
  flex-direction: column;
  padding: 2em 0;
  ${(props) =>
    props.isDragActive &&
    css`
      background-color: ${(props) => props.theme.color.background};
    `};
  ${(props) =>
    props.isDragAccept &&
    css`
      border-color: #92cc79;
    `}
  ${(props) =>
    (props.isDragReject || props.toManyFiles) &&
    css`
      border-color: ${(props) => props.theme.color.stol};
      ${DropInfo} {
        color: ${(props) => props.theme.color.stol};
      }
    `}
`
const ImagesPreview = styled.aside``
const ImagePreview = styled(ImageWidescreen)`
  min-width: 200px;
  flex: 1;
`

const ProfilePicture = styled.img`
  maxwidth: 160px;
  maxheight: 160px;
  flex: 1;
`

const InfoPreview = styled.div`
  flex: 2;
  display: flex;
  flex-wrap: wrap;
  font-family: ${(props) => props.theme.font.primary};
  margin: 1em;
  align-content: flex-start;
  ${(props) =>
    props.profilePicture &&
    css`
      width: 80%;
    `}
`
const InputPreview = styled(Input)``
const FileInfoPreview = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.theme.fontSize.small};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
  color: ${(props) => props.theme.color.textTertiary};
  min-width: 200px;
  margin-top: 1em;
  margin-bottom: 1em;
`
const ButtonDeletePreview = styled(Button)`
  min-width: 90%;
  ${(props) =>
    props.profilePicture &&
    css`
      min-width: 100%;
    `}
`
const WrapperPreview = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.color.background};
  margin-top: 0.5em;
  ${(props) =>
    props.profilePicture &&
    css`
      flx-wrap: nowrap;
      flex-direction: column;
      align-items: center;
    `}
`

const StyledCircularProgress = styled(CircularProgress)`
  margin: 0 auto;
  margin-top: 0.5em;
  margin-bottom: 1em;
`

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
`

const StyledCropWrapper = styled.div`
  background-color: #fff;
  margin: 100px auto;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35%;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  @media (max-width: 1200px) {
    width: 50%;
  }
  @media (max-width: 1000px) {
    width: 60%;
  }
  @media (max-width: 800px) {
    width: 70%;
  }
  @media (max-width: 700px) {
    width: 80%;
  }
  @media (max-width: 600px) {
    width: 90%;
  }
  @media (max-width: 450px) {
    width: 95%;
  }
`

const StyledCropOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 90px;
  width: 260px;
  padding-top: 10px;
`

const StyledCloseIcon = styled(Icon)`
  position: absolute;
  padding: 10px;
  top: 0;
  right: 0;
  font-size: 30px;
  cursor: pointer;
  color: ${(props) => props.theme.color.stol};
  font-weight: bold;
`

const StyledSlider = withTheme(
  withStyles({
    root: {
      color: 'red',
      backgroundColor: 'red',
    },
    container: {
      color: 'red',
      backgroundColor: 'red',
    },
    track: {
      color: 'red',
      backgroundColor: 'red',
    },
    thumb: {
      color: 'red',
      backgroundColor: 'red',
      '&:hover': {
        boxShadow: '0px 0px 0px 9px rgba(242, 38, 19, 0.3)',
      },
    },
    thumbIcon: {
      color: 'red',
      backgroundColor: 'red',
    },
    activated: {
      color: 'red',
      backgroundColor: 'red',
    },
    focused: {
      color: 'red',
      backgroundColor: 'red',
      boxShadow: '0px 0px 0px 9px rgba(242, 38, 19, 0.3)',
    },
    thumbWrapper: {
      color: 'red',
      backgroundColor: 'red',
    },
    thumbIconWrapper: {
      color: 'red',
      backgroundColor: 'red',
    },
  })(Slider),
)

const ZoomText = styled(Text)`
  text-align: center;
  width: 100%;
`

const StyledCropTitle = styled(Title)`
  padding-left: ${(props) => props.theme.sizes.gapColHalf};
  padding-right: ${(props) => props.theme.sizes.gapColHalf};
  text-align: center;
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.tablet.sizes.gapColHalf};
    padding-right: ${(props) => props.theme.tablet.sizes.gapColHalf};
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.mobile.sizes.gapColHalf};
    padding-right: ${(props) => props.theme.mobile.sizes.gapColHalf};
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`

class InputDropZone extends Component {
  constructor(props) {
    super(props)
    this.onClickSave = this.onClickSave.bind(this)
    this.closeEditor = this.closeEditor.bind(this)
    this.state = {
      files: [],
      maxFiles: props.maxFiles,
      fileToBig: false,
      toManyFiles: false,
      previewImage: undefined,
      previewImageisLoading: this.props.profilePicture ? true : false,
      showCropper: false,
      fileToCrop: undefined,
      zoomValue: 0,
      scale: 1,
      fileName: undefined,
      showDropzone: true,
    }
  }

  findFile = (fileName) => this.state.files.findIndex((fileOld) => fileOld.name === fileName)
  toManyFiles = (lengthFiles) => {
    const toManyFiles = lengthFiles >= this.state.maxFiles
    if (toManyFiles !== this.state.toManyFiles) {
      this.setState({
        toManyFiles: toManyFiles,
      })
    }
    return toManyFiles
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles[0]) {
      this.setState({ fileToBig: false })
      let allFiles =
        acceptedFiles &&
        acceptedFiles.reduce((result, fileNew) => {
          fileNew['preview'] = URL.createObjectURL(fileNew)
          const reader = new FileReader()
          reader.addEventListener(
            'load',
            function () {
              fileNew['base64'] = reader.result
            },
            false,
          )
          reader.readAsDataURL(fileNew)

          const lengthFiles = this.state.files.length + result.length
          const uniqueFileName = this.findFile(fileNew.name) === -1
          const toManyFiles = this.toManyFiles(lengthFiles)
          if (uniqueFileName && !toManyFiles) {
            result.push(fileNew)
          }
          return result
        }, [])

      if (this.state.files && allFiles) {
        allFiles = [...this.state.files, ...allFiles]
      }

      if (allFiles) {
        this.setState({
          files: allFiles,
        })

        this.onChange(allFiles)
      }
    }
    if (rejectedFiles && rejectedFiles[0]) {
      if (rejectedFiles && this.props.maxSize && rejectedFiles[0] && rejectedFiles[0].size > this.props.maxSize)
        this.setState({ fileToBig: true })
    }
  }

  onChange = (files) => {
    if (!files) files = this.state.files
    if (this.props.onDropChange) {
      this.props.onDropChange(files)
    }
  }

  onInputChange = (index, value) => {
    const subtitleFiles = this.state.files
    subtitleFiles[index]['subtitle'] = value
    this.onChange(subtitleFiles)
  }

  onDelete = (fileName) => {
    const files = [...this.state.files]

    files.splice(this.findFile(fileName), 1)
    this.toManyFiles(files.length)
    this.setState({
      files: files,
      showDropzone: true,
    })
  }
  onClickSave = () => {
    if (this.editor) {
      const canvasScaled = this.editor.getImageScaledToCanvas().toDataURL()
      let croppedFile = this.state.files
      croppedFile[0].base64 = canvasScaled
      this.setState({
        previewImage: canvasScaled,
        previewImageisLoading: false,
        showCropper: false,
        files: croppedFile,
        showDropzone: false,
      })
    }
  }
  setEditorRef = (editor) => {
    this.editor = editor
  }
  closeEditor() {
    this.onDelete(this.state.fileName)
    this.resetEditor()
  }

  resetEditor() {
    this.setState({
      files: [],
      maxFiles: this.props.maxFiles,
      toManyFiles: false,
      fileToBig: false,
      previewImage: undefined,
      previewImageisLoading: this.props.profilePicture ? true : false,
      showCropper: false,
      fileToCrop: undefined,
      zoomValue: 0,
      scale: 1,
      fileName: undefined,
      showDropzone: true,
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.reset && this.state.files.length > 0) {
      this.resetEditor()
    }
  }

  render() {
    const {
      reset,
      maxFiles,
      maxSize,
      form,
      showFileInfoPreview,
      showFileInputPreview,
      dropZoneInfo,
      profilePicture,
      requiredImageSubtitle,
      ...props
    } = this.props
    const { getFieldProps, getFieldError } = form
    const { files, showDropzone } = this.state
    let errors
    const thumbs = files.map((file, index) => {
      if (profilePicture && this.state.previewImageisLoading) {
        if (this.state.showCropper === false) {
          this.setState({
            showCropper: true,
            fileToCrop: file.preview,
            fileName: file.name,
          })
        }
      }
      return (
        <WrapperPreview key={file.name} profilePicture={profilePicture}>
          {this.state.previewImageisLoading === false && !profilePicture && (
            <ImagePreview image={{ url: file.preview }} />
          )}
          <InfoPreview profilePicture={profilePicture}>
            {showFileInputPreview && (
              <InputPreview
                append={
                  (errors = getFieldError('imagesSubtitles[' + index + ']')) && (
                    <IconError errorMessage={errors.join(',')} />
                  )
                }
                padding
                placeholder={translate('input_dropzone_image_subtitle')}
                {...getFieldProps('imagesSubtitles[' + index + ']', {
                  onChange: (e) => this.onInputChange(index, e.target.value),
                  rules: [
                    {
                      required: requiredImageSubtitle,
                      message: translate('input_dropzone_image_subtitle_error'),
                    },
                  ],
                })}
              />
            )}
            {showFileInfoPreview && (
              <FileInfoPreview title={file.name}>
                {truncateFileName(file.name, 15)}
                <br />
                {formatBytes(file.size)}
              </FileInfoPreview>
            )}
            <LoadingWrapper>
              {this.state.previewImageisLoading && <StyledCircularProgress />}
              <ButtonDeletePreview
                view="grey"
                profilePicture={profilePicture}
                onClick={() => {
                  if (profilePicture) {
                    this.setState({
                      previewImageisLoading: true,
                    })
                  } else {
                    this.setState({
                      previewImageisLoading: false,
                    })
                  }
                  this.onDelete(file.name)
                }}
              >
                {translate('input_dropzone_delete')}
              </ButtonDeletePreview>
            </LoadingWrapper>
          </InfoPreview>
        </WrapperPreview>
      )
    })
    //TODO move this into a seperate Component
    return (
      <Wrapper {...props}>
        <Dropzone
          multiple={true}
          maxSize={maxSize}
          accept="image/png, image/jpg, image/jpeg, image/jpe"
          onDrop={this.onDrop}
          {...props}
        >
          {({ getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive }) => {
            const dropInfo = () => {
              if (this.state.toManyFiles) return translate('input_dropzone_maximum')
              if (this.state.fileToBig) return translate('input_dropzone_bigfile', { filesize: formatBytes(maxSize) })
              if (isDragReject) return translate('input_dropzone_reject')
              if (dropZoneInfo) return dropZoneInfo
              return translate('input_dropzone_info')
            }
            return (
              <DropZoneWrapper
                isDragAccept={isDragAccept}
                isDragReject={isDragReject || this.state.fileToBig || this.state.toManyFiles}
                toManyFiles={this.state.toManyFiles}
                isDragActive={isDragActive}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {this.state.previewImageisLoading === false && profilePicture && (
                  <ProfilePicture src={this.state.previewImage} />
                )}
                {showDropzone && (
                  <Fragment>
                    <DropInfo>{dropInfo()}</DropInfo>
                    <p>{translate('input_dropzone_info2')}</p>
                    <Button onClick={this.onDrop}>
                      {maxFiles > 1
                        ? translate('input_dropzone_button')
                        : translate('input_dropzone_button_single_image')}
                    </Button>
                  </Fragment>
                )}
              </DropZoneWrapper>
            )
          }}
        </Dropzone>
        <ImagesPreview>{thumbs}</ImagesPreview>
        <Modal
          disableAutoFocus
          open={this.state.showCropper}
          onEscapeKeyDown={this.closeEditor}
          onBackdropClick={this.closeEditor}
        >
          <StyledCropWrapper>
            <StyledCropTitle>{translate('please_select_image_crop')}</StyledCropTitle>
            <AvatarEditor
              ref={this.setEditorRef}
              image={this.state.fileToCrop}
              width={160}
              height={160}
              border={50}
              color={[255, 255, 255, 0.6]} // RGBA
              scale={this.state.scale}
              rotate={0}
            />
            <StyledCropOptionsWrapper>
              <StyledCloseIcon icon="close" onClick={this.closeEditor} />
              <StyledSlider
                onChange={(event, value) => {
                  this.setState({ zoomValue: value, scale: value / 100 + 1 })
                }}
                value={this.state.zoomValue}
              />
              {
                <ZoomText>
                  {translate('zoom_step')}: {((this.state.scale - 1) * 100).toFixed(0)}%
                </ZoomText>
              }
              <Button onClick={this.onClickSave}>{translate('save')}</Button>
            </StyledCropOptionsWrapper>
          </StyledCropWrapper>
        </Modal>
      </Wrapper>
    )
  }
}

InputDropZone.propTypes = {
  reset: PropTypes.bool,
  maxFiles: PropTypes.number,
  form: PropTypes.object,
}

InputDropZone.defaultProps = {
  reset: false,
  maxFiles: 5,
  showFileInfoPreview: true,
  showFileInputPreview: true,
  requiredImageSubtitle: true,
  form: {
    getFieldProps: {},
  },
}

export default InputDropZone
