import React, { Fragment } from 'react'
import styled, { withTheme } from 'styled-components'
import ErrorBoundary from '../../../../topics/Error/widgets/ErrorBoundary'
import translate from '../../../../modules/translate'
import hotels from './hotel_data'
import pushEventToDataLayer from '../../../../modules/push-event-to-data-layer'
import ReactGA from 'react-ga'
import BlockThreeHotelsPretty from '../BlockThreeHotelsPretty'

const MobileBlockThreeHotelsPretty = styled(BlockThreeHotelsPretty)`
  display: none;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    display: flex;
  }
`

const Wrapper = styled.div`
  display: flex;
  height: 380px;
  margin-top: -20px;
  margin-bottom: 15px;
  font-family: ${(props) => props.theme.font.primary};

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    display: none;
    height: auto;
    margin-top: -30px;
    margin-bottom: 0;
    flex-direction: column;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: -20px;
  }
`

const StyledFront = styled.div`
  background-color: white;
  color: ${(props) => props.theme.color.textPrimary};
  height: 100%;
  padding: 20px;
  flex-grow: 1;
  flex: 1;
  box-sizing: border-box;
  border-right: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    height: auto;
    flex-direction: row;
    padding: 10px;
    border-right: none;
    border-bottom: 1px solid #e5e5e5;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-left: 0;
  }
`

const StyledHotelImage = styled.img`
  width: 100%;
  height: auto;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: 30%;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 40%;
  }
`

const StyledHotelInfo = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.theme.fontSize.text};

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
    padding-left: 20px;
    height: auto;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
    padding-left: 10px;
  }
`

const StyledHotelText = styled.div`
  margin-top: 10px;

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: 0;
  }
`

const StyledHotelHeader = styled.div`
  font-weight: 700;
  margin-bottom: 3px;
  font-size: ${(props) => props.theme.fontSize.title3};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.title3};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.title3};
  }
`

const StyledHotelSubheader = styled.div``

const StyledHotelLinks = styled.div`
  display: flex;
  flex-grow: 20;
  align-items: flex-end;
  height: 30px;
`

const StyledHotelName = styled.div`
  color: #f00074;
  width: 100%;
  text-align: end;
  padding-right: 10px;
  font-size: ${(props) => props.theme.fontSize.base};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobileS.breakPoint + 'px'}) {
    padding-right: 0;
  }
`

const StyledBack = styled.div`
  height: 100%;
  display: none;
  flex-direction: column;
`

const StyledBackHead = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #f00074;
  border: 2px solid #f00074;
  padding: 12px;
`

const StyledBackTitle = styled.div`
  font-weight: 700;
  padding-bottom: 2px;
  font-size: ${(props) => props.theme.fontSize.title3};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.title3};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.title3};
  }
`

const StyledPrice = styled.div`
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
`

const StyledBackBody = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  color: white;
`

const StyledAttributes = styled.table`
  border-collapse: separate;
  border-spacing: 0 12px;
  padding: 5px 20px;
`

const StyledAttribute = styled.tr``

const StyledAttributeImageWrapper = styled.td`
  text-align: center;
`

const StyledAttributeImage = styled.img`
  max-width: 45px;
  max-height: 42px;
  height: auto;
  width: auto;
`

const StyledAttributeText = styled.td`
  padding-left: 12px;
  line-height: 1.4;
  font-size: ${(props) => props.theme.fontSize.base};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};
  }
`

const StyledBookButton = styled.div`
  padding: 5px 10px;
  margin: auto 0 20px;
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
  color: #f00074;
  background-color: white;
  align-self: center;
  border-width: 0px;
  border-radius: 10px;
`

const StyledHotelItem = styled.a`
  text-decoration: none;
  width: calc(100% / 3);
  height: 100%;

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: 100%;
    height: auto;
  }

  &:hover {
    background-color: #f00074;

    ${StyledBack} {
      display: flex;
    }
    ${StyledFront} {
      display: none;
    }
  }
`

// enable the hotel widget by replacing 'special_box' with 'special_box_hotels_commercial' or 'special_box_hotels_pretty' in home.json
const BlockThreeHotelsCommercial = ({ data, ...props }) => {
  console.log(props, data)
  const errorOptions = {
    component: undefined,
    link: '/',
    linkText: translate('error_try_homepage'),
    logError: 'BlockThreeHotelsCommercial index',
  }

  return (
    <ErrorBoundary {...errorOptions}>
      <Wrapper {...props}>
        <Fragment>
          {hotels.map((hotel) => (
            <StyledHotelItem
              href={hotel.link}
              target="_blank"
              onClick={() => {
                pushEventToDataLayer('Hotel 2', ['HotelName', hotel.name])
                ReactGA.event({ category: 'Hotel 2', action: hotel.name })
              }}
            >
              <StyledFront>
                <StyledHotelImage src={hotel.imgUrl} />
                <StyledHotelInfo>
                  <StyledHotelText>
                    <StyledHotelHeader>{hotel.header}</StyledHotelHeader>
                    <StyledHotelSubheader>{hotel.subHeader}</StyledHotelSubheader>
                    {hotel.duration && <StyledHotelSubheader>{hotel.duration}</StyledHotelSubheader>}
                  </StyledHotelText>
                  <StyledHotelLinks>
                    <StyledHotelName>{hotel.name}</StyledHotelName>
                  </StyledHotelLinks>
                </StyledHotelInfo>
              </StyledFront>
              <StyledBack>
                <StyledBackHead>
                  <StyledBackTitle>{hotel.backTitle}</StyledBackTitle>
                  <StyledPrice>
                    {hotel.moreExpensiveRooms && 'Ab '} <strong>{hotel.price} €</strong>{' '}
                    {!hotel.numberGuests ? 'pro Person' : `für ${hotel.numberGuests} Personen`}
                  </StyledPrice>
                </StyledBackHead>
                <StyledBackBody>
                  <StyledAttributes>
                    {hotel.attributes.map((attribute) => (
                      <StyledAttribute>
                        <StyledAttributeImageWrapper>
                          <StyledAttributeImage src={attribute.image}></StyledAttributeImage>
                        </StyledAttributeImageWrapper>
                        <StyledAttributeText>{attribute.text}</StyledAttributeText>
                      </StyledAttribute>
                    ))}
                  </StyledAttributes>
                  <StyledBookButton>Jetzt buchen</StyledBookButton>
                </StyledBackBody>
              </StyledBack>
            </StyledHotelItem>
          ))}
        </Fragment>
      </Wrapper>
      <MobileBlockThreeHotelsPretty commercial={true}></MobileBlockThreeHotelsPretty>
    </ErrorBoundary>
  )
}

export default withTheme(BlockThreeHotelsCommercial)
