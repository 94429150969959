import styled from 'styled-components'

export const ParagraphWrapper = styled.section`
  margin-bottom: 44px;
  @media (max-width: 600px) {
    margin-bottom: 40px;
  }
`

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 16px;
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column-reverse;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    max-width: 344px;
    margin-bottom: 100px;
    @media (max-width: 600px) {
      max-width: 238px;
      margin-bottom: 40px;
    }
  }
`

export const Text = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  letter-spacing: -0.02em;
  color: #23242a;
  margin-bottom: 24px;
  margin-top: 0px;
  :last-of-type {
    font-size: 14px;
  }
`
