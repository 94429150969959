import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import Title from '../../../../components/Title'
import AccordionDetails from '@mui/material/AccordionDetails'
import SaveLoadComponent from '../SaveLoadComponent'
import React from 'react'
import styled from 'styled-components'
import Text from '../../../../components/Text'
import Icon from '../../../../components/Icon'

const StyledText = styled(Text)`
  width: 100%;
`

const OptionsAccordion = ({ options, ...props }) =>
  Object.keys(options).map((category) => (
    <Accordion square defaultExpanded={options[category].expanded} {...props}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<Icon icon="arrowUp" />}>
        <Title tag="h2">{category}</Title>
      </AccordionSummary>
      <AccordionDetails>
        <StyledText>
          {options[category].settings.map((setting) => (
            <SaveLoadComponent settings={setting} />
          ))}
        </StyledText>
      </AccordionDetails>
    </Accordion>
  ))

export default OptionsAccordion
