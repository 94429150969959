//truncates the filename of uploaded images

const truncateFileName = (fileName, maxLength) => {
  const ext = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length).toLowerCase()
  let fileNameNew = fileName.replace('.' + ext, '')
  if (fileNameNew.length <= maxLength) {
    return fileName
  }
  fileNameNew = fileNameNew.substr(0, maxLength) + (fileName.length > maxLength ? '[...]' : '')
  return fileNameNew + '.' + ext
}

export default truncateFileName
