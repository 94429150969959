import Router from '../Router'
import React from 'react'
import ScreenLoader from '../ScreenLoader'
import errorLog from '../../modules/error-log'
import ScreenLayout from '../ScreenLayout'
import consoleLogger from '../../modules/console-logger'
import RouteScreen from '../../class/RouteScreen'
import RoutesConfig from '../../class/RoutesConfig'

const DlabLoader = ({ route, routes, layouts, modules, components, blocks, ...props }) => {
  const [consoleLog] = consoleLogger('DlabLoader')
  consoleLog('route: ', route)
  consoleLog('routes: ', routes)
  consoleLog('modules: ', modules)
  consoleLog('components: ', components)
  consoleLog('layouts: ', layouts)
  if (!modules) {
    errorLog({
      info: 'DlabLoader',
      error: 'modules missing',
    })
    consoleLog('error: ', 'modules missing')
    return false
  }
  if (!components) {
    errorLog({
      info: 'DlabLoader',
      error: 'components missing',
    })
    consoleLog('error: ', 'components missing')
    return false
  }
  if (routes instanceof RoutesConfig && !route) {
    return (
      <Router
        customRoutes={routes}
        layouts={layouts}
        blocks={blocks}
        modules={modules}
        components={components}
        {...props}
      />
    )
  }

  if (!(route instanceof RoutesConfig)) {
    errorLog({
      info: 'DlabLoader',
      error: 'Route missing or not using RoutesConfig class',
    })
    consoleLog('error: ', 'Route missing or not using RoutesConfig class')
    return false
  }

  const routeOptions = route.getOptions()
  const screenConfig = routeOptions.screen
  const topicConfig = routeOptions.topic

  if (topicConfig && typeof topicConfig !== 'string') {
    errorLog({
      info: 'DlabLoader - Topic',
      error: 'RouteScreen topic config is not a string',
    })
    consoleLog('error topic config is not a string: ', topicConfig)
    return false
  }

  if (topicConfig && typeof topicConfig === 'string') {
    const Topic = components[topicConfig]
    return <Topic {...props} />
  }

  if (screenConfig instanceof RouteScreen && typeof screenConfig !== 'string' && layouts) {
    const screenLayoutConfig = screenConfig && screenConfig.layout
    if (!(screenConfig instanceof RouteScreen) || (screenLayoutConfig && typeof screenLayoutConfig !== 'string')) {
      errorLog({
        info: 'DlabLoader - ScreenLayout',
        error: 'RouteScreen config or RouteScreen layout config missing',
      })
      consoleLog('error ScreenLayout: ', 'RouteScreen config or RouteScreen layout config missing')
      consoleLog('error ScreenLayout: RouteScreen class used: ', screenConfig instanceof RouteScreen)
      return false
    }
    const screenLayout = layouts[screenLayoutConfig]
    return (
      <ScreenLayout
        route={route}
        layout={screenLayout}
        blocks={blocks}
        modules={modules}
        components={components}
        {...props}
      />
    )
  }

  if (typeof routeOptions.screen !== 'string') {
    errorLog({
      info: 'DlabLoader - ScreenLoader',
      error: 'RouteScreen config missing',
    })
    consoleLog('error ScreenLoader: ', 'RouteScreen config missing')
    return false
  }
  const Screen = components[routeOptions.screen]
  return (
    <ScreenLoader Screen={Screen} route={route} modules={modules} components={components} blocks={blocks} {...props} />
  )
}

export default DlabLoader
