import React from 'react'
import PropTypes from 'prop-types'
import LayoutDefault from '../../layouts/Default/index'
import { Async } from 'react-async'
import fetchData from '../../modules/fetch-data'
import options from '../../config/options'
import loadable from '@loadable/component'

// ATTENTION: OLD STUFF!

const SearchScreen = loadable(() =>
  import(/* webpackChunkName: "SearchScreen" , webpackPrefetch: true */ './screens/SearchScreen'),
)

const apiSortBy = {
  relevanz: 'relevance',
  datum: 'date',
}

const Search = ({ initialData, match, location, layout, ...props }) => {
  const requestSortBy =
    match && match.params && match.params.sortBy ? match.params.sortBy : options.Search.defaultSortBy
  const sortBy = apiSortBy[requestSortBy]
  const searchTerm = match && match.params && match.params.searchTerm
  const searchLocation = `search/${sortBy}/${searchTerm}`
  return (
    <Async promiseFn={fetchData} location={searchLocation} version="v2">
      {({ data, error, isPending, isFulfilled }) => (
        <LayoutDefault meta={isFulfilled && data.meta} disable={!layout} {...props}>
          <SearchScreen
            response={isFulfilled && data}
            loading={isPending}
            error={error}
            ready={isFulfilled}
            match={match}
            searchTerm={searchTerm}
            sortBy={sortBy}
          />
        </LayoutDefault>
      )}
    </Async>
  )
}

Search.propTypes = {
  layout: PropTypes.bool,
}

Search.defaultProps = {
  layout: true,
}

export default Search
