import md5 from 'crypto-js/md5'
import sha256 from 'crypto-js/sha256'
import consoleLogger from '../../../../modules/console-logger'

const [consoleLog] = consoleLogger('AdsLog')

const hashedEmails = ({ config }) => {
  if (config) {
    if (config.windowFunction) {
      const email = checkForEmail(config.windowFunction)
      if (email) {
        const md5Email = hashMail({ text: email, algorithm: 'md5' })
        const sha256Email = hashMail({ text: email, algorithm: 'sha256' })

        const hashedConfig = {
          bidders: ['criteo'],
          config: {
            ortb2: {
              user: {
                ext: {
                  data: {
                    eids: [
                      {
                        source: 'https://www.stol.it',
                        uids: [
                          {
                            id: md5Email.toString(),
                            atype: 3,
                            ext: {
                              stype: 'hemmd5',
                            },
                          },
                          {
                            id: sha256Email.toString(),
                            atype: 3,
                            ext: {
                              stype: 'hemsha256',
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
              },
            },
          },
        }
        consoleLog('PREBID Hashed Emails', hashedConfig, 'Ads')

        window.pbjs.setBidderConfig(hashedConfig)
      }
    } else {
      consoleLog('PREBID Hashed Emails', 'Window Function missing in Config', 'Ads')
    }
  }
}

const checkForEmail = ({ emailFunctionName }) => {
  if (window[emailFunctionName] === undefined) {
    consoleLog('CheckForEmail', 'no function found', 'Ads')
    return null
  }
  const email = window[emailFunctionName]()
  if (email) {
    consoleLog('CheckForEmail', 'email found - ' + email, 'Ads')
    return email
  } else {
    consoleLog('CheckForEmail', 'no email found', 'Ads')
    return null
  }
}

const hashMail = ({ email, algorithm = 'md5' }) => {
  let hashedMail = email
  switch (algorithm) {
    case 'md5': {
      hashedMail = md5(email)
      break
    }
    case 'sha256': {
      hashedMail = sha256(email)
      break
    }
    default: {
      consoleLog('hashMail', 'unknown algorithm - ' + algorithm, 'Ads')
      break
    }
  }
  return hashedMail
}

const createSessionId = ({ email }) => {
  console.log('SLC - createSessionId called')
  if (email != null) {
    localStorage.setItem('sessionId', hashMail({ email }))
  } else {
    if (localStorage.getItem('sessionId') == null || !localStorage.getItem('sessionId').startsWith('SID-')) {
      const sessionId = 'SID-' + Math.floor(Math.random() * Date.now()).toString(36)
      localStorage.setItem('sessionId', sessionId)
    }
  }
}

export { hashedEmails, hashMail, createSessionId }
