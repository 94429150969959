const viewports = {
  desktop: [840, 0],
  all: [0, 0],
}

const sizes = {
  button: [300, 100],
  buttonAds: [320, 100],
  buttonAds_s: [320, 50],
  button_s: [300, 50],
  skin: [1400, 600],
  manchette: [150, 70],
  manchette1: [160, 90],
  strip: [990, 30],
  superStrip: [990, 60],
  superBanner: [990, 120],
  sky: [300, 600],
  rectangle: [300, 250],
  billboard: [970, 250],
  leaderboard: [728, 90],
  smallBillboard: [640, 100],
  oneByOne: [1, 1],
  smallInterstitial: [320, 480],
  // smallInterstitialInv: [480, 320],
  // mediumInterstitial: [740, 360],
  // mediumInterstitial2: [420, 740],
  mediumInterstitial3: [600, 600],
  bigInterstitial: [1000, 620],
  bigInterstitial2: [1000, 575],
}

const trentinoConfig = {
  sizeMappings: {
    IS: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.oneByOne, sizes.smallInterstitial],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.oneByOne, sizes.mediumInterstitial3, sizes.bigInterstitial, sizes.bigInterstitial2],
      },
    ],
    ONE_BY_ONE: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.oneByOne],
      },
    ],
    SKIN: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.button],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.skin, sizes.oneByOne],
      },
    ],
    MANCHETTE: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.buttonAds_s],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.manchette, sizes.manchette1],
      },
    ],
    MANCHETTE_DESKTOP: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.manchette, sizes.manchette1],
      },
    ],
    SINGLE_SUPERBANNER: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.buttonAds_s],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.superBanner],
      },
    ],
    SUPERBANNER: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.buttonAds_s],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.strip, sizes.superStrip, sizes.superBanner],
      },
    ],
    BANNER: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.rectangle, sizes.sky],
      },
    ],
    REC: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.rectangle],
      },
    ],
    REC_WIDE: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.rectangle],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.billboard],
      },
    ],
    SMALL_BILLBOARD: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.buttonAds_s],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.smallBillboard],
      },
    ],
    SMALL_BILLBOARD_ALT: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.button],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.smallBillboard],
      },
    ],
    BILLBOARD: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.button],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.billboard],
      },
    ],
    BILLBOARD_BUTTON_ADS: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.button, sizes.buttonAds, sizes.buttonAds_s],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.billboard, sizes.leaderboard],
      },
    ],

    BUTTON: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.button],
      },
    ],
    BUTTONADS: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.buttonAds],
      },
    ],
    REC_DESKTOP: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.rectangle],
      },
    ],
    MIDDLE_PAGE_REC: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.rectangle, sizes.sky],
      },
    ],
    LEADERBOARD_DESKTOP: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.leaderboard],
      },
    ],
    BUTTONS_MOBILE: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.buttonAds, sizes.buttonAds_s, sizes.button_s, sizes.button],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [],
      },
    ],
  },

  slots: [
    {
      id: 'dlab-ad-IS',
      path: '/2273514/trentino/tn_web_is',
      sizeMappingName: 'IS',
    },
    {
      id: 'dlab-ad-W',
      path: '/2273514/trentino/tn_hp_skin',
      sizeMappingName: 'SKIN',
    },
    // Homepage
    {
      id: 'dlab-ad-H1-left',
      path: '/2273514/trentino/tn_hp_manchette_left',
      sizeMappingName: 'MANCHETTE_DESKTOP',
    },
    {
      id: 'dlab-ad-H1-right',
      path: '/2273514/trentino/tn_hp_manchette_right',
      sizeMappingName: 'MANCHETTE',
    },
    {
      id: 'dlab-ad-H2',
      path: '/2273514/trentino/tn_hp_sb_1',
      sizeMappingName: 'SUPERBANNER',
    },
    {
      id: 'dlab-ad-H3',
      path: '/2273514/trentino/tn_hp_sky',
      sizeMappingName: 'BANNER',
    },
    {
      id: 'dlab-ad-H4',
      path: '/2273514/trentino/tn_hp_rec_1',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-H5',
      path: '/2273514/trentino/tn_hp_bb_1',
      sizeMappingName: 'BILLBOARD',
    },
    {
      id: 'dlab-ad-H6',
      path: '/2273514/trentino/tn_hp_rec_2',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-H7',
      path: '/2273514/trentino/tn_hp_but_1',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab-ad-H8',
      path: '/2273514/trentino/tn_hp_but_2',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab-ad-H9',
      path: '/2273514/trentino/tn_hp_but_3',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab-ad-H10',
      path: '/2273514/trentino/tn_hp_rec_3',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-H11',
      path: '/2273514/trentino/tn_hp_rec_4',
      sizeMappingName: 'REC_WIDE',
    },
    {
      id: 'dlab-ad-H14',
      path: '/2273514/trentino/tn_hp_but_4',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab-ad-H15',
      path: '/2273514/trentino/tn_hp_but_5',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab-ad-H16',
      path: '/2273514/trentino/tn_hp_but_6',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab-ad-H17',
      path: '/2273514/trentino/tn_hp_sb_2',
      sizeMappingName: 'SINGLE_SUPERBANNER',
    },
    {
      id: 'dlab-ad-H18',
      path: '/2273514/trentino/tn_hp_rec_7',
      sizeMappingName: 'MIDDLE_PAGE_REC',
    },
    {
      id: 'dlab-ad-H19',
      path: '/2273514/trentino/tn_hp_bb_2',
      sizeMappingName: 'BILLBOARD_BUTTON_ADS',
    },
    {
      id: 'dlab-ad-H20',
      path: '/2273514/trentino/tn_hp_rec_8',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-H21',
      path: '/2273514/trentino/tn_hp_rec_9',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-H22',
      path: '/2273514/trentino/tn_hp_sponsor',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab-ad-H23',
      path: '/2273514/trentino/tn_hp_ll_1',
      sizeMappingName: 'LEADERBOARD_DESKTOP',
    },
    {
      id: 'dlab-ad-H24',
      path: '/2273514/trentino/tn_hp_sticky',
      sizeMappingName: 'BUTTONS_MOBILE',
    },
    // {
    //   id: 'dlab-ad-H25',
    //   path: '/2273514/trentino/tn_hp_topinside',
    //   sizeMappingName: 'REC',
    // },
    {
      id: 'dlab-ad-H26',
      path: '/2273514/trentino/tn_hp_rec_10',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-H27',
      path: '/2273514/trentino/tn_hp_rec_11',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-H28',
      path: '/2273514/trentino/tn_hp_rec_12',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-H29',
      path: '/2273514/trentino/tn_hp_rec_13',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-H30',
      path: '/2273514/trentino/tn_hp_rec_14',
      sizeMappingName: 'REC',
    },

    // Pages
    {
      id: 'dlab-ad-S1-left',
      path: '/2273514/trentino/tn_default_manchette_left',
      sizeMappingName: 'MANCHETTE_DESKTOP',
    },
    {
      id: 'dlab-ad-S1-right',
      path: '/2273514/trentino/tn_default_manchette_right',
      sizeMappingName: 'MANCHETTE',
    },
    {
      id: 'dlab-ad-S2',
      path: '/2273514/trentino/tn_default_sb_1',
      sizeMappingName: 'SUPERBANNER',
    },
    {
      id: 'dlab-ad-S3',
      path: '/2273514/trentino/tn_default_sky',
      sizeMappingName: 'BANNER',
    },
    {
      id: 'dlab-ad-S4',
      path: '/2273514/trentino/tn_default_rec_1',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-S5',
      path: '/2273514/trentino/tn_default_bb_1',
      sizeMappingName: 'BILLBOARD',
    },
    {
      id: 'dlab-ad-S6',
      path: '/2273514/trentino/tn_default_rec_2',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-S7',
      path: '/2273514/trentino/tn_default_but_1',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab-ad-S8',
      path: '/2273514/trentino/tn_default_but_2',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab-ad-S9',
      path: '/2273514/trentino/tn_default_but_3',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab-ad-S10',
      path: '/2273514/trentino/tn_default_rec_3',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-S11',
      path: '/2273514/trentino/tn_default_rec_4',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-S12',
      path: '/2273514/trentino/tn_default_rec_5',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-S13',
      path: '/2273514/trentino/tn_default_rec_6',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-S14',
      path: '/2273514/trentino/tn_default_but_4',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab-ad-S15',
      path: '/2273514/trentino/tn_default_but_5',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab-ad-S16',
      path: '/2273514/trentino/tn_default_but_6',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab-ad-S17',
      path: '/2273514/trentino/tn_default_sb_2',
      sizeMappingName: 'SINGLE_SUPERBANNER',
    },
    {
      id: 'dlab-ad-S18',
      path: '/2273514/trentino/tn_default_rec_7',
      sizeMappingName: 'REC_DESKTOP',
    },
    {
      id: 'dlab-ad-S19',
      path: '/2273514/trentino/tn_default_bb_2',
      sizeMappingName: 'BILLBOARD_BUTTON_ADS',
    },
    {
      id: 'dlab-ad-S20',
      path: '/2273514/trentino/tn_default_rec_8',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-S21',
      path: '/2273514/trentino/tn_default_rec_9',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-S22',
      path: '/2273514/trentino/tn_default_bb_3',
      sizeMappingName: 'SMALL_BILLBOARD_ALT',
    },
    {
      id: 'dlab-ad-S23',
      path: '/2273514/trentino/tn_default_ll_1',
      sizeMappingName: 'LEADERBOARD_DESKTOP',
    },
    {
      id: 'dlab-ad-S24',
      path: '/2273514/trentino/tn_default_sponsor',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab-ad-S25',
      path: '/2273514/trentino/tn_default_sticky',
      sizeMappingName: 'BUTTONS_MOBILE',
    },
    {
      id: 'dlab-ad-S26',
      path: '/2273514/trentino/tn_default_bb_4',
      sizeMappingName: 'SMALL_BILLBOARD',
    },
    {
      id: 'dlab-ad-S27',
      path: '/2273514/trentino/tn_default_inread',
      sizeMappingName: 'ONE_BY_ONE',
    },

    // Advertorial
    {
      id: 'dlab-ad-WA',
      path: '/2273514/trentino/aa_adv_skin',
      sizeMappingName: 'SKIN',
    },
    {
      id: 'dlab-ad-A1-left',
      path: '/2273514/trentino/tn_adv_manchette_left',
      sizeMappingName: 'MANCHETTE_DESKTOP',
    },
    {
      id: 'dlab-ad-A1-right',
      path: '/2273514/trentino/tn_adv_manchette_right',
      sizeMappingName: 'MANCHETTE',
    },
    {
      id: 'dlab-ad-A2',
      path: '/2273514/trentino/tn_adv_sb_1',
      sizeMappingName: 'SUPERBANNER',
    },
    {
      id: 'dlab-ad-A3',
      path: '/2273514/trentino/tn_adv_sky',
      sizeMappingName: 'BANNER',
    },
    {
      id: 'dlab-ad-A4',
      path: '/2273514/trentino/tn_adv_rec_1',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-A5',
      path: '/2273514/trentino/tn_adv_bb_1',
      sizeMappingName: 'BILLBOARD',
    },
    {
      id: 'dlab-ad-A6',
      path: '/2273514/trentino/tn_adv_rec_2',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-A7',
      path: '/2273514/trentino/tn_adv_but_1',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab-ad-A8',
      path: '/2273514/trentino/tn_adv_but_2',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab-ad-A9',
      path: '/2273514/trentino/tn_adv_but_3',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab-ad-A10',
      path: '/2273514/trentino/tn_adv_rec_3',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-A11',
      path: '/2273514/trentino/tn_adv_rec_4',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-A12',
      path: '/2273514/trentino/tn_adv_rec_5',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-A13',
      path: '/2273514/trentino/tn_adv_rec_6',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-A14',
      path: '/2273514/trentino/tn_adv_but_4',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab-ad-A15',
      path: '/2273514/trentino/tn_adv_but_5',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab-ad-A16',
      path: '/2273514/trentino/tn_adv_but_6',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab-ad-A17',
      path: '/2273514/trentino/tn_adv_sb_2',
      sizeMappingName: 'SINGLE_SUPERBANNER',
    },
    {
      id: 'dlab-ad-A18',
      path: '/2273514/trentino/tn_adv_rec_7',
      sizeMappingName: 'REC_DESKTOP',
    },
    {
      id: 'dlab-ad-A19',
      path: '/2273514/trentino/tn_adv_bb_2',
      sizeMappingName: 'BILLBOARD_BUTTON_ADS',
    },
    {
      id: 'dlab-ad-A20',
      path: '/2273514/trentino/tn_adv_rec_8',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-A21',
      path: '/2273514/trentino/tn_adv_rec_9',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab-ad-A22',
      path: '/2273514/trentino/tn_adv_bb_3',
      sizeMappingName: 'SMALL_BILLBOARD_ALT',
    },
    {
      id: 'dlab-ad-A23',
      path: '/2273514/trentino/tn_adv_ll_1',
      sizeMappingName: 'LEADERBOARD_DESKTOP',
    },
    {
      id: 'dlab-ad-A24',
      path: '/2273514/trentino/tn_adv_sponsor',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab-ad-A25',
      path: '/2273514/trentino/tn_adv_sticky',
      sizeMappingName: 'BUTTONS_MOBILE',
    },
    {
      id: 'dlab-ad-A26',
      path: '/2273514/trentino/tn_adv_bb_4',
      sizeMappingName: 'SMALL_BILLBOARD',
    },
  ],

  general: {
    reloadInterval: true,
    collapseEmptyDivs: true,
    disableInitialLoad: true,
    enableSingleRequest: true,
    amazontam: true,
    prebid: {
      active: true,
    },
    interstitial: {
      containerId: 'dlab-interstitial',
      slotId: 'dlab-ad-IS',
      slotPath: '/2273514/trentino/tn_web_is',
      displayTimeInSec: 30,
    },
    customRulesUrls: [
      {
        url: '/advertorial/',
        exact: false,
        customExclusionLabels: ['Programmatic'],
      },
      {
        url: '/advertorial/',
        exact: false,
        excludeAdUnit: ['dlab-ad-IS', 'dlab-ad-W'],
      },
      {
        url: '/native/',
        exact: false,
        customExclusionLabels: ['Programmatic'],
      },
      {
        url: '/native/',
        exact: false,
        excludeAdUnit: ['dlab-ad-IS', 'dlab-ad-W'],
      },
      {
        url: '/',
        exact: false,
        slots: [
          'dlab-ad-H1-left',
          'dlab-ad-H1-right',
          'dlab-ad-H2',
          'dlab-ad-H17',
          'dlab-ad-S1-left',
          'dlab-ad-S1-right',
          'dlab-ad-S2',
          'dlab-ad-S17',
          'dlab-ad-S26',
        ],
        customExclusionLabels: ['Programmatic'],
      },
    ],
    preloadedFiles: [
      {
        link: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
        async: true,
      },
    ],
  },
}

export default trentinoConfig
