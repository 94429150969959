import React, { useState } from 'react'
import styled from 'styled-components'
import Input from '../../../../components/Input'
import Icon from '../../../../components/Icon'
import JobList from './JobList'
import translate from '../../../../modules/translate'
import ErrorBoundary from '../../../Error/widgets/ErrorBoundary'
import JobLoadError from '../../JobWidgetPageScreen/components/JobLoadError'
import SkeletonJobWIdgetPage from '../../JobWidgetPageScreen/skeleton'
import InputMui from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Autocomplete from '@mui/material/Autocomplete'
import ListItemText from '@mui/material/ListItemText'
import SelectMUI from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import InputLabel from '@mui/material/InputLabel'
import isStolApp from '../../../../modules/is-stolapp'
import testPartnerFilter from '../../modules/testPartnerFilter'
import { actionMETHOD } from '../../../../modules/fetch-data'
import TextField from '@mui/material/TextField'
import FilterOptions from './filterOptions'
import Tooltip from '@mui/material/Tooltip'
import Chip from '@mui/material/Chip'
import isServerSide from '../../../../modules/is-server-side'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const Wrapper = styled.div``

const StyledFormControl = styled(FormControl)`
  width: 100% !important;
  cursor: text;
  height: 44px;
  margin: unset !important;
  border-radius: 4px !important;
  background-color: #eeeeee !important;
  color: #afafaf;
  border-style: unset !important;
  white-space: unset;
  font-family: 'Splus Icon', 'Fira Sans', sans-serif !important;

  .MuiFormControl-root {
    margin: unset;
  }

  .MuiInputLabel-root {
    font-family: 'Splus Icon', 'Fira Sans', sans-serif !important;
    color: ${(props) => props.theme.default.color.textTertiary};
    &.Mui-focused {
      color: ${(props) => props.theme.default.color.textTertiary};
    }
  }
  .MuiInputBase-root .MuiSelect-select {
    color: ${(props) => props.theme.default.color.textPrimary};
    padding: unset;
    padding-left: 10px;
    padding-top: 7px;
    height: 30px;
    max-height: 30px;
    line-height: 30px;
    min-height: unset;

    font-family: 'Splus Icon','Fira Sans',sans-serif !important;
    font-size: 16px; !important
    background-color: unset; !important
    @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
      font-size: 15px;
    }
  }
  .MuiSvgIcon-root {
    display: none;
  }

  .MuiOutlinedInput-notchedOutline {
    border: unset;
  }
  .MuiOutlinedInput-root div:not(:first-child) {
    display: none; !important


  }
  .MuiOutlinesInput-root{
    .MuiChip-outlined{
      border: unset !important;
      font-family: 'Splus Icon','Fira Sans',sans-serif !important;
      font-size: 16px !important;


    }
  }

  .MuiPaper-root {
    max-height: 304px;
    width: 300px;

    @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
      width: 100%;
    }
  }
  .MuiAutocomplete-popper {
    max-height: 304px;
    width: 300px;
    @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
      width: 100%;
    }
  }

  .MuiPopper-root{
        max-height: 304px;

    width: 300px;
    @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
      width: 100%;
    }
  }

  :focus{
    /* body{
      overflow:hidden;
    } */
    background-color:red;
  }
  #demo-multiple-checkbox-label {
    pointer-events: none;
  }
`

const StyleInputLabel = styled(InputLabel)`
  transform: translate(10px, 11px) scale(1) !important;
  font-family: 'Splus Icon', 'Fira Sans', sans-serif !important;
  color: ${(props) => props.theme.default.color.textTertiary};
  font-size: 16px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: 15px !important;
  }
`

const StyledSelectMUI = styled(SelectMUI)`
  color: ${(props) => props.theme.default.color.textTertiary};
  font-size: 16px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: 15px;
  }
`
const StyledAutocompleteMUI = styled(Autocomplete)`
  color: ${(props) => props.theme.default.color.textTertiary};
  font-size: 16px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: 15px;
  }
  #location-input {
    padding-top: 2px !important;
    padding-left: 1px;
  }
`

const StyledListItemText = styled(ListItemText)`
  white-space: unset !important;
  font-family: 'Splus Icon', 'Fira Sans', sans-serif !important;
  width: 70%;

  .MuiTypography-root {
    font-family: 'Splus Icon', 'Fira Sans', sans-serif !important;
    font-size: 16px;
    width: 70%;
  }
`

const StyledAppSpace = styled.div`
  height: 90px;
  width: 100%;
`

const StyledMenuItem = styled(MenuItem)`
  white-space: unset !important;
  padding-right: 5px !important;
  padding-left: 10px !important;

  .MuiMenuItem-root {
    padding-right: 5px !important;
    padding-left: 10px !important;
  }

  .MuiButtonBase-root {
    padding-right: 5px !important;
    padding-left: 10px !important;
  }
`
const StyledMenuItemLocation = styled.li`
  white-space: unset !important;
  padding-right: 5px !important;
  padding-left: 10px !important;

  .MuiMenuItem-root {
    padding-right: 5px !important;
    padding-left: 10px !important;
  }

  .MuiButtonBase-root {
    padding-right: 5px !important;
    padding-left: 10px !important;
  }
`

const StyledListItemTextLocation = styled.div`
  white-space: unset !important;
  font-family: 'Splus Icon', 'Fira Sans', sans-serif !important;
  flex: 1 1 auto !important;
  width: 68%;

  .MuiTypography-root {
    font-family: 'Splus Icon', 'Fira Sans', sans-serif !important;
    font-size: 16px;
    width: 68%;
  }
`

const StyledOutline = styled(InputMui)`
  border: unset !important;
  background-color: #eeeeee !important;
  color: #afafaf !important;
`

const FlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 2.5em;
  margin-bottom: 2em;
  padding: 0 20px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: 0em;
    padding: 0 40px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding: 0 16px 0 16px;
  }
  @media (max-width: 600px) {
    margin-top: 0px;
    margin-bottom: 0.8em;
  }
`

const InputWrapperFullText = styled.div`
  position: relative;
  height: 40px;
  margin-right: 30px;
  max-width: 220px;

  flex: 2;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: 100%;
    max-width: unset;
    flex: unset;
    margin-right: 0;
    margin-top: 0.8em;
  }
`

const InputWrapperBranch = styled.div`
  cursor: text;
  position: relative;
  margin-right: 30px;
  flex: 1;
  max-width: 140.5px;
  height: 40px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: 100% !important;
    flex: unset;
    max-width: unset;
    margin-right: 0;
    margin-top: 1em;
  }
  @media (max-width: 600px) {
    width: 35% !important;
    margin-top: 0.8em;
    margin-left: auto;
  }
`
const InputWrapperType = styled.div`
  cursor: text;
  position: relative;
  margin-right: 30px;
  flex: 1;
  max-width: 200.5px;
  height: 40px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: 100% !important;
    flex: unset;
    max-width: unset;
    margin-right: 0;
    margin-top: 1em;
  }
  @media (max-width: 600px) {
    width: 60% !important;
    margin-top: 0.8em;
    margin-left: 0px;
  }
`

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 7px;
  right: 10px;
  opacity: 0.8;
  color: #afafaf;
  font-size: 30px;
`
const StyledClockIcon = styled(StyledIcon)`
  @media (max-width: 310px) {
    display: none;
  }
`

const StyledBranchIcon = styled(Icon)`
  position: absolute;

  right: 10px;
  opacity: 0.8;
  color: #afafaf;
  font-size: 27px;
  top: 8px;
  @media (max-width: 340px) {
    display: none;
  }
`

const StyledMapIcon = styled(Icon)`
  position: absolute;

  right: 10px;
  opacity: 0.8;
  color: #afafaf;
  font-size: 24px;
  top: 10px;
`

const FullTextInput = styled(Input)`
  height: 44px;
  background-color: #eeeeee;
  border-bottom: unset !important;
  border-radius: 4px !important;

  font-family: 'Splus Icon', 'Fira Sans', sans-serif !important;
  font-size: 16px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: 15px;
  }

  input {
    background-color: #eeeeee;
    &::placeholder {
      color: ${(props) => props.theme.default.color.textTertiary};
    }
    color: ${(props) => props.theme.default.color.textPrimary};
    padding-left: 10px;
    border-radius: 4px !important;
    font-family: 'Splus Icon', 'Fira Sans', sans-serif !important;
    font-size: 16px;
    @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
      font-size: 15px;
    }
  }
`

const LetsGo = styled.button`
  padding: 15px 35px;
  cursor: pointer;
  background-color: #d41e25;
  border: unset;
  border-radius: 4px !important;
  color: white;
  height: calc(100% - 1em);

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-left: 60px;
    padding-right: 60px;
    margin-top: 1em;
    margin-left: 0px;
  }
  @media (max-width: 600px) {
    padding: 14px 30px;
    width: 100%;
  }

  @media (max-width: 400px) {
    margin-top: 16px;
  }
`

const StyledBranchCount = styled.p`
  font-family: 'Splus Icon', 'Fira Sans', sans-serif;
  font-size: 16px;
  color: #959595de;
`

const StyledCheckbox = styled(Checkbox)`
  color: #959595de !important;
`

const CrossDiv = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0.8;
  color: #afafaf;
  font-size: 30px;
  width: 18px;

  margin-left: auto;
  :after {
    background-color: #999;

    content: '';
    display: block;
    height: 2px;
    margin: 7px 0;

    transform: translateY(-4px) rotate(135deg);
    :hover {
      background-color: #d41e25;
    }
  }

  :before {
    background-color: #999;

    content: '';
    display: block;
    height: 2px;
    margin: 7px 0;

    transform: translateY(5px) rotate(-135deg);
    :hover {
      background-color: #d41e25;
    }
  }

  cursor: pointer;
`

const StyledTextField = styled(TextField)`
padding-top: 4px; !important
padding-left: 2px; !important

.MuiOutlinedInput-root div:not(:first-child) {
    display: none; !important
}
`

const StyledChip = styled(Chip)`
  border: unset !important;
  font-family: 'Splus Icon', 'Fira Sans', sans-serif !important;
  font-size: 16px !important;
  padding-bottom: 6px !important;

  :first-child {
    padding-left: 0px !important;
  }
  .MuiChip-label {
    padding-left: 0px !important;
  }
`

let timerId
const debounce = (fn, delay) => {
  return (...args) => {
    console.log('TIMERID2:', timerId)
    clearTimeout(timerId)
    timerId = setTimeout(fn, delay, [...args])
    console.log('TIMERID3:', timerId)
  }
}

const JobSearchBlock = ({ data, loading, error, ready, match, location, run, ...props }) => {
  const errorOptions = {
    component: translate('topic'),
    link: '/',
    linkText: translate('error_try_homepage'),
    logError: 'Topic screen display',
  }
  const [stateSearchValues, setStateSearchValues] = useState({
    fullText: '',
    location: [],
    locationInputValue: '',
    page: 1,
    branch: [],
    type: [],
  })
  const [stateUrl, setStateUrl] = useState({
    url:
      process.env.RAZZLE_JOBWIDGET_API_HOST +
      '/public/search?class=50&cc=0&l=1&size=50&order=random' +
      testPartnerFilter(),
  })
  const [stateBranchPlaceholder, setStateBranchPlaceholder] = useState([])
  const [stateTypePlaceholder, setStateTypePlaceholder] = useState([])

  const [stateBranchOptions, setStateBranchOptions] = useState(
    data && data.meta && data.meta.filters && data.meta.filters[0].items,
  )
  const [stateLocationOptions, setStateLocationOptions] = useState(
    data && data.meta && data.meta.filters && data.meta.filters[3].items,
  )
  const [stateTypeOptions, setStateTypeOptions] = useState(
    data && data.meta && data.meta.filters && data.meta.filters[2].items,
  )

  const setFilterOptions = (setLocations = true, setBranches = true, setTypes = true, stateData) => {
    let branchString = ''
    if (stateData.branch && stateData.branch.length > 0) {
      branchString = '&filter[jobads-type]='
      stateData.branch.map((branches) => {
        branchString = branchString + branches.value + ';'
      })
      branchString = branchString.substring(0, branchString.length - 1)
    }
    let locationString = ''
    if (stateData.location && stateData.location.length > 0) {
      locationString = '&filter[jobads-district]='
      stateData.location.map((locations) => {
        locationString = locationString + locations.value + ';'
      })
      locationString = locationString.substring(0, locationString.length - 1)
    }
    let typeString = ''
    if (stateData.type && stateData.type.length > 0) {
      typeString = '&filter[jobads-employmenttype]='
      stateData.type.map((types) => {
        typeString = typeString + types.value + ';'
      })
      typeString = typeString.substring(0, typeString.length - 1)
    }

    let stringele =
      `/public/search?class=50&cc=0&l=1&size=50&order=random${testPartnerFilter()}&q=` +
      stateData.fullText +
      stateData.locationInputValue +
      locationString +
      branchString +
      typeString
    console.log(stringele)

    actionMETHOD([], {
      path:
        process.env.RAZZLE_JOBWIDGET_API_HOST +
        `/public/search?class=50&cc=0&l=1&size=50&order=random${testPartnerFilter()}&q=` +
        stateData.fullText +
        stateData.locationInputValue +
        locationString +
        branchString +
        typeString,
      accessType: 'APIKEY',
      access: process.env.RAZZLE_JOBWIDGET_API_KEY,
    }).then(function (data) {
      if (setBranches) {
        setStateBranchOptions(
          data && data.meta && data.meta.filters && data.meta.filters[0].items ? data.meta.filters[0].items : [],
        )
      }

      if (setLocations) {
        setStateLocationOptions(
          data && data.meta && data.meta.filters && data.meta.filters[3].items ? data.meta.filters[3].items : [],
        )
      }
      if (setTypes) {
        setStateTypeOptions(
          data && data.meta && data.meta.filters && data.meta.filters[3].items ? data.meta.filters[2].items : [],
        )
      }
    })
  }

  //Click on start search button
  function letsGoButton() {
    setStateSearchValues({
      fullText: stateSearchValues.fullText,
      location: stateSearchValues.location,
      branch: stateSearchValues.branch,
      page: 1,
      type: stateSearchValues.type,
      locationInputValue: stateSearchValues.locationInputValue,
    })
    let branchString = ''
    if (stateSearchValues.branch && stateSearchValues.branch.length > 0) {
      branchString = '&filter[jobads-type]='
      stateSearchValues.branch.map((branches) => {
        branchString = branchString + branches.value + ';'
      })
      branchString = branchString.substring(0, branchString.length - 1)
    }
    let locationString = ''
    if (stateSearchValues.location && stateSearchValues.location.length > 0) {
      locationString = '&filter[jobads-district]='
      stateSearchValues.location.map((locations) => {
        locationString = locationString + locations.value + ';'
      })
      locationString = locationString.substring(0, locationString.length - 1)
    }
    let typeString = ''
    if (stateSearchValues.type && stateSearchValues.type.length > 0) {
      typeString = '&filter[jobads-employmenttype]='
      stateSearchValues.type.map((types) => {
        typeString = typeString + types.value + ';'
      })
      typeString = typeString.substring(0, typeString.length - 1)
    }

    setStateUrl({
      url:
        `/public/search?class=50&cc=0&l=1&size=50&order=random${testPartnerFilter()}&q=` +
        stateSearchValues.fullText +
        stateSearchValues.locationInputValue +
        locationString +
        branchString +
        typeString,
    })

    run({
      path:
        process.env.RAZZLE_JOBWIDGET_API_HOST +
        `/public/search?class=50&cc=0&l=1&size=50&order=random${testPartnerFilter()}&q=` +
        stateSearchValues.fullText +
        '' +
        stateSearchValues.locationInputValue +
        locationString +
        branchString +
        typeString,
    })
  }

  //click on fulltext button
  function fullTextChange(e) {
    let value = document.getElementById('fullTextJobWidget').value
    let stateData = {
      fullText: value,
      location: stateSearchValues.location,
      branch: stateSearchValues.branch,
      page: stateSearchValues.page,
      type: stateSearchValues.type,
      locationInputValue: stateSearchValues.locationInputValue,
    }
    setStateSearchValues(stateData)
  }

  //click on branchen button
  function branchChange(e) {
    let branchObject = []
    e.target.value.map((b) => {
      branchObject.push({ label: b.split('|')[0], value: b.split('|')[1] })
    })
    let stateData = {
      fullText: stateSearchValues.fullText,
      location: stateSearchValues.location,
      branch: branchObject,
      page: stateSearchValues.page,
      type: stateSearchValues.type,
      locationInputValue: stateSearchValues.locationInputValue,
    }

    setStateSearchValues(stateData)
    setStateBranchPlaceholder(e.target.value)

    setFilterOptions(true, false, true, stateData)
  }

  function typeChange(e) {
    let typeObject = []
    e.target.value.map((b) => {
      typeObject.push({ label: b.split('|')[0], value: b.split('|')[1] })
    })
    let stateData = {
      fullText: stateSearchValues.fullText,
      location: stateSearchValues.location,
      branch: stateSearchValues.branch,
      page: stateSearchValues.page,
      type: typeObject,
      locationInputValue: stateSearchValues.locationInputValue,
    }

    setStateSearchValues(stateData)
    setStateTypePlaceholder(e.target.value)
    setFilterOptions(true, true, false, stateData)
  }

  //delete Function
  function deleteFilter(id) {
    switch (id) {
      case 0:
        let fullTextJobWidget = document.getElementById('fullTextJobWidget')
        fullTextJobWidget.value = ''
        setStateSearchValues({
          fullText: '',
          location: stateSearchValues.location,
          branch: stateSearchValues.branch,
          page: stateSearchValues.page,
          type: stateSearchValues.type,
          locationInputValue: stateSearchValues.locationInputValue,
        })
        setFilterOptions(true, true, true, {
          fullText: '',
          location: stateSearchValues.location,
          branch: stateSearchValues.branch,
          page: stateSearchValues.page,
          type: stateSearchValues.type,
          locationInputValue: stateSearchValues.locationInputValue,
        })

        break
      case 1:
        setStateSearchValues({
          fullText: stateSearchValues.fullText,
          location: [],
          branch: stateSearchValues.branch,
          page: stateSearchValues.page,
          type: stateSearchValues.type,
          locationInputValue: '',
        })

        setFilterOptions(true, true, true, {
          fullText: stateSearchValues.fullText,
          location: [],
          branch: stateSearchValues.branch,
          page: stateSearchValues.page,
          type: stateSearchValues.type,
          locationInputValue: '',
        })
        break
      case 2:
        setStateTypePlaceholder([])
        setStateSearchValues({
          fullText: stateSearchValues.fullText,
          location: stateSearchValues.location,
          branch: stateSearchValues.branch,
          page: stateSearchValues.page,
          type: [],
          locationInputValue: stateSearchValues.locationInputValue,
        })
        setFilterOptions(true, true, true, {
          fullText: stateSearchValues.fullText,
          location: stateSearchValues.location,
          branch: stateSearchValues.branch,
          page: stateSearchValues.page,
          type: [],
          locationInputValue: stateSearchValues.locationInputValue,
        })
        break
      case 3:
        setStateBranchPlaceholder([])
        setStateSearchValues({
          fullText: stateSearchValues.fullText,
          location: stateSearchValues.location,
          branch: [],
          page: stateSearchValues.page,
          type: stateSearchValues.type,
          locationInputValue: stateSearchValues.locationInputValue,
        })
        setFilterOptions(true, true, true, {
          fullText: stateSearchValues.fullText,
          location: stateSearchValues.location,
          branch: [],
          page: stateSearchValues.page,
          type: stateSearchValues.type,
          locationInputValue: stateSearchValues.locationInputValue,
        })
        break

      default:
        let fullTextJobWidget2 = document.getElementById('fullTextJobWidget')
        fullTextJobWidget2.value = ''
        setStateTypePlaceholder([])
        setStateBranchPlaceholder([])
        setStateSearchValues({
          fullText: '',
          location: [],
          branch: [],
          page: [],
          type: [],
          locationInputValue: '',
        })
        setFilterOptions(true, true, true, {
          fullText: '',
          location: [],
          branch: [],
          page: [],
          type: [],
          locationInputValue: '',
        })
        break
    }
  }

  const inputSearch = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      letsGoButton()
    }
  }

  let windowInnerWidth = 300
  if (!isServerSide()) {
    windowInnerWidth = window
      ? window.innerWidth < 600 || window.innerWidth > 1080
        ? 300
        : window.innerWidth - 60
      : 300
  }

  const MenuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
        width: isStolApp() ? '100%' : 300,
        marginTop: '10px',
      },
    },
  }

  const branchHelper = stateBranchOptions
    ? stateBranchOptions
    : data && data.meta && data.meta.filters && data.meta.filters[0].items
  const locationHelper = stateLocationOptions
    ? stateLocationOptions
    : data && data.meta && data.meta.filters && data.meta.filters[3].items
  const typeHelper = stateTypeOptions
    ? stateTypeOptions
    : data && data.meta && data.meta.filters && data.meta.filters[2].items

  return (
    <React.Fragment>
      <ErrorBoundary view={'jobwidget'} {...errorOptions} {...props}>
        <Wrapper id={'jobsite'} {...props}>
          <FlexDiv>
            <InputWrapperFullText>
              <FullTextInput
                id={'fullTextJobWidget'}
                onChange={fullTextChange}
                placeholder={'Beruf, Firma, Begriff...'}
                onKeyPress={inputSearch}
              />
              {stateSearchValues && stateSearchValues.fullText.length === 0 && <StyledIcon icon={'search'} />}
              {stateSearchValues && stateSearchValues.fullText && stateSearchValues.fullText.length > 0 && (
                <CrossDiv onClick={() => deleteFilter(0)} />
              )}
            </InputWrapperFullText>

            <Tooltip
              title="Falls Ort/Region nicht vorhanden, kann auch per Text gesucht werden."
              arrow
              placement="right"
            >
              <InputWrapperFullText>
                <StyledFormControl sx={{ m: 1, width: windowInnerWidth }}>
                  {stateSearchValues &&
                    stateSearchValues.location.length === 0 &&
                    stateSearchValues.locationInputValue.length === 0 && (
                      <StyleInputLabel id="">Ort / Region</StyleInputLabel>
                    )}
                  <StyledAutocompleteMUI
                    multiple
                    freeSolo
                    value={stateSearchValues.location}
                    onKeyPress={inputSearch}
                    onChange={(event, newValue) => {
                      if (event.code === 'Enter') {
                        document.activeElement.blur()
                        event.preventDefault()
                      } else {
                        let stateData = {
                          fullText: stateSearchValues.fullText,
                          location: newValue,
                          branch: stateSearchValues.branch,
                          page: stateSearchValues.page,
                          type: stateSearchValues.type,
                          locationInputValue: stateSearchValues.locationInputValue,
                        }
                        setStateSearchValues(stateData)
                        setFilterOptions(false, true, true, stateData)
                      }
                    }}
                    inputValue={stateSearchValues.locationInputValue}
                    onInputChange={(event, newInputValue) => {
                      if (event.code === 'Enter') {
                        event.preventDefault()
                        letsGoButton()
                      } else {
                        setStateSearchValues({
                          fullText: stateSearchValues.fullText,
                          location: stateSearchValues.location,
                          branch: stateSearchValues.branch,
                          page: stateSearchValues.page,
                          type: stateSearchValues.type,
                          locationInputValue: newInputValue,
                        })
                      }
                    }}
                    id="location-input"
                    options={data && locationHelper ? locationHelper : FilterOptions.locationOptions}
                    disableCloseOnSelect
                    onOpen={() => {
                      if (document.body) {
                        document.ontouchmove = function (e) {
                          e.preventDefault()
                        }
                        if (window.innerWidth > 1080) {
                          const muiOffset = window.innerWidth - document.body.offsetWidth
                          document.body.style.paddingRight = muiOffset < 20 ? muiOffset + 'px' : 'unset'
                          document.body.style.overflow = muiOffset < 20 ? 'hidden' : 'unset'
                        }
                        document.body.style.touchAction = 'none'
                        document.body.style.msTouchAction = 'none'
                      }
                    }}
                    onClose={() => {
                      if (document.body) {
                        document.ontouchmove = function (e) {
                          return true
                        }
                        document.body.style.overflow = 'unset'
                        document.body.style.position = 'unset'
                        document.body.style.paddingRight = 'unset'
                        document.body.style.touchAction = 'unset'
                        document.body.style.msTouchAction = 'unset'
                      }
                    }}
                    getOptionLabel={(option) => option.label}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <StyledChip
                          variant="outlined"
                          label={value.length > 1 ? option.label + ' + ' + (value.length - 1) : option.label}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderOption={(props, option, { selected }) => (
                      <StyledMenuItemLocation {...props}>
                        <StyledListItemTextLocation>{option.label}</StyledListItemTextLocation>
                        <StyledBranchCount>{option.count ? option.count : ''}</StyledBranchCount>

                        <StyledCheckbox style={{ marginRight: 8 }} checked={selected} />
                      </StyledMenuItemLocation>
                    )}
                    style={{ width: windowInnerWidth }}
                    renderInput={(params) => <StyledTextField {...params} onKeyPress={inputSearch} />}
                    sx={{ width: '300px' }}
                  />
                </StyledFormControl>

                {stateSearchValues &&
                  stateSearchValues.location.length === 0 &&
                  stateSearchValues.locationInputValue.length === 0 && <StyledMapIcon icon={'mapmark'} />}
                {stateSearchValues &&
                  stateSearchValues.location &&
                  (stateSearchValues.location.length !== 0 || stateSearchValues.locationInputValue.length !== 0) && (
                    <CrossDiv onClick={() => deleteFilter(1)} />
                  )}
              </InputWrapperFullText>
            </Tooltip>

            <InputWrapperType>
              <StyledFormControl sx={{ m: 1, width: 300 }}>
                {stateSearchValues && stateSearchValues.type.length === 0 && (
                  <StyleInputLabel id="demo-multiple-checkbox-label">Beschäftigungsart</StyleInputLabel>
                )}

                <StyledSelectMUI
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={stateTypePlaceholder}
                  onChange={typeChange}
                  input={<StyledOutline label="Beschäftigungsart" />}
                  renderValue={(selected) => {
                    let labelArray = []
                    selected.map((select) => {
                      labelArray.push(select.split('|')[0])
                    })
                    return labelArray.join(' / ')
                  }}
                  MenuProps={MenuProps}
                >
                  {data &&
                    !loading &&
                    typeHelper.map((option) => {
                      return (
                        <StyledMenuItem key={option.value} value={option.label + '|' + option.value}>
                          <StyledListItemText primary={option.label} />
                          <StyledBranchCount>{option.count}</StyledBranchCount>
                          <StyledCheckbox
                            checked={
                              stateTypePlaceholder &&
                              stateTypePlaceholder.filter(function (e) {
                                return e.split('|')[0] === option.label
                              }).length > 0
                            }
                          />
                        </StyledMenuItem>
                      )
                    })}
                </StyledSelectMUI>
              </StyledFormControl>

              {stateSearchValues && stateSearchValues.type.length === 0 && <StyledClockIcon icon={'clock'} />}
              {stateSearchValues && stateSearchValues.type && stateSearchValues.type.length !== 0 && (
                <CrossDiv onClick={() => deleteFilter(2)} />
              )}
            </InputWrapperType>

            <InputWrapperBranch>
              <StyledFormControl sx={{ m: 1, width: 300 }}>
                {stateSearchValues && stateSearchValues.branch.length === 0 && (
                  <StyleInputLabel id="demo-multiple-checkbox-label">Branchen</StyleInputLabel>
                )}

                <StyledSelectMUI
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={stateBranchPlaceholder}
                  onChange={branchChange}
                  input={<StyledOutline label="Branchen" />}
                  renderValue={(selected) => {
                    let labelArray = []
                    selected.map((select) => {
                      labelArray.push(select.split('|')[0])
                    })
                    return labelArray.join(' / ')
                  }}
                  MenuProps={MenuProps}
                >
                  {data &&
                    !loading &&
                    branchHelper.map((option) => {
                      return (
                        <StyledMenuItem key={option.value} value={option.label + '|' + option.value}>
                          <StyledListItemText primary={option.label} />
                          <StyledBranchCount>{option.count}</StyledBranchCount>
                          <StyledCheckbox
                            checked={
                              stateBranchPlaceholder &&
                              stateBranchPlaceholder.filter(function (e) {
                                return e.split('|')[0] === option.label
                              }).length > 0
                            }
                          />
                        </StyledMenuItem>
                      )
                    })}
                </StyledSelectMUI>
              </StyledFormControl>
              {stateSearchValues && stateSearchValues.branch.length === 0 && (
                <StyledBranchIcon icon={'stellenanzeigen'} />
              )}
              {stateSearchValues && stateSearchValues.branch && stateSearchValues.branch.length !== 0 && (
                <CrossDiv onClick={() => deleteFilter(3)} />
              )}
            </InputWrapperBranch>

            <LetsGo onClick={() => letsGoButton()}>Los gehts!</LetsGo>
          </FlexDiv>

          {error && <JobLoadError logInfo={error.message} {...errorOptions} />}
          {loading && <SkeletonJobWIdgetPage />}
          {data && <JobList data={data} url={stateUrl.url} page={stateSearchValues.page + 1} />}
          {isStolApp() && <StyledAppSpace />}
        </Wrapper>
      </ErrorBoundary>
    </React.Fragment>
  )
}
JobSearchBlock.propTypes = {}
JobSearchBlock.defaultProps = {}

export default JobSearchBlock
export {
  StyleInputLabel as JobSearchBlock0StyleInputLabel,
  FullTextInput as JobSearchBlock0FullTextInput,
  FlexDiv as JobSearchBlock0FlexDiv,
  LetsGo as JobSearchBlock0LetsGo,
  StyledListItemTextLocation as JobSearchBlock0StyledListItemTextLocation,
  StyledListItemText as JobSearchBlock0StyledListItemText,
  InputWrapperFullText as JobSearchBlock0InputWrapperFullText,
  InputWrapperType as JobSearchBlock0InputWrapperType,
  InputWrapperBranch as JobSearchBlock0InputWrapperBranch,
}
