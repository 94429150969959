import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import translate from '../../../../../modules/translate'
import Button from '../../../../../components/Button'
import { Get } from 'react-axios'
import JobLoadError from '../../../JobWidgetPageScreen/components/JobLoadError'
import SkeletonJobWIdgetPage from '../../../JobWidgetPageScreen/skeleton'
import Axios from 'axios'
import testPartnerFilter from '../../../modules/testPartnerFilter'

const Wrapper = styled.div``
const StyledLoadMoreJobs = styled.div`
  min-width: 100px;
  margin: 10px auto auto;
  width: fit-content;
`

const StyledButton = styled(Button)`
  margin: 0 auto;
  margin-bottom: 10px;
  color: #d41e25;
`
const axiosInstance = Axios.create({
  baseURL: process.env.RAZZLE_JOBWIDGET_API_HOST,
  timeout: 5000,
  headers: { Authorization: 'APIKEY ' + process.env.RAZZLE_JOBWIDGET_API_KEY },
})

const JobLoadMore = ({ url, page, afterloadmore, ...props }) => {
  const [loadMore, getMoreArticles] = useState(false)
  const [stopLoading, setStopLoading] = useState(false)
  const [pageCount, setPageCount] = useState(page)

  const errorOptions = {
    component: translate('topic'),
    link: '/',
    linkText: translate('error_try_homepage'),
    logError: 'Topic screen display',
  }

  return (
    <Wrapper {...props}>
      {loadMore && (
        <Fragment>
          <Get url={url + '&page=' + pageCount + testPartnerFilter()} instance={axiosInstance}>
            {(error, response, isLoading) => {
              if (error) {
                return <JobLoadError logInfo={error.message} {...errorOptions} />
              }
              if (isLoading) {
                return <SkeletonJobWIdgetPage />
              }
              if (response) {
                getMoreArticles(false)
                if (response && response.data && response.data.data) {
                  afterloadmore(response.data)
                }
                if (
                  response &&
                  response.data.data &&
                  response.data.data.length != 0 &&
                  response.data.meta.pagination.per_page == response.data.meta.pagination.count
                ) {
                  setPageCount(pageCount + 1)
                } else {
                  setStopLoading(true)
                }
              }
              return <Fragment></Fragment>
            }}
          </Get>
        </Fragment>
      )}
      {!loadMore && !stopLoading && (
        <StyledLoadMoreJobs>
          <StyledButton
            onClick={() => {
              getMoreArticles(true)
            }}
          >
            {translate('load_more')}
          </StyledButton>{' '}
        </StyledLoadMoreJobs>
      )}
    </Wrapper>
  )
}
JobLoadMore.propTypes = {}
JobLoadMore.defaultProps = {}
export default JobLoadMore
export { StyledButton as JobLoadMore0StyledButton, Wrapper as JobLoadMore0Wrapper }
