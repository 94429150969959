import hasItems from '../../modules/has-items'
import ErrorBoundary from '../../topics/Error/widgets/ErrorBoundary'
import BlockBuilder from '../BlockBuilder'
import React from 'react'
import getData from './modules/get-data'
import getIdsArticles from './modules/get-ids-articles'
import getPropsData from './modules/get-props-data'
import consoleLogger from '../../modules/console-logger'

const LayoutBuilder = ({ layout, data, loading, blocks }) => {
  const [consoleLog] = consoleLogger('LayoutBuilder')
  consoleLog('layout config: ', layout)
  consoleLog('data:  ', data)
  consoleLog('loading?:  ', loading)
  return (
    <ErrorBoundary info="LayoutBuilder">
      {layout.feed.map(
        (
          { name, adIds, dataStatic, dataIds, dataProps, articleIds, articlesFrom, margin, noGapEdge, feed, ...props },
          index,
        ) => {
          let blockData = {}

          if (data) {
            if (dataIds) {
              blockData = getData({ dataIds, data, noArticles: !hasItems(articleIds) })
            }
            if (articleIds) {
              blockData['articles'] = getIdsArticles({ articleIds, articles: data.articles })
            }
            if (articlesFrom) {
              blockData['articles'] = data.articles.slice(articlesFrom)
            }
            if (dataProps) {
              blockData = getPropsData({ selectedProps: dataProps, data: data })
            }
            if (feed && blockData === []) {
              blockData = data
            }
          }
          if (dataStatic) {
            blockData = dataStatic
          }

          return (
            <BlockBuilder
              view={name}
              key={name + index}
              adIds={adIds}
              data={blockData}
              dataIds={dataIds}
              margin={margin}
              noGapEdge={noGapEdge}
              layout={{ ...layout, feed }}
              loading={loading}
              blocks={blocks}
              {...props}
            />
          )
        },
      )}
    </ErrorBoundary>
  )
}

export default LayoutBuilder
