import React from 'react'
import PropTypes from 'prop-types'
import LayoutDefault from '../../layouts/Default/index'
import { Async } from 'react-async'
import fetchData from '../../modules/fetch-data'
import isServerSide from '../../modules/is-server-side'
import loadable from '@loadable/component'

const ScreenOnTour = loadable(() =>
  import(/* webpackChunkName: "ScreenOnTour" , webpackPrefetch: true */ './screens/ScreenOnTour'),
)

const OnTour = ({ initialData, match, location, view, ...props }) => {
  let routeState
  if (!isServerSide()) {
    routeState = (location && location.state) || (window && window.athesia_react && window.athesia_react.state)
  }

  return (
    <Async promiseFn={fetchData} location={location.pathname} watch={location} initialValue={initialData}>
      {({ data, error, isPending, isFulfilled }) => {
        let timeout = data && data.data && data.data.timeout

        return (
          <LayoutDefault meta={isFulfilled && data.meta} {...props}>
            <ScreenOnTour
              response={isFulfilled && !timeout && data}
              loading={isPending || timeout}
              error={error}
              ready={isFulfilled && !timeout}
              match={match}
              view={view}
              routeState={routeState}
            />
          </LayoutDefault>
        )
      }}
    </Async>
  )
}

OnTour.getInitialData = ({ req }) => {
  return fetchData({ location: req.url })
}

OnTour.propTypes = {
  initialData: PropTypes.shape({
    meta: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    config: PropTypes.object,
  }),
  location: PropTypes.object,
  match: PropTypes.object,
  view: PropTypes.string,
}

OnTour.defaultProps = {
  location: {
    pathname: undefined,
  },
}

export default OnTour
