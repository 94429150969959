import React from 'react'
import IdPiano from './screens/IdPiano'
import LayoutDefault from '../../layouts/Default'

const Login = ({ view, ...props }) => {
  let meta
  switch (view) {
    case 'account':
      meta = {
        title: 'Profil | stol.it',
        adsTargeting: {
          STOL7_seite: 'Profil',
        },
      }
      break
    case 'profile.register':
      meta = {
        title: 'Registrieren | stol.it',
        adsTargeting: {
          STOL7_seite: 'Registrieren',
        },
      }
      break
    case 'profile.restore':
      meta = {
        title: 'Passwort wiederherstellen | stol.it',
        adsTargeting: {
          STOL7_seite: 'Passwort wiederherstellen',
        },
      }
      break
    case 'profile.new_password':
      meta = {
        title: 'Neues Passwort | stol.it',
        adsTargeting: {
          STOL7_seite: 'Neues Passwort',
        },
      }
      break
    case 'profile.logout':
      meta = {
        title: 'Abmelden | stol.it',
        adsTargeting: {
          STOL7_seite: 'Abmelden',
        },
      }
      break
    case 'login':
      meta = {
        title: 'Anmelden | stol.it',
        adsTargeting: {
          STOL7_seite: 'Anmelden',
        },
      }
      break
    default:
      meta = {
        title: 'stol.it',
        adsTargeting: {
          STOL7_seite: 'stol.it',
        },
      }
  }
  return (
    <LayoutDefault meta={meta} {...props}>
      <IdPiano view={view} />
    </LayoutDefault>
  )
}

Login.propTypes = {
  view: IdPiano.propTypes.view,
}

Login.defaultProps = {
  view: IdPiano.defaultProps.view,
}

export default Login
