import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import TopLine from '../../../../../../components/TopLine'
import TitleItemArticle, { Link0TitleItemArticle, Title0TitleItemArticle } from './components/TitleItemArticle'
import TextItemArticle from './components/TextItemArticle'
import translate from '../../../../../../modules/translate'
import truncate from '../../../../../../modules/truncate'
import Link from '../../../../../../components/Link'
import moment from 'moment'

const Wrapper = styled.div`
  ${(props) =>
    props.inverted === 1
      ? css`
          background-color: ${(props) => props.theme.color.multimedia[props.inverted_background_color]};
        `
      : css`
          background-color: transparent;
        `};
  flex-basis: ${(props) => props.theme.sizes.col4};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-basis: ${(props) => props.theme.tablet.sizes.col4};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-basis: ${(props) => props.theme.mobile.sizes.col4};
  }
  padding-left: 0;
  margin-top: 10px;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
`
const WrapperTextLink = styled(Link)`
  display: block;
  max-width: 100%;
  font-size: ${(props) => props.theme.fontSize.base};

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: none;
  }
`
const More = styled(Link)`
  ${(props) =>
    props.inverted === 1
      ? css`
          color: ${(props) => props.theme.color.textInverted};
          text-decoration: underline;
        `
      : css`
          color: ${(props) => props.theme.color.stol};
        `}
`
const StyledTextItemArticle = styled(TextItemArticle)`
  max-width: 100%;
  font-family: ${(props) => props.theme.font.primary};
`
const StyledTopLine = styled(TopLine)`
  max-width: 100%;
  margin-bottom: 5px;
`
const StyledDateDiv = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  font-weight: ${(props) => props.theme.fontWeight.regular};
  font-size: ${(props) => props.theme.fontSize.smallX};
  line-height: ${(props) => props.theme.lineHeight.title};
  font-family: ${(props) => props.theme.font.primary};
  color: ${(props) => props.theme.color.textSecondary};

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    right: 5px;
  }
`

const DescriptionItemArticle = ({
  url,
  department,
  headline,
  headlinePrefix,
  title,
  description,
  moreLink,
  inverted,
  truncateTitle,
  truncateText,
  inverted_background_color,
  image,
  date,
  video,
  partner,
  onClick,
  textTag,
  adsTargeting,
  to,
  noTo,
  plus,
  ...props
}) => {
  if (!truncateTitle || !truncateTitle.numberOfLines) {
    truncateTitle = { numberOfLines: 2, ...truncateTitle }
  }
  return (
    <Fragment>
      <Wrapper inverted_background_color={inverted_background_color} inverted={inverted} {...props}>
        <StyledTopLine
          department={department}
          headline={headline}
          headlinePrefix={headlinePrefix}
          inverted_background_color={inverted_background_color}
          inverted={inverted}
        />
        <TitleItemArticle
          truncateOptions={truncateTitle}
          inverted={inverted}
          plus={plus}
          inverted_background_color={inverted_background_color}
          to={to}
          noTo={noTo}
          onClick={onClick}
          nextToAnother={props.nextToAnother}
          forceTarget={props.forceTarget}
        >
          {title}
        </TitleItemArticle>
        {description && (
          <WrapperTextLink to={!noTo && to ? to : undefined} onClick={onClick}>
            <StyledTextItemArticle
              as={textTag}
              truncateOptions={truncateText}
              inverted={inverted}
              inverted_background_color={inverted_background_color}
            >
              {description}
            </StyledTextItemArticle>
            {moreLink && (
              <More inverted={inverted} to={to} onClick={onClick}>
                {translate('article_more')}
              </More>
            )}
          </WrapperTextLink>
        )}
      </Wrapper>
      {date && <StyledDateDiv>{moment(date).format('DD.MM.YYYY')}</StyledDateDiv>}
    </Fragment>
  )
}

DescriptionItemArticle.propTypes = {
  url: PropTypes.string.isRequired,
  department: TopLine.propTypes.department,
  headline: TopLine.propTypes.headline,
  headlinePrefix: TopLine.propTypes.headlinePrefix,
  title: TitleItemArticle.propTypes.children,
  text: TextItemArticle.propTypes.children,
  moreLink: PropTypes.bool,
  inverted: PropTypes.number,
  truncateTitle: truncate.propTypes.truncateOptions,
  truncateText: truncate.propTypes.truncateOptions,
}

DescriptionItemArticle.defaultProps = {
  moreLink: false,
  inverted: 0,
  inverted_background_color: 'backgroundTertiary',
}

export default DescriptionItemArticle

export {
  StyledTopLine as TopLine0DescriptionItemArticle,
  Link0TitleItemArticle as TitleLink0DescriptionItemArticle,
  Title0TitleItemArticle as Title0DescriptionItemArticle,
  StyledTextItemArticle as Text0DescriptionItemArticle,
  WrapperTextLink as WrapperText0DescriptionItemArticle,
}
