import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { addSpecialTargets } from '../../modules/ads-targets'
import Options from '../../config/options'
import initAds from '../../bundles/BundleAds/modules/init-ads'
import destroyAdSettings from '../../bundles/BundleAds/modules/destroy-ad-settings'
import { useUserContext } from '../ContextUser'
import prebidVideoSuccess from '../../bundles/BundleAds/vendor/prebid-magnite/modules/prebid-video-success'

const ContextAds = React.createContext({ adsDispatch: () => {}, targets: {}, showAds: true, videoTargetUri: '' })

const AdsProvider = ({ meta, targeting, wait, showAds, children, forcedConfigs, ...props }) => {
  const {
    access: { isPlus },
    profile,
  } = useUserContext()
  const reducer = (state, action) => {
    switch (action) {
      case 'wait':
        return 'wait'
      case 'ready':
      case 'default-start':
        return 'ready'
    }
  }

  const [adsState, adsDispatch] = useReducer(reducer, wait ? 'wait' : 'ready')
  const [targets, setTargets] = useState({})
  const [localVideoTargetUri, setLocalVideoTargetUri] = useState('')

  useEffect(() => {
    if (meta?.url && showAds && isPlus !== undefined && adsState === 'ready') {
      const mergedTargets = {
        ...meta.adsTargeting,
        ...targeting,
      }
      addSpecialTargets(mergedTargets)

      const checkPlus = isPlus && profile

      mergedTargets['splus'] = checkPlus ? 'true' : 'false' //magic default/premium
      mergedTargets['splus_flag'] = checkPlus ? 'true' : 'false'

      if (mergedTargets !== targets) {
        setTargets(mergedTargets)
      }
      let excludedLabels = []
      if (isPlus && profile) {
        excludedLabels = [...Options.Ads.sPlusExcludedAdsLabels, ...excludedLabels]
      }

      const onPrebidVideoSuccess = (props) => {
        const custParams = prebidVideoSuccess(props)
        setLocalVideoTargetUri(custParams)
      }

      initAds({
        adTargets: mergedTargets,
        excludedLabels: excludedLabels,
        onPrebidVideoSuccess: onPrebidVideoSuccess,
        forcedConfigs,
      })
    }

    return () => {
      destroyAdSettings()
    }
  }, [meta.url, isPlus, adsState])

  const value = useMemo(() => ({ adsDispatch, targets, showAds: showAds, videoTargetUri: localVideoTargetUri }), [
    meta.url,
    isPlus,
    adsState,
    targets,
    showAds,
    localVideoTargetUri,
  ])

  return (
    <ContextAds.Provider value={value} {...props}>
      {children}
    </ContextAds.Provider>
  )
}

const useAdsContext = () => useContext(ContextAds)

export default ContextAds

export { AdsProvider, useAdsContext }
