import React, { Fragment } from 'react'
import SkeletonSmallLandscapeItemArticle from '../../../Article/components/ItemArticle/views_default/SmallLandscapeItemArticle/skeleton'
import styled from 'styled-components'
import SkeletonWidgetNearMeWeather from '../../../Weather/widgets/WidgetNearMeWeather/skeleton'
import ContainerFull from '../../../../components/ContainerFull'

const Wrapper = styled.div``

const WrapperInner = styled.div`
  margin-bottom: ${(props) => props.theme.sizes.gapVerticalHalf};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
  }
  @media (max-width: 450px) {
    margin-left: 0;
    margin-right: 0;
  }
`

const WrapperSkeletonSmallLandscapeItemArticle = styled.div`
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
  &:first-child {
    margin-top: 0;
  }
`

const SkeletonSmallLandscapeItemArticles = ({ items, ...props }) => {
  let SmallLandscapeItemArticles = []
  for (let i = 0; i < items; i++) {
    SmallLandscapeItemArticles.push(
      <WrapperSkeletonSmallLandscapeItemArticle>
        <SkeletonSmallLandscapeItemArticle />
      </WrapperSkeletonSmallLandscapeItemArticle>,
    )
  }
  return (
    <Wrapper>
      <WrapperInner>{SmallLandscapeItemArticles}</WrapperInner>
    </Wrapper>
  )
}

const StyledSkeletonWidgetNearMeWeather = styled(SkeletonWidgetNearMeWeather)`
  margin-bottom: ${(props) => props.theme.sizes.gapVerticalHalf};
`

const SkeletonNearMe = () => (
  <Fragment>
    <ContainerFull>
      <SkeletonSmallLandscapeItemArticles items={3} />
    </ContainerFull>
    <StyledSkeletonWidgetNearMeWeather />
    <ContainerFull>
      <SkeletonSmallLandscapeItemArticles items={23} />
    </ContainerFull>
  </Fragment>
)

SkeletonNearMe.defaultProps = {
  items: 26,
}

export default SkeletonNearMe
