import React from 'react'
import styled from 'styled-components'
import translate from '../../../../modules/translate/index'
import Tabs from '../../../../components/Tabs/index'
import TabsItem from '../../../../components/TabsItem/index'
import { Switch } from 'react-router'
import { Route } from 'react-router-dom'
import isServerSide from '../../../../modules/is-server-side'
import skeletonFade from '../../../../modules/mixins/skeleton-fade'
import loadable from '@loadable/component'
import routes from '../../../routes'

const Prognosis = loadable(() =>
  import(/* webpackChunkName: "Prognosis" , webpackPrefetch: true */ './screens/ScreenPrognosis/index'),
)
const MountainWeather = loadable(() =>
  import(/* webpackChunkName: "MountainWeather" , webpackPrefetch: true */ './screens/ScreenMountainWeather/index'),
)
const RadarRainfall = loadable(() =>
  import(/* webpackChunkName: "RadarRainfall" , webpackPrefetch: true */ './screens/ScreenRadarRainfall/index'),
)
const ForecastAvalanche = loadable(() =>
  import(/* webpackChunkName: "ForecastAvalanche" , webpackPrefetch: true */ './screens/ScreenAvalanche/index'),
)

const StyledTabsSkeleton = styled.div`
  background-color: ${(props) => props.theme.color.skeletonScreen};
  ${skeletonFade};

  width: 100%;
  height: 45px;
  margin-bottom: 5px;
`

const ScreenWeather = ({ location, response, loading, failed, ...props }) => {
  const value = location ? location.pathname : !isServerSide() && window.location && window.location.pathname

  const data = response && response.data
  const meta = response && response.meta

  return (
    <React.Fragment>
      {!data && <StyledTabsSkeleton></StyledTabsSkeleton>}
      {data && (
        <Tabs value={value}>
          <TabsItem label={translate('weather_forecast')} value={routes.getOptions('weather').path} />
          <TabsItem label={translate('weather_mountain')} value={routes.getOptions('weather.mountain').path} />
          <TabsItem
            label={translate('weather_rainfall_radar')}
            value={routes.getOptions('weather.rainfall_radar').path}
          />
          {data.avalancheReportAvailable && (
            <TabsItem label={translate('weather_avalanche')} value={routes.getOptions('weather.avalanche').path} />
          )}
        </Tabs>
      )}

      <Switch location={location}>
        <Route
          {...routes.getOptions('weather')}
          render={(props) => (
            <Prognosis
              data={data}
              loading={loading}
              failed={failed}
              adTargeting={meta && meta.adsTargeting}
              {...props}
            />
          )}
        />
        <Route
          {...routes.getOptions('weather.mountain')}
          render={(props) => <MountainWeather data={data} loading={loading} failed={failed} {...props} />}
        />
        <Route
          {...routes.getOptions('weather.rainfall_radar')}
          render={(props) => <RadarRainfall data={data} loading={loading} failed={failed} {...props} />}
        />
        <Route
          {...routes.getOptions('weather.avalanche')}
          render={(props) => <ForecastAvalanche data={data} loading={loading} failed={failed} {...props} />}
        />
      </Switch>
    </React.Fragment>
  )
}

export default ScreenWeather
