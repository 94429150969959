import React from 'react'
import LayoutDefault from '../../layouts/Default/'
import ScreenNewsFeed from './screens/ScreenNewsFeed'

const meta = {
  title: 'Newsticker',
  adsTargeting: {
    STOL7_seite: 'Newsticker',
  },
}

const NewsFeed = ({ ...props }) => (
  <LayoutDefault meta={meta} {...props}>
    <ScreenNewsFeed response={{ meta }} />
  </LayoutDefault>
)

export default NewsFeed
