import React from 'react'
import styled from 'styled-components'
import LayoutDefault from '../../layouts/Default/index'
import fetchData, { actionMETHOD } from '../../modules/fetch-data'
import { Async } from 'react-async'
import BookmarkScreen from './screens/BookmarkScreen'
import userSession from '../Profile/modules/user-session'
import TitleTopic from '../../components/TitleTopic'
import consoleLogger from '../../modules/console-logger'
import PlusDialog from '../Article/components/DetailArticle/components/ContentDetailArticle/components/PlusDialog'
import { useUserContext } from '../../components/ContextUser'

const Wrapper = styled.div``

const PlusDialogDiv = styled(PlusDialog)`
  padding: 0 20px;
  margin: 20px auto;

  @media (min-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    max-width: 640px;
  }
`

const BookmarkContent = ({ isFulfilled, isPending, match, data, error, run, ...props }) => {
  const [consoleLog] = consoleLogger('Bookmark')
  const {
    access: { isPlus },
    profile,
  } = useUserContext()

  consoleLog('plus', isPlus, 'UseAccess')
  consoleLog('profile', profile, 'UseUserData')

  return (
    <Wrapper>
      {(profile && isPlus && (
        <BookmarkScreen
          response={isFulfilled && data}
          loading={isPending}
          error={error}
          ready={isFulfilled}
          match={match}
          run={run}
        />
      )) || (
        <>
          <TitleTopic view="screen" title={data && data.title ? data.title : 'Lesezeichen'} />
          <PlusDialogDiv id={'plusDialog'} />
        </>
      )}
    </Wrapper>
  )
}

const Bookmark = ({ match, location, layout, ...props }) => {
  if (typeof layout === 'undefined') {
    layout = true
  }

  return (
    <Async
      promiseFn={fetchData}
      deferFn={actionMETHOD}
      location={'/user/bookmarks'}
      watch={location}
      access={userSession.getAccessToken()}
      version="v1"
    >
      {({ data, error, isPending, isFulfilled, run }) => {
        return (
          <Wrapper>
            <LayoutDefault meta={isFulfilled && data.meta} disable={!layout} {...props}>
              <BookmarkContent
                isFulfilled={isFulfilled}
                isPending={isPending}
                match={match}
                data={data}
                error={error}
                run={run}
              />
            </LayoutDefault>
          </Wrapper>
        )
      }}
    </Async>
  )
}

Bookmark.propTypes = {}
Bookmark.defaultProps = {}
export default Bookmark
