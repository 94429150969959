import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import MessageText, { colorSets } from '../../../../../UserGeneratedContent/components/FormUGC/components/MessageText'
import translate from '../../../../../../modules/translate'
import React, { useState } from 'react'
import styled from 'styled-components'
import Icon from '../../../../../../components/Icon'
import { useMutation } from '@tanstack/react-query'
import apiConnector from '../../../../../../modules/api-connector'
import userSession from '../../../../modules/user-session'
import DialogMessage from '../DialogMessage'
import DialogActions from '../DialogActions'
import DialogButton from '../DialogButton'

const MessageTitle = styled.div`
  font-family: ${(props) => props.theme.default.font.primary};
  text-align: center;
  font-weight: bold;
`

const KeyIcon = styled(Icon)`
  font-size: 20px;
  margin-right: 5px;
  margin-bottom: 7px;
`

const StyledMessageText = styled(MessageText)`
  justify-content: center;
`
const DialogContentInsertCode = ({ onClose = ({ isSuccess }) => {} }) => {
  const [code, setCode] = useState('')
  const mutation = useMutation({
    mutationFn: (data) => {
      return apiConnector({
        location: 'verifySms',
        method: 'post',
        data: data,
        access: userSession.getAccessToken(),
      })
    },
  })
  const blockDialog = mutation.isSuccess || mutation.isLoading || !code
  const getCode = (e) => {
    setCode(e.target.value)
  }

  return (
    <>
      {!mutation.isSuccess && (
        <DialogContent sx={{ m: 0, p: 0 }}>
          <MessageTitle>Bitte Zahlencode aus SMS hier eingeben:</MessageTitle>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', mb: 3 }}>
            <KeyIcon icon="keyboard" />
            <TextField id="input-with-sx" label="Zahlencode" variant="standard" onChange={getCode} />
          </Box>
        </DialogContent>
      )}
      {mutation.isError && (
        <DialogMessage color={colorSets.error}>
          {mutation.error?.response?.data?.errors || mutation.error?.response?.data?.error}
        </DialogMessage>
      )}
      {mutation.isSuccess && (
        <DialogMessage color={colorSets.success}>{translate('verification_verify_success')}</DialogMessage>
      )}

      <DialogActions>
        <DialogButton
          onClick={() => {
            mutation.mutate({ code: code })
          }}
          loading={mutation.isLoading}
          disabled={blockDialog}
        >
          {translate('verification_verify_button')}
        </DialogButton>
        <DialogButton
          onClick={() => onClose({ isSuccess: mutation.isSuccess })}
          disabled={mutation.isLoading}
          view="grey"
        >
          {translate('close')}
        </DialogButton>
      </DialogActions>
    </>
  )
}

export default DialogContentInsertCode
