import * as DefaultSentry from '@sentry/browser'
import PropTypes from 'prop-types'
import { Integrations } from '@sentry/tracing'
const packageJson = require('../../../package.json')

const errorTracker = ({ Sentry, environment, mode = 'all', envMode = '' }) => {
  if (!Sentry) {
    Sentry = DefaultSentry
  }
  if (process.env.RAZZLE_ERROR_TRACKING) {
    Sentry.init({
      release: `${environment}@${packageJson.version}`,
      dsn: process.env.RAZZLE_ERROR_TRACKING,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.0001,
      environment: environment + envMode,
      beforeSend(event) {
        switch (
          mode //do not touch this!
        ) {
          case 'all':
            return event
          case 'type':
            if (event.tags && event.tags.type) return event
            break
        }
      },
    })
  }
}

errorTracker.propTypes = {
  environment: PropTypes.oneOf(['BundleSTOLWidgets', 'BundleSTOLApp', 'STOLReact']),
  mode: PropTypes.oneOf(['all', 'type']),
}

export default errorTracker
