import React from 'react'
import LayoutDefault from '../../layouts/Default'
import fetchData from '../../modules/fetch-data'
import { Async } from 'react-async'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

const ScreenObituary = loadable(() =>
  import(/* webpackChunkName: "ScreenObituary" , webpackPrefetch: true */ './screens/ScreenObituary'),
)

const Obituary = ({ initialData, location, ...props }) => (
  <Async promiseFn={fetchData} location={location.pathname} watch={location} initialValue={initialData}>
    {({ data, error, isPending, isFulfilled }) => {
      let timeout = data && data.data && data.data.timeout

      return (
        <LayoutDefault meta={isFulfilled && data.meta} {...props}>
          <ScreenObituary
            error={error}
            loading={isPending && timeout}
            response={isFulfilled && !timeout && data}
            ready={isFulfilled && !timeout}
            {...props}
          />
        </LayoutDefault>
      )
    }}
  </Async>
)

Obituary.getInitialData = ({ req }) => {
  return fetchData({ location: req.url })
}

Obituary.propTypes = {
  initialData: PropTypes.shape({
    meta: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    config: PropTypes.object,
  }),
  location: PropTypes.object,
}

Obituary.defaultProps = {
  location: {
    pathname: undefined,
  },
}

export default Obituary
