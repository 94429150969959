//this function provides you with a unique way to log errors - useful with Promises or try catch blocks.

import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'
import isStolApp from '../is-stolapp'

const errorLog = ({ error, info, type = 'custom', level, tags }) => {
  Sentry.captureException(error)
  Sentry.captureMessage(info)

  Sentry.configureScope((scope) => {
    if (tags) scope.setTags(tags)
    if (level) scope.setLevel(level)
    scope.setTag('type', type)
    if (isStolApp()) scope.setTag('STOLApp', isStolApp())
  })
}

errorLog.propTypes = {
  error: PropTypes.string,
  info: PropTypes.string,
  level: PropTypes.oneOf(['fatal', 'error', 'warning', 'info', 'debug']),
  tag: PropTypes.object,
  type: PropTypes.oneOf(['custom', 'app', 'message', 'not-found']),
}

export default errorLog
