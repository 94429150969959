import HotelPfisl from './images/Hotel-Pfisl.jpg'
import HotelStafler1 from './images/Hotel-Stafler1.png'
import HotelSensoria from './images/Hotel-Sensoria.png'
import HotelSchwarzenstein from './images/Hotel-Schwarzenstein.png'
import HotelSeeleiten from './images/Hotel-Seeleiten.png'
import HotelHohenwart from './images/Hotel-Hohenwart.png'
import HotelDolceVita from './images/Hotel-DolceVita.jpg'
import HotelLaMaiena from './images/Hotel-LaMaiena.jpg'
import HotelWeineggWellviva from './images/Hotel-Weinegg-Wellviva.jpg'
import Winklerhotels from './images/Winklerhotels.jpg'
import arrow from './images/arrow.png'
import food from './images/food.png'
import water from './images/water.png'

const hotelStafler = {
  header: 'Genussurlaub daheim',
  subHeader: '03.10.2022 – 22.12.2022',
  name: 'Romantik Hotel Stafler',
  imgUrl: HotelStafler1,
  link: 'https://short.bnamic.com/qO6Pu',
  backTitle: 'Exklusive Auszeit',
  price: 119,
  moreExpensiveRooms: true,
  attributes: [
    {
      image: water,
      text: 'Erholung in der Wellnessoase mit Schwimmbad, Saunen, Ruheraum',
    },
    {
      image: food,
      text: '6-Gänge-Menü „Mare e Monti“ in der Gasthofhofstube',
    },
    {
      image: arrow,
      text: 'Genuss-Roulette für einen Tisch in der Gasthofstube Einhorn für nur 75 €',
    },
  ],
}
const hotelPfoestl = {
  header: 'Schnuppertage im November',
  subHeader: '02.11. - 27.11.2022',
  duration: '2 Nächte',
  name: 'Naturhotel Pfösl',
  imgUrl: HotelPfisl,
  link: 'https://short.bnamic.com/VLtJC',
  backTitle: 'Schnuppertage im November',
  price: 300,
  moreExpensiveRooms: true,
  attributes: [
    {
      image: water,
      text: 'naturaspa (2 000 m²) mit Panorama-Infinity-Pool, Saunen & Ruheoasen',
    },
    {
      image: food,
      text: 'Genießerpension mit gesunden regionalen Produkten aus der Naturküche',
    },
    {
      image: arrow,
      text: 'Regeneration 360° mit Meditation, Waldbaden & Yoga',
    },
  ],
}
const hotelSensoria = {
  header: 'Slow down',
  subHeader: '29.09. – 06.11.2022',
  duration: '2 Nächte',
  name: 'Sensoria Dolomites',
  imgUrl: HotelSensoria,
  link: 'https://short.bnamic.com/obPw9',
  backTitle: 'Auszeit inkl. 10 % Rabatt ',
  price: '1.152',
  numberGuests: 2,
  moreExpensiveRooms: true,
  attributes: [
    {
      image: water,
      text: 'Eine Behandlung täglich. Welche? Sie entscheiden.',
    },
    {
      image: food,
      text: 'All-Day-Inklusiv-Arrangement und Erlebnisfrühstück bereits am Anreisetag',
    },
    {
      image: arrow,
      text: 'Luxus für die Seele im neuen Hotel in Seis ',
    },
  ],
}
const hotelSchwarzenstein = {
  header: 'Teldra Wellnäss',
  subHeader: '06.11. – 02.12.2022',
  duration: '4 Nächte zum Preis von 3',
  name: 'SPA Resort SCHWARZENSTEIN',
  imgUrl: HotelSchwarzenstein,
  link: 'https://short.bnamic.com/DA5zi',
  backTitle: 'Auszeit im Ahrntal',
  price: '618',
  moreExpensiveRooms: true,
  attributes: [
    {
      image: water,
      text: '7.700 m² großen Wellnessbereichs mit 6 Pools, 8 Saunen und SKY SPA',
    },
    {
      image: food,
      text: '¾ All-inclusive-Pension',
    },
    {
      image: arrow,
      text: 'Betreutes Wander-, Sport- und Vitalprogramm',
    },
  ],
}
const hotelSeeleiten = {
  header: 'NOVEMBER SPECIAL 4=3',
  subHeader: '13.11. – 27.11.2022',
  duration: '4 Nächte zum Preis von 3',
  name: 'Lake Spa Hotel SEELEITEN',
  imgUrl: HotelSeeleiten,
  link: 'https://short.bnamic.com/KMvxr',
  backTitle: 'Herbst am Kalterer See',
  price: '591',
  moreExpensiveRooms: true,
  attributes: [
    {
      image: water,
      text: 'Neuer Spa-Bereich mit 8 Saunen und Lake Sauna am See',
    },
    {
      image: food,
      text: '¾ Verwöhnpension',
    },
    {
      image: arrow,
      text: 'Vielseitiges Sport- und Erholungsprogramm',
    },
  ],
}
const hotelHohenwart = {
  header: 'Wohlfühltage in Schenna',
  subHeader: '04.11. – 04.12.2022',
  duration: '2 Nächte',
  name: 'Hotel Hohenwart ',
  imgUrl: HotelHohenwart,
  link: 'https://short.bnamic.com/9qBht',
  backTitle: 'Auszeit im Hotel Hohenwart',
  price: '362',
  moreExpensiveRooms: true,
  attributes: [
    {
      image: water,
      text: 'Ruhe und Entspannung im VistaSpa mit 360° Solebecken & Saunawelt',
    },
    {
      image: food,
      text: '¾-Genießerpension mit raffiniert inszenierten Gourmetkreationen',
    },
    {
      image: arrow,
      text: 'Abwechslungsreiches Aktiv- & Verwöhnprogramm',
    },
  ],
}
const hotelDolceVita = {
  header: 'Short Stay-Special',
  subHeader: '16.10.2022 – 10.12.2022',
  duration: '2 Nächte',
  name: 'DOLCE VITA HOTELS',
  imgUrl: HotelDolceVita,
  link: 'https://short.bnamic.com/kOeu8',
  backTitle: 'Maximum an Entspannung',
  price: '180',
  moreExpensiveRooms: true,
  attributes: [
    {
      image: water,
      text: 'Hotelhopping - 1 Hotel buchen, den Wellnessbereich aller 5 Hotels nutzen',
    },
    {
      image: food,
      text: 'Willkommensaperitif und ¾-Gourmetpension',
    },
    {
      image: arrow,
      text: 'Wohlfühlen in einer Suite ab mindestens 40 m²',
    },
  ],
}
const hotelLaMaiena = {
  header: 'Special Deal',
  subHeader: '06.11.2022 – 08.12.2022',
  duration: '4 Nächte',
  name: 'La Maiena Meran Resort',
  imgUrl: HotelLaMaiena,
  link: 'https://short.bnamic.com/pJzWF',
  backTitle: '25 % Rabatt',
  price: '599',
  moreExpensiveRooms: true,
  attributes: [
    {
      image: water,
      text: 'Sensa Spa Pools, Sauna & Relax (2 500 m²)',
    },
    {
      image: food,
      text: 'Gourmet Premium Halbpension mit frischen Produkten aus der Region',
    },
    {
      image: arrow,
      text: 'Geführtes Aktiv- und Wohlfühlprogramm',
    },
  ],
}
const hotelWeineggWellviva = {
  header: 'ALL (THE BEST) INCLUSIVE',
  subHeader: '06.11.2022 – 05.03.2023',
  duration: '4 Nächte',
  name: 'Weinegg Wellviva Resort',
  imgUrl: HotelWeineggWellviva,
  link: 'https://short.bnamic.com/eNM6P',
  backTitle: 'MIDWEEK SPECIAL PLUS',
  price: '848',
  moreExpensiveRooms: true,
  attributes: [
    {
      image: water,
      text: '55-Euro-Wellviva SPA-Gutschein',
    },
    {
      image: food,
      text: 'Weine aus dem hauseigenen Weingut | Tenuta Moser beim Dinner inbegriffen',
    },
    {
      image: arrow,
      text: 'Weinegg Wellviva ¾ Verwöhn-Pension',
    },
  ],
}

const winklerhotels = {
  header: 'November Special 4 = 3',
  subHeader: '06.11.2022 – 07.12.2022',
  duration: '4 Nächte',
  name: 'Winklerhotels',
  imgUrl: Winklerhotels,
  link: 'https://short.bnamic.com/XU7y9',
  backTitle: 'Premium-Auszeit gefällig?',
  price: '360',
  moreExpensiveRooms: true,
  attributes: [
    {
      image: water,
      text: 'Ruhe und Entspannung im exklusiven Premium Spa',
    },
    {
      image: food,
      text: '¾-Gourmetpension für höchsten alpin-mediterranen Genuss',
    },
    {
      image: arrow,
      text: '1 Nacht geschenkt und viele Verwöhn-Highlights',
    },
  ],
}

export default [hotelWeineggWellviva, winklerhotels, hotelStafler]
