import React from 'react'
import LayoutDefault from '../../layouts/Default'
import { Async } from 'react-async'
import NearMeScreen from './screens/NearMeScreen'
import { getStoredItem } from '../../modules/stored-item'
import fetch from 'cross-fetch'
import { clientCoordinates } from '../../modules/location'
import Config from '../../config'
import isServerSide from '../../modules/is-server-side'
import routes from '../routes'

let latitude = undefined
let longitude = undefined
let accuracy = undefined
let radius = getStoredItem('nearMeRadius') ? parseFloat(getStoredItem('nearMeRadius')) : 50

const getData = () => {
  return new Promise((resolve) => {
    clientCoordinates()
      .then((coordinates) => {
        latitude = parseFloat(coordinates.latitude).toFixed(6)
        longitude = parseFloat(coordinates.longitude).toFixed(6)
        accuracy = parseFloat(coordinates.accuracy).toFixed(6)
        if (latitude !== undefined && longitude !== undefined && accuracy !== undefined) {
          fetch(Config.restUrl(routes.generatePathByRouteName('nearMe', { latitude, longitude, radius }, 'api')))
            .then((response) => response.json())
            .then((response) => resolve({ ...response, latitude, longitude, radius }))
        } else {
          fetch(
            Config.restUrl(
              routes.generatePathByRouteName('nearMe', { latitude: '1.0', longitude: '1.0', radius }, 'api'),
            ),
          )
            .then((response) => response.json())
            .then((response) => resolve({ ...response, latitude: 1, longitude: 1, radius: radius }))
        }
      })
      .catch(() => {
        fetch(
          Config.restUrl(
            routes.generatePathByRouteName('nearMe', { latitude: '1.0', longitude: '1.0', radius }, 'api'),
          ),
        )
          .then((response) => response.json())
          .then((response) => resolve({ ...response, latitude: 1, longitude: 1, radius: radius }))
      })
  })
}

const NearMe = ({ location, initialData, onChange, ...props }) => {
  initialData = {
    meta: {
      title: 'Aktuelle Nachricht aus deiner Nähe | stol.it',
      adsTargeting: {
        STOL7_seite: 'In meiner Nähe',
      },
    },
    data: {
      loading: true,
    },
  }

  return (
    <Async promiseFn={getData} watch={location} initialValue={isServerSide() && initialData}>
      {({ data, error, isPending, isFulfilled }) => {
        let timeout = data && data.data && data.data.timeout
        const onHold = timeout || (data && data.data && data.data.loading)

        return (
          <LayoutDefault meta={isFulfilled && !onHold && data.meta} {...props}>
            <NearMeScreen
              error={error}
              loading={isPending || onHold}
              onChange={onChange}
              response={isFulfilled && !onHold && data}
              ready={isFulfilled && !onHold}
            />
          </LayoutDefault>
        )
      }}
    </Async>
  )
}

export default NearMe
