import React from 'react'
import PropTypes from 'prop-types'
import translate from '../../../../../../modules/translate'
import ErrorBoundary from '../../../../../Error/widgets/ErrorBoundary'
import MessageError from '../../../../../Error/screens/MessageError'
import { withRouter } from 'react-router'
import hasItems from '../../../../../../modules/has-items'
import { withTheme } from 'styled-components'
import loadable from '@loadable/component'
import SkeletonListVideos from './components/ListVideos/skeleton'

const ListVideos = loadable(() =>
  import(/* webpackChunkName: "ListVideos" , webpackPrefetch: true */ './components/ListVideos'),
)

const ScreenVideoOverview = ({ loading, failed, response, location, match, ...props }) => {
  const errorOptions = {
    component: translate('department'),
    link: '/',
    linkText: translate('error_try_homepage'),
    logError: 'Videos screen display',
  }
  const data = response && response.data
  const meta = response && response.meta
  if (loading) ListVideos.preload()
  return (
    <>
      <ErrorBoundary error={failed} {...errorOptions}>
        {loading ? (
          <SkeletonListVideos />
        ) : hasItems(data) ? (
          <ListVideos data={data} />
        ) : (
          <MessageError error={failed} logInfo={translate('dev_errorlog_no_data')} {...errorOptions} />
        )}
      </ErrorBoundary>
    </>
  )
}

ScreenVideoOverview.propTypes = {
  loading: PropTypes.bool,
}

export default withTheme(withRouter(ScreenVideoOverview))
