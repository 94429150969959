import React from 'react'
import SkeletonBigItemArticle from '../../../Article/components/ItemArticle/views_default/BigItemArticle/skeleton'
import SkeletonSmallLandscapeItemArticle from '../../../Article/components/ItemArticle/views_default/SmallLandscapeItemArticle/skeleton'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import TitleTopic from '../../../../components/TitleTopic'

const Wrapper = styled.div``

const WrapperInner = styled.div`
  margin-left: ${(props) => props.theme.sizes.gapEdge};
  margin-right: ${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-left: ${(props) => props.theme.tablet.sizes.gapEdge};
    margin-right: ${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-left: ${(props) => props.theme.mobile.sizes.gapEdge};
    margin-right: ${(props) => props.theme.mobile.sizes.gapEdge};
  }
  @media (max-width: 450px) {
    margin-right: 0;
    margin-left: 0;
  }
`

const WrapperSkeletonSmallLandscapeItemArticle = styled.div`
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`

const WrapperSkeletonBigItemArticle = styled.div`
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`

const SkeletonListTopic = ({ title, ...props }) => {
  let SmallLandscapeItemArticles = []
  for (let i = 0; i < 26; i++) {
    SmallLandscapeItemArticles.push(
      <WrapperSkeletonSmallLandscapeItemArticle key={i + ' WrapperSkeletonSmallLandscapeItemArticle0SkeletonTopic'}>
        <SkeletonSmallLandscapeItemArticle />
      </WrapperSkeletonSmallLandscapeItemArticle>,
    )
  }
  return (
    <Wrapper {...props}>
      {title && <TitleTopic view="screen" title={title} />}
      <WrapperInner>
        <WrapperSkeletonBigItemArticle>
          <SkeletonBigItemArticle />
        </WrapperSkeletonBigItemArticle>
        {SmallLandscapeItemArticles}
      </WrapperInner>
    </Wrapper>
  )
}

SkeletonListTopic.propTypes = {
  title: PropTypes.string,
}

export default SkeletonListTopic
