import styled from 'styled-components'
import MainWrapper from '../MainWrapper'

const StyledWrapper = styled.div`
  max-width: 1164px;
  padding-right: 14px;
  padding-left: 14px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const Wrapper = ({ children }) => (
  <MainWrapper>
    <StyledWrapper>{children}</StyledWrapper>
  </MainWrapper>
)

export default Wrapper
