import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import Title from '../../../../components/Title'
import Button from '../../../../components/Button'
import Link from '../../../../components/Link'
import translate from '../../../../modules/translate'
import ErrorBoundary from '../../../Error/widgets/ErrorBoundary'
import MessageError from '../../../Error/screens/MessageError'
import { Carousel } from '@dlab/react-materialize'
import hasItems from '../../../../modules/has-items'
import Image from '../../../../components/Image'
import isStolApp from '../../../../modules/is-stolapp'
import PropTypes from 'prop-types'
import isServerSide from '../../../../modules/is-server-side'

let materializeJS

if (!isServerSide()) {
  materializeJS = require('@dlab/react-materialize/lib/common/materialize.js')
}
//for reference visit: https://materializecss.com/carousel.html

if (!materializeJS) {
  console.error('common materializeJS failed to load')
}

//moved materialize.css here
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.background};
  border: 1px solid ${(props) => props.theme.color.backgroundBorder};
  padding-top: ${(props) => props.theme.sizes.gapVertical};
  padding-bottom: ${(props) => props.theme.sizes.gapVertical};
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  margin: ${(props) => props.margin};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-top: ${(props) => props.theme.tablet.sizes.gapVertical};
    padding-bottom: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-top: ${(props) => props.theme.mobile.sizes.gapVertical};
    padding-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
  /*
  this is a modified version of the materialize-css file.
  for reference visit: https://materializecss.com/carousel.html
  */
  .carousel {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 400px;
    -webkit-perspective: 500px;
    perspective: 500px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }

  .carousel.carousel-slider {
    top: 0;
    left: 0;
  }

  .carousel.carousel-slider .carousel-fixed-item {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    z-index: 1;
  }

  .carousel.carousel-slider .carousel-fixed-item.with-indicators {
    bottom: 68px;
  }

  .carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 400px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .carousel.carousel-slider .carousel-item h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }

  .carousel.carousel-slider .carousel-item p {
    font-size: 15px;
  }

  .carousel .carousel-item {
    visibility: hidden;
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: -60px;
    opacity: 1 !important;
  }

  .carousel .carousel-item > img {
    width: 100%;
  }

  .carousel .indicators {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
  }

  .carousel .indicators .indicator-item {
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 8px;
    width: 8px;
    margin: 24px 4px;
    background-color: rgba(255, 255, 255, 0.5);
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    border-radius: 50%;
  }

  .carousel .indicators .indicator-item.active {
    background-color: #fff;
  }

  .carousel.scrolling .carousel-item .materialboxed,
  .carousel .carousel-item:not(.active) .materialboxed {
    pointer-events: none;
  }
`
const StyledButton = styled(Button)`
  margin-top: 1em;
`

const StyledP = styled.p`
  text-decoration: none;
  text-align: center;
  color: #000;
`

const StyledImage = isStolApp() ? styled.img`` : styled(Image)``

const StyledTitle = styled(Title)`
  text-align: center;
`

const errorOptions = {
  component: translate('paper_rack_error'),
  logError: 'Rackpaper index',
}

const RackPaper = ({ data, margin, ...props }) => {
  const images = []
  if (hasItems(data)) {
    data.forEach((paper) => {
      images.push(
        <Link key={'carousel-item' + paper.url} className="carousel-item" to={paper.url}>
          <StyledImage width={200} height={300} src={paper.image} alt={paper.title} />
          <StyledP>{paper.title}</StyledP>
        </Link>,
      )
    })
  }
  return (
    <Wrapper margin={margin} {...props}>
      <StyledTitle>{translate('paper_rack')}</StyledTitle>

      <ErrorBoundary {...errorOptions}>
        {hasItems(data) ? (
          <Carousel options={{ padding: 200 }}>{images}</Carousel>
        ) : (
          <MessageError logInfo={translate('dev_errorlog_no_data')} {...errorOptions} />
        )}
      </ErrorBoundary>

      <StyledButton to="https://abo.athesiamedien.com/epaper" view="grey">
        {translate('paper_rack_button')}
      </StyledButton>
    </Wrapper>
  )
}

RackPaper.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      url: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  margin: PropTypes.string,
}

export default withTheme(RackPaper)
