import React from 'react'
import PropTypes from 'prop-types'
import LayoutDefault from '../../layouts/Default/index'
import { Async } from 'react-async'
import fetchData, { actionMETHOD } from '../../modules/fetch-data'
import ScreenVideo from './screens/ScreenVideo'
import isServerSide from '../../modules/is-server-side'

const Video = ({ initialData, match, location, view, ...props }) => {
  let routeState
  if (!isServerSide()) {
    routeState = (location && location.state) || (window && window.athesia_react && window.athesia_react.state)
  }

  return (
    <Async
      promiseFn={fetchData}
      location={location.pathname}
      watch={location}
      deferFn={actionMETHOD}
      initialValue={initialData}
    >
      {({ data, error, isPending, isFulfilled }) => {
        let timeout = data && data.data && data.data.timeout
        const pianoTargets = {}
        if (isFulfilled) {
          if (data.data.editor) pianoTargets.author = data.data.editor
          if (data.data.type) pianoTargets.native = data.data.type === 'PR'
          if (data.data.type) pianoTargets.contentType = data.data.type
          if (data.data.hasVideo) pianoTargets.hasVideo = data.data.hasVideo
          if (data.data.date) pianoTargets.contentDate = data.data.date
          pianoTargets.tags = data.tags
        }

        return (
          <LayoutDefault
            meta={isFulfilled && data.meta}
            pianoTargets={pianoTargets}
            showAds={isFulfilled && data.data && data.data.showAds}
            {...props}
          >
            <ScreenVideo
              response={isFulfilled && !timeout && data}
              loading={isPending || timeout}
              error={error}
              ready={isFulfilled && timeout}
              match={match}
              view={view}
              routeState={routeState}
            />
          </LayoutDefault>
        )
      }}
    </Async>
  )
}

Video.getInitialData = ({ req }) => {
  return fetchData({ location: req.url })
}

Video.propTypes = {
  initialData: PropTypes.shape({
    meta: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    config: PropTypes.object,
  }),
  location: PropTypes.object,
  match: PropTypes.object,
  view: PropTypes.string,
}

Video.defaultProps = {
  location: {
    pathname: undefined,
  },
}

export default Video
