import React from 'react'
import LayoutDefault from '../../layouts/Default'
import ScreenPaper from './screens/ScreenPaper'
import { Async } from 'react-async'
import fetchData from '../../modules/fetch-data'

const DataArticle = ({ initialData, match, location, ...props }) => (
  <Async promiseFn={fetchData} location={location.pathname} watch={location} initialValue={initialData}>
    {({ data, error, isPending, isFulfilled }) => {
      let timeout = data && data.data && data.data.timeout

      return (
        <LayoutDefault meta={isFulfilled && data.meta} {...props}>
          <ScreenPaper
            error={error}
            loading={isPending || timeout}
            response={isFulfilled && !timeout && data}
            {...props}
          />
        </LayoutDefault>
      )
    }}
  </Async>
)

DataArticle.getInitialData = ({ req }) => {
  return fetchData({ location: 'home' })
}

DataArticle.propTypes = {}

export default DataArticle
