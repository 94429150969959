import React from 'react'
import { hydrate, render } from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { loadableReady } from '@loadable/component'
import { CookiesProvider } from 'react-cookie'
import 'react-app-polyfill/ie9' // For IE 9-11 support
import 'react-app-polyfill/ie11' // For IE 11 supportimport cacheEmotion from './vendor/mui/cacheEmotion'
import { ThemeProvider } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import theme from './vendor/mui/themes'
import isStolApp from './modules/is-stolapp'
import { HelmetProvider } from 'react-helmet-async'
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import handlerFetch from './modules/handler-fetch'
import cacheEmotion from './vendor/mui/cacheEmotion'
import errorTracker from './modules/error-tracker'
import * as Sentry from '@sentry/browser'

const dehydratedState = window.__REACT_QUERY_STATE__
errorTracker({ Sentry, environment: 'STOLReact' })
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: handlerFetch,
      refetchOnWindowFocus: false,
      retry: false,
      retryOnMount: false,
      staleTime: Infinity,
    },
  },
})

const version = require('../package.json').version
window.athesia_react = {
  version,
  ...window.athesia_react,
}

const cache = cacheEmotion()

loadableReady().then(() => {
  const app = (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={dehydratedState}>
        <CookiesProvider>
          <BrowserRouter>
            <HelmetProvider>
              <CacheProvider value={cache}>
                <ThemeProvider theme={theme}>
                  <App />
                </ThemeProvider>
              </CacheProvider>
            </HelmetProvider>
          </BrowserRouter>
        </CookiesProvider>
      </Hydrate>
    </QueryClientProvider>
  )
  if (isStolApp()) {
    render(app, document.getElementById('root'))
  } else {
    hydrate(app, document.getElementById('root'))
  }
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

if (module.hot) {
  module.hot.accept()
}
