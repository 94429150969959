import showModalId from '../../../../../../vendor/Piano/modules/show-modal-id'
import translate from '../../../../../../modules/translate'
import React, { Fragment } from 'react'
import styled from 'styled-components'
import ContainerArticle from '../../../../../../components/ContainerArticle'
import Link from '../../../../../../components/Link'
import PropTypes from 'prop-types'
import routes from '../../../../../routes'

const Wrapper = styled(ContainerArticle)``

const StyledInfo = styled.div`
  display: block;
  flex-basis: 100%;
  font-size: ${(props) => props.theme.fontSize.small};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
  font-family: ${(props) => props.theme.font.primary};
  margin-bottom: 1em;
`

const StyledLink = styled(Link)`
  font-size: ${(props) => props.theme.fontSize.small};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
`

const LoginMessage = () => (
  <Fragment>
    <StyledLink onClick={showModalId}>{translate('ugc_add_signin1') + ' '}</StyledLink>
    {translate('ugc_add_signin2')}
  </Fragment>
)

const VerifiedMessage = () => (
  <Fragment>
    {translate('verification_message', { customMessage: '' })}
    <br />
    <StyledLink to={routes.generatePathByRouteName('account')}> {translate('user_account')}</StyledLink>
  </Fragment>
)

const NoAccessView = ({ access, verified }) => (
  <Wrapper>
    <StyledInfo>
      {!access && <LoginMessage />}
      {access && !verified && <VerifiedMessage />}
    </StyledInfo>
  </Wrapper>
)

NoAccessView.propTypes = {
  verified: PropTypes.bool,
}

export default NoAccessView
