import React, { Component, Fragment } from 'react'
import Text from '../../../../components/Text/index'
import styled, { css } from 'styled-components'
import Link from '../../../../components/Link/index'
import PropTypes from 'prop-types'
import translate from '../../../../modules/translate/index'
import ContainerArticle from '../../../../components/ContainerArticle/index'
import { bottomLineAfter } from '../../../../modules/mixins'
import errorLog from '../../../../modules/error-log'
import isStolApp from '../../../../modules/is-stolapp'
import isDebug from '../../../../modules/is-debug'
import { getStoredItem } from '../../../../modules/stored-item'
import isServerSide from '../../../../modules/is-server-side'

const Wrapper = styled(ContainerArticle)`
  flex: 2;
  ${bottomLineAfter}
`

const Message = styled(Text)`
  font-size: ${(props) => props.theme.fontSize.title3};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.title3};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.title3};
  }
  padding: 3em 0;
  ${(props) =>
    props.inverted
      ? css`
          color: ${(props) => props.theme.color.textInverted};
        `
      : css`
          color: ${(props) => props.theme.color.textPrimary};
        `}
`

const StyledLink = styled(Link)`
  font-size: ${(props) => props.theme.fontSize.title3};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.title3};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.title3};
  }
`

const StyledText = styled(Text)`
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
`

const ErrorCode = styled(StyledText)`
  color: ${(props) => props.theme.color.textSecondary};
  margin-bottom: 1.5em;
`
//const LogInfo = styled.h1``
//const LogError = styled.p``
class MessageError extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorStatus: undefined,
    }
  }
  render() {
    const { text, link, linkText, component, logError, logInfo, inverted, error, ...props } = this.props
    if (logError || logInfo) errorLog({ error: logError, info: logInfo })
    if (error) {
      if (this.state.errorStatus === undefined && error && error.response) {
        this.setState({
          errorStatus: error.response.status,
        })
      }
    }
    if (component !== undefined || text !== undefined) {
      const dlabSettingsShowErrors = getStoredItem('dlabShowErrors', true) === 'true'
      return (
        <Wrapper {...props}>
          {(component || text) && (
            <Message tag="div" inverted={inverted}>
              {text}
              {component && translate('error_component', { name: component })}
              <br />
              {!isStolApp && linkText && <StyledLink to={link}> {linkText} </StyledLink>}
              {this.state.errorStatus && (
                <ErrorCode>{translate('error_not_found_code') + ' ' + this.state.errorStatus}</ErrorCode>
              )}
              {(process.env.NODE_ENV === 'development' || isDebug() || dlabSettingsShowErrors || isServerSide()) && (
                <React.Fragment>
                  <h1>{JSON.stringify(logError)}</h1>
                  <p>{JSON.stringify(logInfo)}</p>
                </React.Fragment>
              )}
            </Message>
          )}
        </Wrapper>
      )
    } else {
      errorLog({ error: logError, info: logInfo, type: 'message' })
      return <Fragment />
    }
  }
}

MessageError.propTypes = {
  text: PropTypes.string,
  component: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  inverted: PropTypes.bool,
  logError: errorLog.propTypes.error,
  logInfo: errorLog.propTypes.info,
}

MessageError.defaultProps = {
  link: '/',
  logError: 'MessageError',
}

export default MessageError
