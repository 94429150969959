import styled from 'styled-components'

const MainWrapper = styled.main`
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  button {
    cursor: pointer;
    border: none;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  display: flex;
  justify-content: center;
  width: 100%;
  background: #f5f5f5;
`

export default MainWrapper
