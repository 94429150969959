import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import skeletonFade from '../../../modules/mixins/skeleton-fade'
import JobElement from '../../../components/JobElement/skeleton'

const Wrapper = styled.div``
const SkeletonSearchBar = styled.div`
  height: 120px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  ${skeletonFade};
  background-color: lightgrey;
  margin-top: 10px;
`

const SkeletonJobBlock = styled.div`
  height: 150px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  ${skeletonFade};
  background-color: lightgrey;

  margin-bottom: 5px;
`

const SkeletonJobWIdgetPage = ({ ...props }) => (
  <Wrapper {...props}>
    <JobElement></JobElement>
    <JobElement></JobElement>
    <JobElement></JobElement>
    <JobElement></JobElement>
    <JobElement></JobElement>
    <JobElement></JobElement>
    <JobElement></JobElement>
  </Wrapper>
)
SkeletonJobWIdgetPage.propTypes = {}
SkeletonJobWIdgetPage.defaultProps = {}
export default SkeletonJobWIdgetPage
