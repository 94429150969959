import sha1 from 'crypto-js/sha1'
import sha256 from 'crypto-js/sha256'
import md5 from 'crypto-js/md5'
import { getCookieByName, setCookie } from '../../../../../../modules/modify-cookies'
import consoleLogger from '../../../../../../modules/console-logger'
import handlerWindowDlab from '../../../../modules/handler-window-dlab'

const liveRamp = ({ config }) => {
  const [consoleLog] = consoleLogger('AdsLog')
  const { dlabOption } = handlerWindowDlab('prebid', { sub: 'liveRamp' })

  if (!config || !config?.cookieName) {
    return
  }
  if (dlabOption['isInitiated']) {
    return
  }

  // FOR DEBUG:
  //setCookie('stnews-user', '{"email":"philipp.sorio@abc.it"}')

  const cookieContent = getCookieByName(config.cookieName)
  consoleLog('PREBID Liveramp', 'Looking for cookie ' + config.cookieName, 'Ads')

  if (!cookieContent) {
    consoleLog('PREBID Liveramp', 'Cookie ' + config.cookieName + ' empty or not found!', 'Ads')
    return
  }

  let email = ''
  if (config.cookieObjectProperty) {
    email = JSON.parse(cookieContent)[config.cookieObjectProperty]
  } else {
    email = cookieContent
  }
  consoleLog('PREBID Liveramp', 'Cookie ' + config.cookieName + ' found. Creating envelope! - ' + email, 'Ads')

  window.addEventListener('envelopeModuleReady', function (event) {
    consoleLog('PREBID Liveramp - event', event, 'Ads')
    atsenvelopemodule.setAdditionalData({
      type: 'emailHashes',
      id: [sha1(email).toString(), sha256(email).toString(), md5(email).toString()],
    })
  })
  dlabOption['isInitiated'] = true
}

export default liveRamp
