import React from 'react'
import PropTypes from 'prop-types'
import ContainerFull from '../../components/ContainerFull'
import styled, { css, withTheme } from 'styled-components'
import AdUnit from '../../components/AdUnit'
import BannerBlock from '../../layouts/Blocks/components/BannerBlock'
import TitleBlock from '../../layouts/Blocks/components/TitleBlock'
import generateStylingString from '../../modules/generate-styling-string/index.php'
import consoleLogger from '../../modules/console-logger'
import ErrorBoundary from '../../topics/Error/widgets/ErrorBoundary'
import createValidRelativeUrl from '../../modules/create-valid-relative-url'

const [consoleLog] = consoleLogger('Styling')

const WrapperContainer = styled(ContainerFull)`
  ${({ margin, pr, theme }) => {
    let cssString = ''

    if (margin && typeof margin === 'object') {
      cssString += generateStylingString(margin, theme)
    }

    if (margin && typeof margin !== 'object') cssString += `margin: ${margin};`
    if (pr) cssString += `background-color: ${theme.color.pr};`

    consoleLog('Styling', 'Custom CSS:', cssString)

    return css`
      ${cssString}
    `
  }}
`

const BlockBuilder = ({
  view,
  loading = false,
  pr,
  title,
  titleComponent,
  adIds,
  data,
  className,
  margin,
  noGapEdge,
  autoHide,
  noLazyLoading,
  blocks,
  ...props
}) => {
  if (!(view in blocks)) {
    return false
  }

  const blockElement = blocks[view](props)
  const BlockComponent = loading ? blockElement.skeleton : blockElement.component
  if (autoHide === undefined) autoHide = blockElement.autoHide
  if (pr === undefined) pr = blockElement.pr
  if (margin === undefined) margin = blockElement.margin
  if (!BlockComponent || (autoHide && !data)) {
    return false
  }

  const cinemaColor =
    (data && data['background-color']) || (loading && props.dataIds && props.dataIds.includes('specialbox1'))

  const hasBannerImages = data && data.imageSmall && data.imageLarge
  const isLoadingSpecialBox1 = loading && props.dataIds && props.dataIds.includes('specialbox1')
  return (
    <ErrorBoundary info={`BlockBuilder ${view}`}>
      <WrapperContainer
        id={'ContainerFull'}
        view={view}
        className={className}
        cinemaMode={
          cinemaColor && {
            backgroundPrimary: cinemaColor,
            backgroundSecondary: '#ffffff',
          }
        }
        pr={!cinemaColor && pr}
        margin={margin}
        noGapEdge={noGapEdge ? noGapEdge : blockElement.noGapEdge}
        {...props}
      >
        <TitleBlock title={title} />
        {titleComponent}
        {(hasBannerImages || isLoadingSpecialBox1) && (
          <BannerBlock
            imageSmall={data && data.imageSmall}
            imageLarge={data && data.imageLarge}
            imageHeight={data && data.imageHeight}
            url={createValidRelativeUrl(data?.url)}
            loading={loading}
            {...props}
          />
        )}
        {!loading && (
          <BlockComponent
            blocks={blocks}
            data={data}
            adIds={adIds}
            loading={loading}
            pr={!cinemaColor && pr}
            noLazyLoading={noLazyLoading}
            {...props}
          />
        )}
        {loading && (
          <BlockComponent
            blocks={blocks}
            data={data}
            adIds={adIds}
            loading={loading}
            pr={!cinemaColor && pr}
            noLazyLoading={noLazyLoading}
            {...props}
          />
        )}
      </WrapperContainer>
    </ErrorBoundary>
  )
}

BlockBuilder.propTypes = {
  pr: PropTypes.bool,
  title: PropTypes.node,
  adIds: PropTypes.arrayOf(AdUnit.propTypes.adId),
  autoHide: PropTypes.bool,
}

BlockBuilder.defaultProps = {
  view: 'big_article',
}

export default withTheme(BlockBuilder)
