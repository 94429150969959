import React from 'react'
import styled from 'styled-components'
import translate from '../../../../modules/translate'
import { WrapperText0DescriptionItemArticle } from '../../components/ItemArticle/components/DescriptionItemArticle'
import ListWidgetNakedLineArticle, {
  ItemList0ListWidgetNakedLineArticle,
} from '../WidgetNakedLineArticle/components/ListWidgetNakedLineArticle'
import TabsWidgetNakedLineArticle from '../WidgetNakedLineArticle/components/TabsWidgetNakedLineArticle'
import WrapperWidgetNakeLineArticle from '../WidgetNakedLineArticle/components/WrapperWidgetNakedLineArticle'
import TitleWidgetNakedLineArticle from '../WidgetNakedLineArticle/components/TitleWidgetNakedLineArticle'

const Wrapper = styled(WrapperWidgetNakeLineArticle)`
  padding: 25px ${(props) => props.theme.sizes.gapEdge} 0px 34px;
  border: none;

  ${WrapperText0DescriptionItemArticle} {
    font-size: ${(props) => props.theme.fontSize.small};
  }

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding: 25px ${(props) => props.theme.tablet.sizes.gapEdge};
    padding-bottom: 0px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding: 16px calc(${(props) => props.theme.mobile.sizes.gapEdge} / 2);
    padding-bottom: 0px;

    ${WrapperText0DescriptionItemArticle} {
      display: block;
    }
  }
`

const StyledTitle = styled(TitleWidgetNakedLineArticle)`
  text-align: center;
  background-color: ${(props) => props.theme.color.background};
  margin-top: 0px;
  margin-bottom: 25px;

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: 16px;
  }
`

const StyledListWidgetNakedLineArticle = styled(ListWidgetNakedLineArticle)`
  margin-top: 25px;

  ${ItemList0ListWidgetNakedLineArticle} {
    height: 90px;
  }

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: 25px;
  }

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: 12px;
    padding-left: 0;
    padding-right: 0;
  }
`

const WidgetMostRead = ({ articles, tabs, ...props }) => (
  <Wrapper {...props}>
    <StyledTitle tag={'div'}>{translate('article_most_read')}</StyledTitle>
    <TabsWidgetNakedLineArticle articles={articles} numbering={true} textTag="div" />
  </Wrapper>
)

WidgetMostRead.propTypes = {}

export default WidgetMostRead
export {
  Wrapper as Wrapper0WidgetMostRead,
  StyledListWidgetNakedLineArticle as StyledListWidgetNakedLineArticle0WidgetMostRead,
}
