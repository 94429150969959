import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import React from 'react'
import { setStoredItem, getStoredItem } from '../../../../modules/stored-item'
import styled from 'styled-components'

const SaveLoadComponent = ({ settings }) => {
  const FormLineDiv = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
  `

  const [checked, setChecked] = React.useState(String(getStoredItem(settings.valueKey, true)) === 'true')
  const [content, setContent] = React.useState(
    String(getStoredItem(settings.valueKey, true)) != 'undefined' ? getStoredItem(settings.valueKey, true) : '',
  )

  let ControlComponent = null

  switch (settings.type) {
    case 'textarea': {
      const handleChange = (event) => {
        setStoredItem(settings.valueKey, event.target.value, true)
        setContent(event.target.value)
      }

      ControlComponent = () => (
        <TextField
          placeholder={settings.placeholder ? settings.placeholder : settings.label}
          onChange={handleChange}
          value={content}
        />
      )
      break
    }
    case 'switch':
    default: {
      const handleChange = (event, newValue) => {
        setStoredItem(settings.valueKey, newValue, true)
        setChecked(newValue)
      }

      ControlComponent = () => (
        <Switch
          checked={checked}
          onChange={handleChange}
          name={settings.valueKey}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      )
      break
    }
  }

  return (
    <FormLineDiv>
      <div>{settings.label}</div>

      <ControlComponent />
    </FormLineDiv>
  )
}

export default SaveLoadComponent
