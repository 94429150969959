import React, { useState } from 'react'
import styled from 'styled-components'
import JobLoadError from '../../../JobWidgetPageScreen/components/JobLoadError'
import JobLoadMore from '../JobLoadMore'
import JobElement from '../../../../../components/JobElement'

const Wrapper = styled.div``
const JobList = ({ data, url, page, ...props }) => {
  const [stateData, setStateData] = useState({
    data: data.data,
  })

  return (
    <Wrapper {...props}>
      {stateData && stateData.data && stateData.data.length == 0 && <JobLoadError noResult={true}></JobLoadError>}
      {stateData &&
        stateData.data &&
        stateData.data.length > 0 &&
        stateData.data.map((job) => {
          let location = ''
          let partner = ''
          let type = ''
          let sectors = []
          if (job && job.properties && job.properties.data) {
            job.properties.data.map((data) => {
              if (data.facet && data.facet.localeCompare('jobads-district') === 0) {
                location = data.name
              }
              if (data.facet && data.facet.localeCompare('jobads-origin') === 0) {
                partner = data.name
              }
              if (data.facet && data.facet.localeCompare('jobads-employmenttype') === 0) {
                type = data.name
              }
            })
          }
          let city = job && job.city ? job.city : location ? location : ''

          if (job && job.types && job.types.data && job.types.data.length > 0) {
            job.types.data.map((data) => {
              sectors.push(data.name)
            })
          }

          let elementData = {
            id: job && job.id ? job.id : '0',
            link: job && job.deeplink ? job.deeplink : '',
            logo: job && job.logo && job.logo.data && job.logo.data.url ? job.logo.data.url : null,
            image: job && job.picture && job.picture.data && job.picture.data.url ? job.picture.data.url : null,
            employer_name: job && job.company_name ? job.company_name : '',
            video: job && job.video && job.video.data ? job.video.data.url : '',
            type: type ? type : '',
            title: job && job.title ? job.title : '',
            location: city,
            partner: partner ? partner : '',
            date: job && job.published_at ? job.published_at : '',
            sectors: sectors,
          }
          return (
            <JobElement key={elementData.id} data={elementData} isWidget={false}>
              {' '}
            </JobElement>
          )
        })}
      {data &&
        data.data &&
        data.data.length != 0 &&
        data.meta?.pagination?.current_page !== data.meta?.pagination?.total_pages && (
          <JobLoadMore
            url={url}
            page={page}
            afterloadmore={(data) => {
              setStateData({ data: stateData.data.concat(data.data) })
            }}
          />
        )}
    </Wrapper>
  )
}
JobList.propTypes = {}
JobList.defaultProps = {}
export default JobList
