import stnConfig from './german-pages/stn-config'
import stolConfig from './german-pages/stol-config'
import trauerConfig from './german-pages/trauer-config'
import spnConfig from './german-pages/spn-config'
import secondHandConfig from './german-pages/second-hand-config'
import wattenOrgConfig from './german-pages/watten-org-config'
import fubasConfig from './german-pages/fubas-config'
import altoAdigeConfig from './italian-pages/alto-adige-config'
import ladigeConfig from './italian-pages/ladige-config'
import trentinoConfig from './italian-pages/trentino-config'
import dummyConfig from './dummy'
import getParameterByName from '../modules/get-parameter-by-name'
import consoleLogger from '../../../modules/console-logger'
const [consoleLog] = consoleLogger('Ads')

const index = () => {
  const allConfigFiles = {
    secondHandConfig,
    spnConfig,
    stnConfig,
    stolConfig,
    trauerConfig,
    wattenOrgConfig,
    altoAdigeConfig,
    ladigeConfig,
    trentinoConfig,
    dummyConfig,
    fubasConfig,
  }
  if (typeof document !== 'undefined') {
    if (getParameterByName('testConfigFile') && getParameterByName('testConfigFile') !== undefined) {
      let wantedTestConfigFile = getParameterByName('testConfigFile')
      consoleLog(
        `Test Config File`,
        'Wanted ' + wantedTestConfigFile + ' - Found? ' + (allConfigFiles[wantedTestConfigFile] != null),
      )
      if (allConfigFiles[wantedTestConfigFile]) {
        return allConfigFiles[wantedTestConfigFile]
      }
    }

    const hostName = window.location.hostname
    if (hostName.includes('suedtirolnews.it')) {
      consoleLog(`Config File`, 'Using ' + hostName)
      return stnConfig
    }
    if (hostName.includes('stol.it')) {
      return stolConfig
    }
    if (hostName.includes('trauer.bz')) {
      consoleLog(`Config File`, 'Using ' + hostName)
      return trauerConfig
    }
    if (hostName.includes('sportnews.bz')) {
      consoleLog(`Config File`, 'Using ' + hostName)
      return spnConfig
    }
    if (hostName.includes('second-hand.it')) {
      consoleLog(`Config File`, 'Using ' + hostName)
      return secondHandConfig
    }
    if (hostName.includes('watten.org')) {
      consoleLog(`Config File`, 'Using ' + hostName)
      return wattenOrgConfig
    }
    if (hostName.includes('fubas.it') || hostName.includes('azurewebsites.net')) {
      consoleLog(`Config File`, 'Using ' + hostName)
      return fubasConfig
    }

    if (hostName.includes('ladige.it')) {
      consoleLog(`Config File`, 'Using ' + hostName)
      return ladigeConfig
    }
    if (hostName.includes('altoadige.it')) {
      consoleLog(`Config File`, 'Using ' + hostName)
      return altoAdigeConfig
    }
    if (hostName.includes('giornaletrentino.it')) {
      consoleLog(`Config File`, 'Using ' + hostName)
      return trentinoConfig
    }

    consoleLog(`Config File`, 'No adfile for hostname found')
    return false
  }
}

export default index
