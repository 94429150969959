import isServerSide from '../../../../modules/is-server-side'
import options from '../../../../config/options'

const testPartnerFilter = () => {
  if (!isServerSide() && window.localStorage.getItem('dlabJobsOrigin')) {
    const partnerName = window.localStorage.getItem('dlabJobsOrigin')

    const partner = options.Jobs.partner.find((partner) => partner.name.includes(partnerName))
    if (partner) {
      return `&filter[jobads-origin]=${partner.id}`
    }
    return ''
  }
  return ''
}

export default testPartnerFilter
