import React from 'react'
import ErrorBoundary from '../../../Error/widgets/ErrorBoundary'
import MessageError from '../../../Error/screens/MessageError'
import SkeletonBookmarks from '../../components/BookmarkResult/skeleton'
import translate from '../../../../modules/translate'
import loadable from '@loadable/component'

const BookmarksResult = loadable(() =>
  import(/* webpackChunkName: "BookmarksResult" ,  webpackPrefetch: true */ '../../components/BookmarkResult'),
)

const errorOptions = {
  component: translate('bookmark_error'),
  link: '/',
  linkText: translate('error_try_homepage'),
  logError: 'Bookmark screen display',
}

const BookmarkScreen = ({ response: { data, meta }, title, run, error, loading, ready, ...props }) => {
  if (loading) BookmarksResult.preload()

  return (
    <ErrorBoundary {...errorOptions} {...props}>
      {error && <MessageError logInfo={error.message} {...errorOptions} />}
      {loading && <SkeletonBookmarks meta={meta} />}
      {data && <BookmarksResult data={data} meta={meta} run={run} />}
    </ErrorBoundary>
  )
}
BookmarkScreen.propTypes = {}
BookmarkScreen.defaultProps = {}
export default BookmarkScreen
