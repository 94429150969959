import React from 'react'
import BigItemArticle from './views_default/BigItemArticle'
import SmallLandscapeItemArticle from './views_default/SmallLandscapeItemArticle'
import SmallPortraitItemArticle from './views_default/SmallPortraitItemArticle'
import LeaderItemArticle from './views_leader/LeaderItemArticle'
import BigLeaderItemArticle from './views_leader/BigLeaderItemArticle'
import TopTopicItemArticle from './views_toptopic/TopTopicItemArticle'
import PropTypes from 'prop-types'
import NakedItemArticle from './views/NakedItemArticle'
import BigVideoItemArticle from './views_video/BigVideoItemArticle'
import VideoItemArticle from './views_video/VideoItemArticle'
import DescriptionInVideoItemArticle from './views_video/DescriptionInVideoItemArticle'
import LineItemArticle from './views_line/LineItemArticle'
import options from '../../../../config/options'

let TagName = NakedItemArticle
const components = {
  big: BigItemArticle,
  big_video: BigVideoItemArticle,
  description_in_video: DescriptionInVideoItemArticle,
  small_landscape: SmallLandscapeItemArticle,
  small_portrait: SmallPortraitItemArticle,
  leader: LeaderItemArticle,
  big_leader: BigLeaderItemArticle,
  top_topic: TopTopicItemArticle,
  video: VideoItemArticle,
  line: LineItemArticle,
}

const ItemArticle = ({
  view,
  hide,
  hideDefaults,
  noTo,
  article: {
    position,
    url,
    image,
    breadcrumb,
    plus,
    title,
    description,
    type,
    local,
    partner,
    partnerUrl,
    video,
    fixed,
    hasVideo,
    date,
    track,
    adsTargeting,
    articleLayout,
  },
  noLazyLoading,
  ...props
}) => {
  TagName = components[view]
  let customHeadline = breadcrumb ? breadcrumb.headline : props.breadcrumb.headline
  let customDepartment = breadcrumb ? breadcrumb.department : props.breadcrumb.department
  let customText = typeof description === 'string' && description
  let customTo = {
    pathname: url,
    state: {
      title: title,
      headline: customHeadline,
      department: customDepartment,
      text: customText,
      image: image,
      plus: plus,
      date: date,
      video: video,
      partner: partner,
      adsTargeting: adsTargeting,
    },
  }

  if (hideDefaults) hide = hide.concat(options.Article.item.hiddenElements)

  return (
    <TagName
      {...props}
      url={url}
      image={image}
      breadcrumb={breadcrumb}
      department={customDepartment}
      headline={customHeadline}
      title={title}
      description={!hide.includes('description') && description}
      type={type}
      plus={plus}
      local={local}
      partner={partner}
      partnerUrl={partnerUrl}
      data-id={position}
      video={video}
      hasVideo={hasVideo}
      fixed={fixed}
      date={!hide.includes('date') && date}
      track={track}
      adsTargeting={adsTargeting}
      to={customTo}
      articleLayout={articleLayout}
      noLazyLoading={noLazyLoading}
      noTo={noTo}
    />
  )
}

ItemArticle.propTypes = {
  view: PropTypes.oneOf(Object.keys(components)).isRequired,
  /**
   * Hide in array given possible elements?
   **/
  hide: PropTypes.arrayOf(PropTypes.oneOf(['description', 'date'])),
  /**
   * Should the default Elements from the *options.json* be hidden?
   **/
  hideDefaults: PropTypes.bool,
  article: PropTypes.shape({
    ...NakedItemArticle.propTypes,
    ...TagName.propTypes,
  }),
}

ItemArticle.defaultProps = {
  view: 'big',
  hide: [],
  hideDefaults: true,
  article: {
    ...NakedItemArticle.defaultProps,
  },
}

export default ItemArticle
