import React, { Fragment } from 'react'
import SkeletonBigItemArticle from '../../../../../../../Article/components/ItemArticle/views_default/BigItemArticle/skeleton'
import SkeletonSmallLandscapeItemArticle from '../../../../../../../Article/components/ItemArticle/views_default/SmallLandscapeItemArticle/skeleton'
import styled from 'styled-components'
import Tabs from '../../../../../../../../components/Tabs'
import TabsItem from '../../../../../../../../components/TabsItem'
import translate from '../../../../../../../../modules/translate'
import TitleTopic from '../../../../../../../../components/TitleTopic'

const WrapperInner = styled.div`
  margin-left: ${(props) => props.theme.sizes.gapEdge};
  margin-right: ${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-left: ${(props) => props.theme.tablet.sizes.gapEdge};
    margin-right: ${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-left: ${(props) => props.theme.mobile.sizes.gapEdge};
    margin-right: ${(props) => props.theme.mobile.sizes.gapEdge};
  }
  @media (max-width: 450px) {
    margin-right: 0;
    margin-left: 0;
  }
`

const WrapperSkeletonSmallLandscapeItemArticle = styled.div`
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`

const WrapperSkeletonBigItemArticle = styled.div`
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`

const StyledTabsItem = styled(TabsItem)`
  flex: 1;
`

const SkeletonListVideos = ({ title, ...props }) => {
  let SmallLandscapeItemArticles = []
  for (let i = 0; i < 26; i++) {
    SmallLandscapeItemArticles.push(
      <WrapperSkeletonSmallLandscapeItemArticle
        key={i + ' WrapperSkeletonSmallLandscapeItemArticle0SkeletonListVideos'}
      >
        <SkeletonSmallLandscapeItemArticle />
      </WrapperSkeletonSmallLandscapeItemArticle>,
    )
  }
  return (
    <Fragment>
      <TitleTopic view="screen" title={title} />
      <Tabs indicatorColor="secondary">
        <StyledTabsItem label={translate('category_all')} value="" />
        <StyledTabsItem label={translate('category_local')} value="" />
        <StyledTabsItem label={translate('category_global')} value="" />
      </Tabs>
      <WrapperInner>
        <WrapperSkeletonBigItemArticle>
          <SkeletonBigItemArticle />
        </WrapperSkeletonBigItemArticle>
        {SmallLandscapeItemArticles}
      </WrapperInner>
    </Fragment>
  )
}

export default SkeletonListVideos
