import React from 'react'
import { Async } from 'react-async'
import fetchData from '../../modules/fetch-data'
import LayoutDefault from '../../layouts/Default'
import ScreenTopic from './screens/ScreenTopic'
import PropTypes from 'prop-types'
import routes from '../routes'

const Topic = ({ initialData, match, location, view, ...props }) => {
  const apiUrl = match && view && routes.generatePathByRouteName(view, { slug: match.params.slug }, 'api')
  return (
    <Async promiseFn={fetchData} location={apiUrl} watch={location} initialValue={initialData}>
      {({ data, error, isPending, isFulfilled }) => {
        let timeout = data && data.data && data.data.timeout

        return (
          <LayoutDefault meta={isFulfilled && data.meta} {...props}>
            <ScreenTopic
              response={isFulfilled && !timeout && data}
              loading={isPending || timeout}
              error={error}
              ready={isFulfilled && timeout}
              match={match}
              apiUrl={apiUrl}
            />
          </LayoutDefault>
        )
      }}
    </Async>
  )
}

Topic.getInitialData = ({ req }) => {
  return fetchData({ location: req.url })
}

Topic.propTypes = {
  initialData: PropTypes.shape({
    meta: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    config: PropTypes.object,
  }),
  location: PropTypes.object,
  match: PropTypes.object,
}

Topic.defaultProps = {
  location: {
    pathname: undefined,
  },
}

export default Topic
