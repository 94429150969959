import { useRef, useState } from 'react'
import { EmailShareButton, FacebookShareButton, WhatsappShareButton } from 'react-share'

import T from 'prop-types'

import useOutsideClick from './modules/use-outside-click'
import copy from './images/Copy.svg'
import email from './images/Email.svg'
import facebook from './images/Facebook.svg'
import whatsApp from './images/WhatsApp.svg'
import arrowSvg from './images/arrow.svg'
import locationSvg from './images/mvpLocation.svg'
import {
  HeroArrowIcon24X24,
  HeroBottomDescription,
  HeroBottomDescriptionContainer,
  HeroBottomSpan,
  HeroButton,
  HeroContainer,
  HeroContentContainer,
  HeroDescription,
  HeroFlag,
  HeroFlagContainer,
  HeroFlagIcon24X24,
  HeroFlagSubTitle,
  HeroFlagTitle,
  HeroImageWrapper,
  HeroLabelContainer,
  HeroSwitcher,
  HeroSwitcherDropdown,
  HeroSwitcherLink,
  HeroTitle,
  Icon30X30,
  SocialBottomContainer,
  Wrapper,
} from './styled'
import uniqueId from 'lodash/uniqueId'
import isServerSide from '../../../../modules/is-server-side'
import { htmlParser } from '../../../../modules/parse'

const Header = ({ data }) => {
  const isDe = data.lng === 'DE'
  const [open, setOpen] = useState(false)
  const wrapperRef = useRef(null)

  const handleCopyLink = () => {
    const link = (window && window?.location?.href) || ''
    navigator && navigator?.clipboard?.writeText(link)
  }

  const handleToggle = () => {
    setOpen((prev) => !prev)
  }
  const handleClose = () => {
    setOpen(false)
  }

  useOutsideClick(wrapperRef, handleClose)

  const sharingUrl = (!isServerSide() && window && window?.location?.href) || ''

  const buttonStyle = {
    alignItems: 'center',
    display: 'flex',
    background: 'transparent',
    maxWidth: '38px',
    padding: 0,
    marginRight: '16px',
  }

  const onLinkClick = () => {
    console.log('click')
    document.getElementById('offerWrapper').scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    <Wrapper>
      <HeroImageWrapper>
        <HeroContainer>
          <HeroLabelContainer>
            <HeroFlag>
              <HeroFlagTitle>{data.label.title}</HeroFlagTitle>
              <HeroFlagContainer>
                <HeroFlagIcon24X24 src={locationSvg} alt={'location-icon'} />
                <HeroFlagSubTitle>{data.label.subtitle}</HeroFlagSubTitle>
              </HeroFlagContainer>
            </HeroFlag>
            <HeroSwitcher open={open}>
              <button onClick={handleToggle}>
                <p>{data.lng}</p>
                <HeroArrowIcon24X24 src={arrowSvg} alt={'arrow'} />
              </button>
              {open && (
                <HeroSwitcherDropdown className={'dropdown-content'} ref={wrapperRef}>
                  <HeroSwitcherLink href={isDe ? '/giornata-dello-slittino' : '/rodeltag'}>
                    <span> {isDe ? 'IT' : 'DE'}</span>
                  </HeroSwitcherLink>
                </HeroSwitcherDropdown>
              )}
            </HeroSwitcher>
          </HeroLabelContainer>
          <HeroContentContainer lng={data.lng}>
            <HeroTitle lng={data.lng}>{htmlParser(data.title)}</HeroTitle>
            {data.description.map((el) => (
              <HeroDescription key={uniqueId()}>{el}</HeroDescription>
            ))}
          </HeroContentContainer>
          <HeroButton onClick={onLinkClick} lng={data.lng}>
            {data.button}
          </HeroButton>
          <HeroBottomDescriptionContainer>
            <HeroBottomDescription>
              {data.bottomDescription02}
              <HeroBottomSpan id="sleedingParticipants">---</HeroBottomSpan>
              {data.bottomDescription03}
            </HeroBottomDescription>
          </HeroBottomDescriptionContainer>
          <SocialBottomContainer lng={data.lng}>
            <WhatsappShareButton
              className={'share-button-link'}
              style={buttonStyle}
              title={data.subject}
              separator={' , '}
              url={sharingUrl}
            >
              <Icon30X30 src={whatsApp} alt={'whatsApp'} />
            </WhatsappShareButton>
            <FacebookShareButton
              className={'share-button-link'}
              quote={data.subject}
              hashtag={'#parking'}
              style={buttonStyle}
              url={sharingUrl}
            >
              <Icon30X30 src={facebook} alt={'facebook'} />
            </FacebookShareButton>
            <EmailShareButton
              style={buttonStyle}
              url={sharingUrl}
              subject={data.subject}
              className={'share-button-link'}
              separator={' , '}
            >
              <Icon30X30 src={email} alt={'email'} />
            </EmailShareButton>
            <button style={buttonStyle} onClick={handleCopyLink}>
              <Icon30X30 src={copy} alt={'copy'} />
            </button>
          </SocialBottomContainer>
        </HeroContainer>
      </HeroImageWrapper>
    </Wrapper>
  )
}

Header.propTypes = {
  data: T.shape({
    lng: T.string,
    label: T.shape({
      title: T.string,
      subtitle: T.string,
    }),
    title: T.string,
    description: T.array,
    button: T.string,
  }),
}

export default Header
