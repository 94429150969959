import React from 'react'
import PropTypes from 'prop-types'
import { Async } from 'react-async'
import fetchData from '../../modules/fetch-data'
import ErrorBoundary from '../Error/widgets/ErrorBoundary'
import translate from '../../modules/translate'
import ScreenWeather from './screens/ScreenWeather'
import LayoutDefault from '../../layouts/Default'

const errorOptions = {
  component: translate('weather_error'), //TODO show proper error
  logError: 'Weather screen display',
}

const Weather = ({ initialData, location, ...props }) => (
  <Async promiseFn={fetchData} location={location.pathname} watch={location} initialValue={initialData}>
    {({ data, error, isPending, isFulfilled }) => {
      let timeout= data && data.data && data.data.timeout

      return(
      <LayoutDefault meta={isFulfilled && data.meta} {...props}>
        <ErrorBoundary {...errorOptions}>
          <ScreenWeather failed={error} loading={isPending || timeout} response={isFulfilled && !timeout && data} location={location} />
        </ErrorBoundary>
      </LayoutDefault>
    )}}
  </Async>
)

Weather.getInitialData = ({ req }) => {
  return fetchData({ location: req.url })
}

Weather.propTypes = {
  initialData: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

export default Weather
