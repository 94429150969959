// For full documentation see
// https://github.com/eBayClassifiedsGroup/react-advertising/wiki/Configuration#size-mappings

import settingsSTOL from '../../../../topics/DlabSettings/settings'
import handlerMediaqueryTag from '../../../../modules/handler-mediaquery-tag'

const viewports = {
  desktop: [1381, 0],
  desktop_s: [1081, 0],
  tablet: [970, 0],
  tablet_s: [862, 0],
  mobile_l: [791, 0],
  mobile: [768, 0],
  mobile_m: [480, 0],
  mobile_s: [320, 0],
  all: [0, 0],
}

const { create: createMediaQueryTag } = handlerMediaqueryTag({ viewports })

const sizes = {
  button: [300, 100],
  button_s: [300, 50],
  buttonAds: [320, 100],
  buttonAds_s: [320, 50],
  sponsoredBy: [170, 100],
  sponsoredBy_big: [285, 75],
  hotelWidget_s: [320, 615],
  hotelWidget_m: [480, 800],
  hotelWidget_l: [780, 515],
  hotelWidget_xl: [980, 560],
  hotelWidgetSolo_s: [320, 550],
  hotelWidgetSolo_m: [360, 500],
  hotelWidgetSolo_l: [780, 370],
  hotelWidgetSolo_xl: [970, 370],
  fullSize: [468, 60],
  largeLeaderBoard: [970, 90],
  largeLeaderBoard_s: [728, 90],
  rectangle: [300, 250],
  billboard: [970, 250],
  billboard_s: [728, 200],
  superBanner: [970, 150],
  superBanner_s: [728, 150],
  sky: [300, 600],
  sky_s: [160, 600],
  wallpaper: [1920, 1080],
}

const sizeCombos = {
  buttons: [sizes.buttonAds, sizes.buttonAds_s, sizes.button, sizes.button_s],
  buttons_s: [sizes.buttonAds_s, sizes.button_s],
  largeLeaderboards: [sizes.superBanner, sizes.largeLeaderBoard, sizes.largeLeaderBoard_s],
}

const customCss = {
  sky: `
    ${createMediaQueryTag({ max: 'desktop', min: 'tablet_s' })} {
      max-height: 90px;
    }
    ${createMediaQueryTag({ max: 'tablet_s' })} {
      max-height: 50px;
    }
  `,
  rec: {
    mobile: 'max-height: 400px;',
  },
}

// Config for Südtirol News
const stolConfig = {
  // GPT size mapping definitions (object). Ideally there is a sizeMapping for each Slot
  viewports: viewports,
  sizeMappings: {
    // Homepage
    SB_MOBILE_TABLET: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile_s,
        sizes: sizeCombos.buttons,
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [sizes.superBanner_s],
      },
      {
        viewPortSize: viewports.tablet,
        sizes: sizeCombos.largeLeaderboards,
      },
      {
        viewPortSize: viewports.desktop_s,
        sizes: [],
      },
    ],
    SB_DESKTOP: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.desktop_s,
        sizes: sizeCombos.largeLeaderboards,
      },
    ],
    SKY: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile_s,
        sizes: sizeCombos.buttons_s,
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [sizes.largeLeaderBoard_s],
      },
      {
        viewPortSize: viewports.desktop,
        sizes: [sizes.sky, sizes.sky_s],
      },
    ],
    REC: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.rectangle],
      },
    ],
    REC_MOBILE: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile_s,
        sizes: [sizes.rectangle],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [],
      },
    ],
    REC_TABLET_DESKTOP: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [sizes.rectangle],
      },
    ],
    FS: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile_s,
        sizes: sizeCombos.buttons,
      },
      {
        viewPortSize: viewports.desktop_s,
        sizes: [sizes.fullSize],
      },
    ],
    FS_MOBILE_DESKTOP: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile_s,
        sizes: sizeCombos.buttons,
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [],
      },
      {
        viewPortSize: viewports.desktop_s,
        sizes: [sizes.fullSize],
      },
    ],
    FS_TABLET: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: sizeCombos.buttons,
      },
      {
        viewPortSize: viewports.desktop_s,
        sizes: [],
      },
    ],
    LL_NO_MOBILE: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [sizes.largeLeaderBoard_s],
      },
      {
        viewPortSize: viewports.desktop_s,
        sizes: [sizes.largeLeaderBoard, sizes.superBanner],
      },
    ],
    LL_EXTRAS: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.rectangle, sizes.hotelWidget_s, sizes.hotelWidgetSolo_s],
      },
      {
        viewPortSize: viewports.mobile_m,
        sizes: [sizes.rectangle, sizes.hotelWidget_m, sizes.hotelWidgetSolo_m],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [sizes.largeLeaderBoard_s, sizes.hotelWidget_l, sizes.hotelWidgetSolo_l],
      },
      {
        viewPortSize: viewports.mobile_l,
        sizes: [sizes.largeLeaderBoard_s, sizes.hotelWidget_l, sizes.hotelWidgetSolo_l],
      },
      {
        viewPortSize: viewports.desktop_s,
        sizes: [sizes.largeLeaderBoard, sizes.superBanner, sizes.hotelWidget_xl, sizes.hotelWidgetSolo_xl],
      },
    ],
    LL_EXTRAS_NO_WIDGET: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.rectangle],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [sizes.largeLeaderBoard_s],
      },
      {
        viewPortSize: viewports.desktop_s,
        sizes: [sizes.largeLeaderBoard, sizes.superBanner],
      },
    ],
    LL: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile_s,
        sizes: sizeCombos.buttons,
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [sizes.largeLeaderBoard_s],
      },
      {
        viewPortSize: viewports.desktop_s,
        sizes: [sizes.largeLeaderBoard, sizes.superBanner],
      },
    ],
    LL_HOTELWIDGET: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile_s,
        sizes: [
          sizes.buttonAds,
          sizes.buttonAds_s,
          sizes.button,
          sizes.button_s,
          sizes.hotelWidget_s,
          sizes.hotelWidgetSolo_s,
        ],
      },
      {
        viewPortSize: viewports.mobile_m,
        sizes: [
          sizes.buttonAds,
          sizes.buttonAds_s,
          sizes.button,
          sizes.button_s,
          sizes.hotelWidget_m,
          sizes.hotelWidgetSolo_m,
        ],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [sizes.largeLeaderBoard_s, sizes.hotelWidget_l, sizes.hotelWidgetSolo_l],
      },
      {
        viewPortSize: viewports.mobile_l,
        sizes: [sizes.largeLeaderBoard_s, sizes.hotelWidget_l, sizes.hotelWidgetSolo_l],
      },
      {
        viewPortSize: viewports.desktop_s,
        sizes: [sizes.largeLeaderBoard, sizes.superBanner, sizes.hotelWidget_xl, sizes.hotelWidgetSolo_xl],
      },
    ],
    LL_HOTELWIDGET_WEATHER: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile_s,
        sizes: [
          sizes.buttonAds,
          sizes.buttonAds_s,
          sizes.button,
          sizes.button_s,
          sizes.hotelWidget_s,
          sizes.hotelWidgetSolo_s,
        ],
      },
      {
        viewPortSize: viewports.mobile_m,
        sizes: [
          sizes.buttonAds,
          sizes.buttonAds_s,
          sizes.button,
          sizes.button_s,
          sizes.hotelWidget_m,
          sizes.hotelWidgetSolo_m,
        ],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [sizes.largeLeaderBoard_s, sizes.hotelWidget_m, sizes.hotelWidgetSolo_m],
      },
      {
        viewPortSize: viewports.mobile_l,
        sizes: [sizes.largeLeaderBoard_s, sizes.hotelWidget_m, sizes.hotelWidgetSolo_m],
      },
      {
        viewPortSize: viewports.tablet_s,
        sizes: [sizes.largeLeaderBoard_s, sizes.hotelWidget_l, sizes.hotelWidgetSolo_l],
      },
      {
        viewPortSize: viewports.desktop_s,
        sizes: [sizes.largeLeaderBoard, sizes.superBanner, sizes.hotelWidget_xl, sizes.hotelWidgetSolo_xl],
      },
    ],
    BB: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile_s,
        sizes: [sizes.rectangle, sizes.hotelWidget_s, sizes.hotelWidgetSolo_s],
      },
      {
        viewPortSize: viewports.mobile_m,
        sizes: [sizes.rectangle, sizes.hotelWidget_m, sizes.hotelWidgetSolo_m],
      },
      {
        viewPortSize: viewports.mobile,
        sizes: [sizes.largeLeaderBoard_s, sizes.hotelWidget_l, sizes.hotelWidgetSolo_l],
      },
      {
        viewPortSize: viewports.mobile_l,
        sizes: [sizes.largeLeaderBoard_s, sizes.hotelWidget_l, sizes.hotelWidgetSolo_l],
      },
      {
        viewPortSize: viewports.desktop_s,
        sizes: [sizes.billboard, sizes.hotelWidget_xl, sizes.hotelWidgetSolo_xl],
      },
    ],
    BUTTON: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile_s,
        sizes: [sizes.button],
      },
    ],
    BUTTON_Desktop: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.desktop_s,
        sizes: [sizes.button],
      },
    ],
    WALLPAPER: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.tablet,
        sizes: [sizes.wallpaper],
      },
    ],
    IS: [
      {
        viewPortSize: [960, 0],
        sizes: [],
      },
      {
        viewPortSize: [0, 0],
        sizes: [
          [300, 250],
          [320, 480],
        ],
      },
    ],
    SPONSORED_BY: [
      {
        viewPortSize: viewports.all,
        sizes: [sizes.sponsoredBy],
      },
      {
        viewPortSize: viewports.mobile_l,
        sizes: [sizes.sponsoredBy_big],
      },
    ],
  },

  // array of slot configurations
  slots: [
    // Interstitial
    {
      id: 'dlab-ad-IS-web',
      path: '/2273514/stol7_web_is',
      sizeMappingName: 'IS',
    },
    // Homepage
    {
      // ID of the div
      id: 'dlab_ad_H1_mobile_tablet',
      // slot's ad unit path
      path: '/2273514/stol7_hp_sb',
      // name of sizeMapping that is defined for this slot
      sizeMappingName: 'SB_MOBILE_TABLET',
    },
    {
      id: 'dlab_ad_H1_desktop',
      path: '/2273514/stol7_hp_sb',
      sizeMappingName: 'SB_DESKTOP',
    },
    {
      id: 'dlab_ad_H2',
      path: '/2273514/stol7_hp_sky',
      sizeMappingName: 'SKY',
      customCss: customCss.sky,
    },
    {
      id: 'dlab_ad_H3_tablet',
      path: '/2273514/stol7_hp_fs_1',
      sizeMappingName: 'FS_TABLET',
    },
    {
      id: 'dlab_ad_H3_mobile_desktop',
      path: '/2273514/stol7_hp_fs_1',
      sizeMappingName: 'FS_MOBILE_DESKTOP',
    },
    {
      id: 'dlab_ad_H4_tablet',
      path: '/2273514/stol7_hp_fs_2',
      sizeMappingName: 'FS_TABLET',
    },
    {
      id: 'dlab_ad_H4_mobile_desktop',
      path: '/2273514/stol7_hp_fs_2',
      sizeMappingName: 'FS_MOBILE_DESKTOP',
    },
    {
      id: 'dlab_ad_H5',
      path: '/2273514/stol7_hp_rec_1',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab_ad_H6',
      path: '/2273514/stol7_hp_rec_2',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab_ad_H7',
      path: '/2273514/stol7_hp_ll_1',
      sizeMappingName: 'LL_HOTELWIDGET',
    },
    {
      id: 'dlab_ad_H8',
      path: '/2273514/stol7_hp_ll_2',
      sizeMappingName: 'LL_EXTRAS_NO_WIDGET',
    },
    {
      id: 'dlab_ad_H9',
      path: '/2273514/stol7_hp_rec_3',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab_ad_H10',
      path: '/2273514/stol7_hp_rec_4',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab_ad_H11',
      path: '/2273514/stol7_hp_ll_3',
      sizeMappingName: 'LL_NO_MOBILE',
    },
    {
      id: 'dlab_ad_H12',
      path: '/2273514/stol7_hp_but_1',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab_ad_H13',
      path: '/2273514/stol7_hp_but_2',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab_ad_H14',
      path: '/2273514/stol7_hp_but_3',
      sizeMappingName: 'BUTTON_Desktop',
    },
    {
      id: 'dlab_ad_H15',
      path: '/2273514/stol7_hp_ll_4',
      sizeMappingName: 'LL_HOTELWIDGET_WEATHER',
    },
    {
      id: 'dlab_ad_H16',
      path: '/2273514/stol7_hp_rec_5',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab_ad_H17',
      path: '/2273514/stol7_hp_rec_6',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab_ad_H18',
      path: '/2273514/stol7_hp_bb',
      sizeMappingName: 'BB',
    },
    {
      id: 'dlab_ad_H21',
      path: '/2273514/stol7_hp_ll_5',
      sizeMappingName: 'LL_EXTRAS',
    },
    {
      id: 'dlab_ad_H22',
      path: '/2273514/stol7_hp_ll_6',
      sizeMappingName: 'LL_EXTRAS',
    },
    {
      id: 'dlab_ad_H23',
      path: '/2273514/stol7_hp_ll_7',
      sizeMappingName: 'LL_EXTRAS',
    },
    {
      id: 'dlab_ad_H24',
      path: '/2273514/stol7_hp_ll_8',
      sizeMappingName: 'LL_EXTRAS',
    },
    {
      id: 'dlab_ad_H25',
      path: '/2273514/stol7_hp_ll_9',
      sizeMappingName: 'LL_EXTRAS',
    },
    {
      id: 'dlab_ad_H26',
      path: '/2273514/stol7_hp_ll_10',
      sizeMappingName: 'LL_EXTRAS',
    },
    {
      id: 'dlab_ad_H27',
      path: '/2273514/stol7_hp_ll_11',
      sizeMappingName: 'LL_EXTRAS',
    },
    {
      id: 'dlab_ad_H28',
      path: '/2273514/stol7_hp_ll_12',
      sizeMappingName: 'LL_EXTRAS',
    },
    {
      id: 'dlab_ad_H29',
      path: '/2273514/stol7_hp_ll_13',
      sizeMappingName: 'LL_EXTRAS',
    },
    {
      id: 'dlab_ad_sponsoredBy',
      path: '/2273514/stol7_hp_pb_ontour',
      sizeMappingName: 'SPONSORED_BY',
    },
    {
      id: 'dlab_ad_WALLPAPER',
      path: '/2273514/stol7_wallpaper',
      sizeMappingName: 'WALLPAPER',
    },
    {
      id: 'dlab_ad_S1_mobile_tablet',
      path: '/2273514/stol7_default_sb',
      sizeMappingName: 'SB_MOBILE_TABLET',
    },
    {
      id: 'dlab_ad_S1_desktop',
      path: '/2273514/stol7_default_sb',
      sizeMappingName: 'SB_DESKTOP',
    },
    {
      id: 'dlab_ad_S2',
      path: '/2273514/stol7_default_sky',
      sizeMappingName: 'SKY',
      customCss: customCss.sky,
    },
    {
      id: 'dlab_ad_S3',
      path: '/2273514/stol7_default_fs_1',
      sizeMappingName: 'FS',
    },
    {
      id: 'dlab_ad_S4',
      path: '/2273514/stol7_default_fs_2',
      sizeMappingName: 'FS',
    },
    {
      id: 'dlab_ad_S5',
      path: '/2273514/stol7_default_rec_1',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab_ad_S5_mobile',
      path: '/2273514/stol7_default_rec_1',
      sizeMappingName: 'REC_MOBILE',
      customCss: customCss.rec,
    },
    {
      id: 'dlab_ad_S5_tablet_desktop',
      path: '/2273514/stol7_default_rec_1',
      sizeMappingName: 'REC_TABLET_DESKTOP',
    },
    {
      id: 'dlab_ad_S6',
      path: '/2273514/stol7_default_rec_2',
      sizeMappingName: 'REC',
    },
    {
      id: 'dlab_ad_S7',
      path: '/2273514/stol7_default_ll_1',
      sizeMappingName: 'LL_HOTELWIDGET',
    },
    {
      id: 'dlab_ad_S8',
      path: '/2273514/stol7_default_ll_2',
      sizeMappingName: 'LL_HOTELWIDGET',
    },
    {
      id: 'dlab_ad_S9',
      path: '/2273514/stol7_default_ll_3',
      sizeMappingName: 'LL_EXTRAS_NO_WIDGET',
    },
    {
      id: 'dlab_ad_S10',
      path: '/2273514/stol7_default_ll_3',
      sizeMappingName: 'LL_EXTRAS_NO_WIDGET',
    },
    {
      id: 'dlab_ad_S11',
      path: '/2273514/stol7_default_ll_3',
      sizeMappingName: 'LL_EXTRAS_NO_WIDGET',
    },
    {
      id: 'dlab_ad_S12',
      path: '/2273514/stol7_default_ll_3',
      sizeMappingName: 'LL_EXTRAS_NO_WIDGET',
    },
    {
      id: 'dlab_ad_S13',
      path: '/2273514/stol7_default_ll_3',
      sizeMappingName: 'LL_EXTRAS_NO_WIDGET',
    },
    {
      id: 'dlab_ad_S14',
      path: '/2273514/stol7_default_ll_3',
      sizeMappingName: 'LL_EXTRAS_NO_WIDGET',
    },
    {
      id: 'dlab_ad_S15',
      path: '/2273514/stol7_default_ll_3',
      sizeMappingName: 'LL_EXTRAS_NO_WIDGET',
    },
    {
      id: 'dlab_ad_S16',
      path: '/2273514/stol7_default_ll_3',
      sizeMappingName: 'LL_EXTRAS_NO_WIDGET',
    },
    {
      id: 'dlab_ad_D1',
      path: '/2273514/stol7_dolo_but_1',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab_ad_D2',
      path: '/2273514/stol7_dolo_but_2',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab_ad_D3',
      path: '/2273514/stol7_dolo_but_3',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab_ad_D4',
      path: '/2273514/stol7_dolo_but_4',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab_ad_D5',
      path: '/2273514/stol7_dolo_but_5',
      sizeMappingName: 'BUTTON',
    },
    {
      id: 'dlab_ad_D6',
      path: '/2273514/stol7_dolo_but_6',
      sizeMappingName: 'BUTTON',
    },
  ],

  general: {
    hiddenOptions: settingsSTOL(),
    limitAdElementHeight: true,
    reloadInterval: true,
    collapseEmptyDivs: true,
    disableInitialLoad: true,
    enableSingleRequest: true,
    disableAddScrollbarWidth: true,
    testValue: '1',
    amazontam: true,
    lazyLoad: {
      offset: 500,
    },
    prebid: {
      active: true,
      setSizeMapping: true,
      hashedEmails: {
        windowFunction: 'getStolUser',
      },
      video: {
        adSlotName: '/2273514/stol7_video_pre',
        playerSize: [640, 360],
      },
    },
    adBlockingRecovery: {
      frequencyCap: {
        type: 'time',
        amount: 10,
      },
    },
    customRulesUrls: [
      {
        url: '/anmelden',
        exact: false,
        noAds: true,
      },
      {
        url: '/lesezeichen',
        exact: false,
        noAds: true,
      },
      {
        url: '/profil',
        exact: false,
        noAds: true,
      },
      {
        url: '/lesereporter',
        exact: false,
        noAds: true,
      },
      {
        url: '/p/kuendigen',
        exact: false,
        noAds: true,
      },
      /*
      {
        "url": '/artikel/wirtschaft/',
        "exact": false,
        "forceAds": true
      },
      {
        url: '/trauer',
        exact: false,
        customExclusionLabels: ['Programmatic'],
      }, 
      {
        url: '/dolomiten',
        exact: false,
        customExclusionLabels: ['Programmatic'],
      },
      {
        url: '/artikel/wirtschaft/',
        exact: false,
        excludeAdUnit: ['dlab-ad-IS-web', 'dlab_ad_S1_desktop'],
      },
      */
      {
        url: '/stellenanzeigen',
        exact: false,
        excludeAdUnit: ['dlab-ad-IS-web'],
      },
      {
        url: '/rubrik/plus',
        exact: false,
        excludeAdUnit: ['dlab-ad-IS-web', 'dlab_ad_S5', 'dlab_ad_S2'],
      },
    ],
    preloadedFiles: [
      {
        link: 'https://connect.suedtirol.live/slc.js',
        async: true,
        onload: () => {
          const userString = window.localStorage.getItem('user')
          let userMail = null
          if (userString) {
            userMail = JSON.parse(userString).email
          }
          window.createSessionId({ email: userMail })
          SLC('ui', window.localStorage.getItem('sessionId'))

          // Setup *AFTER* any params and data to SLC
          SLC('setup', 'SLC-8GCSJXZB')
        },
      },
      {
        idLoadOnce: 'gpt-script',
        link: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
        async: true,
      },
    ],
    interstitial: {
      // [ToDo]: Refactor that shit
      // showInViewports: ['mobile', 'mobile_s', 'tablet', 'tablet_s'],
      containerId: 'dlab-interstitial',
      slotId: 'dlab-ad-IS-web',
      // ga4eventName: 'web-interstitial',
      slotPath: '/2273514/stol7_web_is',
      displayTimeInSec: 15,
    },
    reloader: {
      interval: 30,
      campaignIds: [293423654, 164280974],
      adUnitsIds: ['/2273514/stol7_hp_sky', '/2273514/stol7_default_sky'],
    },
    addCloseButton: {
      elements: [
        {
          ids: ['dlab_ad_H2', 'dlab_ad_S2'],
          width: [728],
        },
      ],
    },
  },
}

export default stolConfig
