import T from 'prop-types'

import { Description, Title, FirstDescription, SecondDescription } from '../styled'
import { BottomListContainer, BottomListWrapper, CardContainer, CardWrapper, OfferWrapper } from './styled'
import uniqueId from 'lodash/uniqueId'
import Wrapper from '../../components/Wrapper'
import styled from 'styled-components'
import { htmlParser } from '../../../../modules/parse'

const Offer = ({ data }) => {
  return (
    <Wrapper>
      <OfferWrapper id="offerWrapper">
        <Title>{data.title}</Title>
        <FirstDescription>{data.subTitle}</FirstDescription>
        <SecondDescription>
          {data.content.bottomList.map((el) => (
            <p key={uniqueId()}>{htmlParser(el)}</p>
          ))}
        </SecondDescription>
        <CardWrapper>
          <CardContainer id={data.offerID} />
        </CardWrapper>
      </OfferWrapper>
    </Wrapper>
  )
}

Offer.propTypes = {
  data: T.shape({
    title: T.string,
    subTitle: T.string,
    content: T.shape({
      bottomList: T.array,
    }),
  }),
}

export default Offer
