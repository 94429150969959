import React, { Fragment } from 'react'
import styled from 'styled-components'
import ItemArticle from '../../../../../Article/components/ItemArticle'
import PropTypes from 'prop-types'
import moment from 'moment'
import Text from '../../../../../../components/Text'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Title from '../../../../../../components/Title'
import ContainerFull from '../../../../../../components/ContainerFull'

const Wrapper = styled(ContainerFull)``

const StyledTableRow = styled(TableRow)`
  background-color: #f6f6f6;
  &:nth-of-type(odd) {
    background-color: #fcfcfc;
  }
`
const StyledNewsDot = styled.span`
  color: ${(props) => props.theme.color.stol};
  font-size: 16px;
  font-weight: bold;
`

const DateText = styled(Text)`
  width: 35px;
  white-space: nowrap;
`
const StyledTitle = styled(Title)`
  margin: 0;
`

const styleTableCell = {
  padding: 8,
}

const dateTitleFormat = 'dddd, DD.MM.YYYY'

const ListArticle = ({ articles, ...props }) => {
  let titleDate = null

  const TitleDateRow = ({ articleDate }) => {
    if (titleDate !== articleDate) {
      titleDate = articleDate
      return (
        <TableRow>
          <TableCell colSpan={2}>
            <StyledTitle>{articleDate}</StyledTitle>
          </TableCell>
        </TableRow>
      )
    }
    return false
  }

  return (
    <Wrapper {...props}>
      <TableContainer>
        <Table>
          <TableBody>
            {articles.map((article) => (
              <Fragment>
                <TitleDateRow articleDate={moment(article.date).format(dateTitleFormat)} />
                <StyledTableRow key={article.id}>
                  <TableCell style={styleTableCell}>
                    <DateText>
                      {moment(article.date).format('HH:mm').toString()}{' '}
                      {article.new && <StyledNewsDot>&bull;</StyledNewsDot>}
                    </DateText>
                  </TableCell>
                  <TableCell style={styleTableCell}>
                    <ItemArticle view="line" hide={['description']} article={article} />
                  </TableCell>
                </StyledTableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  )
}

ListArticle.propTypes = {
  articles: PropTypes.arrayOf(ItemArticle.propTypes),
}

export default ListArticle
export { Wrapper as Wrapper0ListArticle, StyledTableRow as StyledTableRow0ListArticle }
