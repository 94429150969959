import Link from '../../../../../../components/Link'
import { StyledIcon0ListWidgetNakedLineArticle } from '../../../WidgetNakedLineArticle/components/ListWidgetNakedLineArticle'
import React from 'react'
import options from '../../../../../../config/options'

const MailIconWidgetEvents = () => (
  <Link to={`mailto:${options.Marketing.email}`}>
    <StyledIcon0ListWidgetNakedLineArticle icon="email" />
  </Link>
)

export default MailIconWidgetEvents
