import Obituary from '../topics/Obituary/index'
import Traffic from '../topics/Traffic'
import Weather from '../topics/Weather'
import Search from '../topics/Search'
import Paper from '../topics/Paper'
import NearMe from '../topics/NearMe'
import JobWidgetPage from './JobWidgetPage'
import UserGeneratedContent from '../topics/UserGeneratedContent'
import Topic from '../topics/Topic'
import NotFoundError from '../topics/Error/screens/NotFoundError'
import NewsFeed from '../topics/NewsFeed'
import OnTour from '../topics/OnTour'
import ScreenVideoDetail from './Video/screens/ScreenVideo/screens/ScreenVideoDetail'
import ScreenPaper from '../topics/Paper/screens/ScreenPaper'
//import CouncilElection from '../topics/CouncilElection'
import Profile from './Profile'
import Page from './Page'
import Bookmark from './Bookmark'
import Faq from './Faq'
import Video from './Video'
import DlabSettings from './DlabSettings'
import loadable from '@loadable/component'
import PreComponentHome from './Home/components/PreComponentHome'
import ScreenArticle from './Article/screens/ScreenArticle'
import PreComponentMVPEvent from './MvpEvent/components/PreComponentMVPEvent'

const ScreenWeather = loadable(() => import(/* webpackChunkName: "ScreenWeather" */ './Weather/screens/ScreenWeather'))
const ScreenObituary = loadable(() =>
  import(/* webpackChunkName: "ScreenObituary" */ './Obituary/screens/ScreenObituary'),
)
const ScreenOnTour = loadable(() => import(/* webpackChunkName: "ScreenOnTour" */ './OnTour/screens/ScreenOnTour'))
const ScreenPage = loadable(() => import(/* webpackChunkName: "ScreenPage" */ './Page/screens/ScreenPage'))
const ScreenSearch = loadable(() => import(/* webpackChunkName: "ScreenSearch" */ './Search/screens/SearchScreen'))
const ScreenNewsFeed = loadable(() =>
  import(/* webpackChunkName: "ScreenNewsFeed" */ './NewsFeed/screens/ScreenNewsFeed'),
)
const ScreenTraffic = loadable(() => import(/* webpackChunkName: "ScreenTraffic" */ './Traffic/screens/ScreenTraffic'))

const Components = {
  ScreenArticle,
  Bookmark,
  //  CouncilElection,
  ScreenObituary,
  ScreenOnTour,
  ScreenPage,
  ScreenSearch,
  ScreenNewsFeed,
  ScreenTraffic,
  DlabSettings,
  Obituary,
  Traffic,
  Weather,
  Search,
  Video,
  Paper,
  NearMe,
  Profile,
  UserGeneratedContent,
  Topic,
  NewsFeed,
  NotFoundError,
  OnTour,
  Page,
  Faq,
  JobWidgetPage,
  ScreenWeather,
  PreComponentHome,
  PreComponentMVPEvent,
  ScreenVideoDetail,
  ScreenPaper,
}

export default Components
