import styled, { keyframes } from 'styled-components'

import flag from './images/flag.png'
import hero from './images/hero.jpg'

export const RedButton = styled.a`
  background: #db2f41;
  border-radius: 12px;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 60px;
  font-size: 24px;
  font-weight: 700;
  line-height: 133.4%;
  text-decoration: none;
  color: #ffffff;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '300')}px;
  border: none;
  :hover {
    background: #a12431;
  }
  @media (max-width: 600px) {
    padding: 14px 40px;
    font-size: 16px;
  }
`

export const Wrapper = styled.header`
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  p {
    margin: 0;
  }
  button {
    cursor: pointer;
    border: none;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
`

export const HeroImageWrapper = styled.section`
  background-image: url(${hero});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  min-height: 900px;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  @media (max-width: 600px) {
    min-height: 712px;
  }
`

export const HeroContainer = styled.div`
  align-self: start;
  max-width: 1440px;
  flex-direction: column;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 58px;
  padding-right: 14px;
  @media (max-width: 600px) {
    padding-top: 24px;
  }
  @media (min-width: 1441px) {
    max-width: none;
    padding-right: calc((100vw - 1164px) / 2);
  }
`

export const HeroLabelContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const HeroFlag = styled.div`
  padding: 16px 28px;
  display: flex;
  //background: linear-gradient(88.49deg, #db2f41 20.48%, rgba(252, 91, 108, 0.87) 82.15%);
  background-image: url(${flag});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  flex-direction: column;
  width: 395px;
  @media (max-width: 600px) {
    width: 226px;
    padding: 16px 13px;
  }
`

export const HeroFlagTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 47px;
  color: #ffffff;
  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 21px;
  }
`
export const HeroFlagSubTitle = styled.p`
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  color: #ffffff;
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 18px;
  }
`

export const HeroFlagContainer = styled.div`
  display: flex;
  align-items: center;
`

export const HeroFlagIcon24X24 = styled.img`
  width: 24px;
  height: 24px;
  padding-right: 8px;
  @media (max-width: 600px) {
    width: 14px;
    height: 16px;
  }
`

export const HeroArrowIcon24X24 = styled.img`
  width: 24px;
  height: 24px;
`

export const Icon30X30 = styled.img`
  width: 30px;
  height: 30px;
`

export const HeroContentContainer = styled.div`
  display: flex;
  margin-top: 8px;
  align-self: end;
  flex-direction: column;
  p:nth-child(2) {
    color: #db2f41;
    font-weight: 700;
  }
  @media (max-width: 600px) {
    margin-top: ${({ lng }) => (lng === 'IT' ? '21' : '78')}px;
  }
`

export const HeroTitle = styled.p`
  font-weight: 700;
  max-width: 760px;
  font-size: 64px;
  margin-bottom: 34px !important;
  line-height: 64px;
  text-align: right;
  color: ${({ lng }) => (lng === 'IT' ? '#35373a' : '#db2f41')};
  span {
    color: ${({ lng }) => (lng === 'IT' ? '#db2f41' : '#35373a')};
    font-size: 48px;
    line-height: 80x;
    @media (max-width: 600px) {
      font-size: 36px;
      line-height: 47px;
    }
  }
  @media (max-width: 800px) {
    font-size: 48px;
    line-height: 64px;
  }
  @media (max-width: 600px) {
    font-size: 36px;
    line-height: 47px;
    max-width: 400px;
    margin-bottom: 30px;
  }
`

export const HeroDescription = styled.p`
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  text-align: right;
  color: #35373a;
  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 21px;
  }
`

export const HeroButton = styled(RedButton)`
  margin-top: ${(props) => (props.lng === 'IT' ? '40' : '64')}px;
  align-self: end;
  max-width: 264px;
  margin-bottom: 8px;
  cursor: pointer;
`

export const HeroBottomDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const HeroBottomDescription = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  text-align: right;
  color: #35373a;
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 18px;
  }
`
const blinkingBackground = keyframes`
  0% {
    background: rgba(255, 231, 231, 0.78);
  }
  25% {
    background: rgba(255, 231, 231, 0.58);
  }
  50% {
    background: rgba(255, 231, 231, 0.38);
  }
  75% {
    background: rgba(255, 231, 231, 0.58);
  }
  100% {
    background: rgba(255, 231, 231, 0.78);
  }
`
export const HeroBottomSpan = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  text-align: right;
  background: rgba(255, 231, 231, 0.78);
  border-radius: 4px;
  color: #db2f41;
  padding: 0 4px;
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 18px;
  }
  animation: ${blinkingBackground} 2s infinite;
`

export const SocialBottomContainer = styled.div`
  display: flex;
  align-self: end;
  margin: ${({ lng }) => (lng === 'IT' ? '33' : '50')}px 0px;
  @media (max-width: 600px) {
    margin-top: 99px;
  }
`

export const HeroSwitcher = styled.div`
  position: relative;
  display: flex;
  align-items: start;
  min-width: 60px;
  padding: 8px 0;
  border-radius: 4px;
  max-height: ${(props) => (props.open ? '52' : '24')}px;
  box-shadow: ${(props) => (props.open ? ' 10px 10px 50px 3px #eeeeee' : 'none')};
  background: ${(props) => (props.open ? '#fefefe' : 'transparent')};

  button {
    display: flex;
    align-items: center;
    max-height: 24px;
    background: transparent;
    > p {
      color: #db2f41;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 133.4%;
    }
  }
`
export const HeroSwitcherDropdown = styled.div`
  display: block;
  position: absolute;
  top: 40px;
  width: 60px;
  z-index: 1;
`

export const HeroSwitcherLink = styled.a`
  color: #db2f41;
  display: flex;
  justify-content: center;
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 133.4%;
  text-decoration: none;
  :hover {
    cursor: pointer;
    background: #db2f41;
    color: #ffffff;
  }
`
